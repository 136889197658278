{
  "accesorio": {
    "h1": "Acessórios",
    "meta": "Encontre o presente perfeito para qualquer ocasião ou mime-se e mostre os melhores acessórios. Visite nosso catálogo agora. {{numPagina}}",
    "title": "Acessórios | Kronaby"
  },
  "accesorio.default": {
    "h1": "Acessórios {{tipoAccesorio}} {{subtipoAccesorio}} {{tipoBoligrafo}}",
    "meta": "Encontre o presente perfeito para qualquer ocasião ou mime-se e mostre os melhores acessórios. Visite nosso catálogo agora. {{numPagina}}",
    "robots": "false",
    "title": "Acessórios {{tipoAccesorio}} {{subtipoAccesorio}} {{tipoBoligrafo}} | Kronaby"
  },
  "accesorio.LEATHER_ITEM.CARD_HOLDER": {
    "h1": "Titulares de cartões de couro",
    "meta": "A melhor maneira de manter os seus cartões organizados é com os nossos porta-cartões de couro feitos do material mais resistente e elegante.",
    "title": "Titulares de cartões de couro : Kronaby"
  },
  "accesorio.LEATHER_ITEM.WALLET": {
    "h1": "Carteiras de couro",
    "meta": "As nossas carteiras de couro são perfeitas para dar presentes. Feitos de material durável e disponíveis numa variedade de cores, são ideais para levar para onde quer que vá.",
    "title": "Carteiras de couro : Kronaby"
  },
  "accesorio.subtipoAccesorio.default": {
    "h1": "{{subtipoAccesorio}}",
    "meta": "Descubra a nossa selecção de {{subtipoAccesorio}} na nossa loja online oficial.",
    "title": "{{subtipoAccesorio}} | Kronaby"
  },
  "accesorio.subtipoAccesorio.SOLAR": {
    "h1": "Óculos de sol",
    "meta": "Descubra a nossa selecção de óculos de sol na nossa loja online oficial.",
    "title": "Óculos de sol | Kronaby"
  },
  "accesorio.tipoAccesorio.default": {
    "h1": "Acessórios {{tipoAccesorio}}",
    "meta": "A partir da nossa selecção de Acessórios Kronaby, encontre o presente ideal para ocasiões especiais ou para se tratar a si próprio.",
    "title": "Acessórios {{tipoAccesorio}} | Kronaby"
  },
  "accesorio.tipoAccesorio.KEYCHAIN": {
    "h1": "porta-chaves",
    "meta": "Os porta-chaves Kronaby são um acessório indispensável para complementar o seu estilo. São elegantes e modernos e dão um toque distinto ao utente.",
    "title": "Porta-chaves : Kronaby"
  },
  "accesorio.WRITING.BALLPOINT_PEN": {
    "h1": "Canetas esferográficas",
    "meta": "As nossas canetas esferográficas tornaram-se as canetas mais fáceis e confortáveis de escrever. Apresentam também um design subtil e exclusivo.",
    "title": "Canetas esferográficas : Kronaby"
  },
  "accesorio.WRITING.FOUNTAIN_PEN": {
    "h1": "Canetas de tinta permanente",
    "meta": "Dê um toque pessoal à sua caligrafia com as nossas canetas de tinta permanente. No nosso catálogo pode encontrá-los em diferentes cores e desenhos.",
    "title": "Canetas Kronaby Fontina"
  },
  "accesorio.WRITING.REPLACEMENT": {
    "h1": "Recargas para canetas esferográficas",
    "meta": "Na nossa loja online pode também comprar recargas de canetas, e comprá-las em pacotes de duas para tê-las sempre à mão.",
    "title": "Recargas para canetas esferográficas | Kronaby"
  },
  "accesorio.WRITING.ROLLERBALL_PEN": {
    "h1": "Canetas Rollerball",
    "meta": "Descubra as nossas canetas de rollerball, canetas de luxo com um design elegante e discreto, perfeitas como prenda para momentos especiais.",
    "title": "Canetas Rollerball : Kronaby"
  },
  "reloj": {
    "h1": "Relógios",
    "meta": "Procurando um relógio exclusivo e elegante? Descubra os nossos relógios das melhores marcas e encontre o modelo perfeito para si. {{numPagina}}",
    "title": "Relógios {{numPagina}} | Kronaby"
  },
  "reloj.C": {
    "h1": "Relógios de Homem",
    "meta": "Os nossos Relógios de Homem são perfeitos para qualquer ocasião. Concebidos de forma exclusiva, são ideais para complementar um estilo elegante e único. {{numPagina}}",
    "title": "Relógios de Homem {{numPagina}} | Kronaby"
  },
  "reloj.colorEsfera.default": {
    "h1": "Relógios com mostradores {{ colorEsfera }}",
    "meta": "À procura de um relógio único e elegante? Descubra os nossos relógios com {{ colorEsfera }} mostradores das melhores marcas e encontre o modelo perfeito para si.",
    "title": "Relógios com mostradores {{ colorEsfera }} | Kronaby"
  },
  "reloj.default": {
    "h1": "Relógios {{marca}} {{funcion}} {{estilos}} {{colorCorrea}} {{colorEsfera}} {{materialCaja}} {{materialCorrea}}",
    "meta": "Procurando um relógio exclusivo e elegante? Descubra os nossos relógios das melhores marcas e encontre o modelo perfeito para si. {{numPagina}}",
    "robots": "false",
    "title": "Relógios {{gama}} {{marca}} {{funcion}} {{estilos}} {{colorCorrea}} {{colorEsfera}} {{materialCaja}} {{materialCorrea}} {{numPagina}} | Kronaby"
  },
  "reloj.funcion.default": {
    "h1": "Relógios {{funcion}}",
    "meta": "Procurando um relógio {{funcion}}? Encontre os melhores modelos das melhores marcas e escolha o relógio perfeito para você. Visite nosso catálogo agora {{numPagina}}",
    "title": "Relógios {{funcion}} {{numPagina}} | Kronaby"
  },
  "reloj.gama.colorCorrea.default": {
    "h1": "Relógios {{colorCorrea}} para {{gama}}",
    "meta": "Temos uma extensa selecção de cores e materiais para que possa escolher os Relógios {{colorCorrea}} para {{gama}} que melhor se adequam ao seu estilo. {{numPagina}}",
    "title": "Relógios {{colorCorrea}} para {{gama}} {{numPagina}} | Kronaby"
  },
  "reloj.gama.colorEsfera.default": {
    "h1": "Relógios com esfera {{colorEsfera}} para {{gama}}",
    "meta": "Distinto, único e sofisticado. Esses são os nossos relógios com mostrador {{colorEsfera}} para {{gama}} que irão adicionar um toque de elegância ao seu pulso.",
    "title": "Relógios com esfera {{colorEsfera}} para {{gama}} {{numPagina}} | Kronaby"
  },
  "reloj.gama.funcion.default": {
    "h1": "Relógios {{funcion}} para {{gama}}",
    "meta": "Encontre os relógios {{function}} mais incríveis e sofisticados para {{gama}} da Kronaby no mercado e use-os em qualquer ocasião. {{numPagina}}",
    "title": "Relógios {{funcion}} para {{gama}} {{numPagina}} | Kronaby"
  },
  "reloj.gama.materialCaja.default": {
    "h1": "{{gama}} relógios com {{materialCaja}}caixa",
    "meta": "Os relógios com estojos {{materialCaja}} a {{gama}} destacam-se pela sua cuidadosa concepção. Entre na nossa loja para escolher a que melhor lhe convier.",
    "title": "{{gama}} relógios com {{materialCaja}} {{numPagina}} caixa | Kronaby"
  },
  "reloj.gama.materialCorrea.default": {
    "h1": "Relógios de {{materialCorrea}} a {{gama}}",
    "meta": "Os relógios Kronaby {{materialCorrea}} a {{gama}} tornam-se a escolha perfeita para usar um relógio exclusivo e prático no seu pulso.",
    "title": "Relógios de {{materialCorrea}} a {{gama}} | Kronaby"
  },
  "reloj.gama.tipoCierre.default": {
    "h1": "Relógios {{tipoCierre}} para {{gama}}",
    "meta": "No nosso website poderá ver a vasta selecção de Relógios {{tipoCierre}} para {{gama}} que oferecemos. Temos peças exclusivas só para si.",
    "title": "Relógios {{tipoCierre}} para {{gama}} | Kronaby"
  },
  "reloj.gama.tipoCorrea.ARMYS": {
    "h1": "Relógios {{tipoCorrea}} para {{gama}}",
    "meta": "Sabia que no Kronaby oferecemos relógios {{tipoCorrea}} para {{gama}} todo o tipo de ocasiões? Encontre aquele que melhor se adequa à sua personalidade!",
    "title": "Relógios {{tipoCorrea}} para {{gama}} | Kronaby"
  },
  "reloj.gama.tipoCorrea.CORREA": {
    "h1": "Relógios {{tipoCorrea}} para {{gama}}",
    "meta": "Sabia que no Kronaby oferecemos relógios {{tipoCorrea}} para {{gama}} todo o tipo de ocasiões? Encontre aquele que melhor se adequa à sua personalidade!",
    "title": "Relógios {{tipoCorrea}} para {{gama}} | Kronaby"
  },
  "reloj.gama.tipoEstanqueidad.default": {
    "h1": "Relógios de {{tipoEstanqueidad}} a {{gama}}",
    "meta": "Consulte o nosso vasto catálogo de Relógios de {{tipoEstanqueidad}} a {{gama}} feitos com os melhores materiais e técnicas do mercado.",
    "title": "Relógios de {{tipoEstanqueidad}} a {{gama}} | Kronaby"
  },
  "reloj.gama.tiposMovimiento.01": {
    "h1": "Relógios digitais para {{gama}}",
    "meta": "Está à procura de um relógio digital para {{gama}}? Na Kronaby temos uma grande seleção de relógios disponíveis. Entre e escolha o que melhor se adapta a você.",
    "title": "Relógios digitais para {{gama}} | Kronaby"
  },
  "reloj.gama.tiposMovimiento.02": {
    "h1": "Relógios para {{gama}} com movimento Quartzo-digital",
    "meta": "Está à procura de relógios de {{gama}} com movimento Quartzo-digital? Dê uma olhada na nossa ampla gama de relógios para {{gama}} e escolha o modelo perfeito para você.",
    "robots": "false",
    "title": "Relógios para {{gama}} com movimento Quartzo-digital | Kronaby"
  },
  "reloj.gama.tiposMovimiento.04": {
    "h1": "Relógios automáticos para {{gama}}",
    "meta": "Está à procura de um relógio automático para {{gama}}? Na Kronaby temos uma grande seleção de relógios disponíveis. Entre e escolha o que melhor se adapta a você.",
    "title": "Relógios automáticos para {{gama}} | Kronaby"
  },
  "reloj.gama.tiposMovimiento.05": {
    "h1": "Relógios Híbridos para {{gama}}",
    "meta": "Está procurando um relógio híbrido para {{gama}}? Na Kronaby temos uma grande seleção de relógios disponíveis. Entre e escolha o que melhor se adapta a você.",
    "title": "Relógios Híbridos para {{gama}} | Kronaby"
  },
  "reloj.gama.tiposMovimiento.06": {
    "h1": "Smartwatches para {{gama}} (Relógios inteligentes para {{gama}})",
    "meta": "Está à procura de um smartwatch para {{gama}}? Na Kronaby temos uma grande seleção de relógios inteligentes disponíveis. Entre e escolha o que melhor se adapta a você.",
    "title": "Smartwatches para {{gama}} - Relógios Inteligentes | Kronaby"
  },
  "reloj.gama.tiposMovimiento.07": {
    "h1": "Relógios de Quartzo para {{gama}}",
    "meta": "Está à procura de um relógio de quartzo para {{gama}}? Na Kronaby temos uma grande seleção de relógios disponíveis. Entre e escolha o que melhor se adapta a você.",
    "title": "Relógios de Quartzo para {{gama}} | Kronaby"
  },
  "reloj.IN": {
    "h1": "Relógios infantis",
    "meta": "À procura dos melhores relógios para crianças? Na nossa loja online encontrará relógios divertidos e variados para os membros mais jovens da família. {{numPagina}}",
    "title": "Relógios infantis {{numPagina}} | Kronaby"
  },
  "reloj.IN.funcion": {
    "h1": "Relógios {{funcion}} para crianças",
    "meta": "Você quer um {{funcion}} relógio para uma criança? Encontre uma variedade de relógios infantis {{funcion}} das melhores marcas. Visite nosso catálogo! {{numPagina}}",
    "title": "Relógios {{funcion}} para crianças {{numPagina}} | Kronaby"
  },
  "reloj.materialCaja.default": {
    "h1": "Relógios com capa {{materialCaja}}",
    "meta": "Você gosta de {{materialCaja}}relógios? Descubra nosso extenso catálogo e encontre o relógio que faz seu pulso brilhar. {{numPagina}}",
    "title": "Relógios com capa {{materialCaja}} {{numPagina}} | Kronaby"
  },
  "reloj.materialCorrea.default": {
    "h1": "Relógios {{materialCorrea}}",
    "meta": "Você gosta de relógios com pulseira de {{materialCorrea}}? Descubra nosso extenso catálogo e encontre o relógio que faz seu pulso brilhar. {{numPagina}}",
    "title": "Relógios {{materialCorrea}} {{numPagina}} | Kronaby"
  },
  "reloj.S": {
    "h1": "Relógios de Mulher",
    "meta": "No nosso catálogo de relógios femininos, encontre aquele que melhor se adapta ao seu estilo e personalidade e use-o confortavelmente na sua vida quotidiana. {{numPagina}}",
    "title": "Relógios de Mulher {{numPagina}} | Kronaby"
  },
  "reloj.tipoCierre.default": {
    "h1": "Relógios {{tipoCierre}}",
    "meta": "Procurando um relógio {{tipoCierre}}? Encontre os melhores modelos das melhores marcas e escolha o relógio perfeito para você. Visite nosso catálogo agora {{numPagina}}",
    "title": "Relógios {{tipoCierre}} {{numPagina}} | Kronaby"
  },
  "reloj.tipoCorrea.default": {
    "h1": "Relógios {{tipoCorrea}}",
    "meta": "Procurando um relógio {{tipoCorrea}}? Encontre os melhores modelos das melhores marcas e escolha o relógio perfeito para você. Visite nosso catálogo agora {{numPagina}}",
    "title": "Relógios {{tipoCorrea}} {{numPagina}} | Kronaby"
  },
  "reloj.tiposMovimiento.01": {
    "h1": "Relógios Digitais",
    "meta": "Está procurando um relógio digital? Na Kronaby temos uma grande seleção de relógios digitais disponíveis. Entre e escolha o que melhor se adapta a você.",
    "robots": "false",
    "title": "Relógios Digitais | Kronaby"
  },
  "reloj.tiposMovimiento.02": {
    "h1": "Relógios com movimento Quartzo-digital",
    "meta": "Está procurando relógios com movimento Quartzo-digital? Dê uma olhada em nossa ampla variedade de relógios e escolha o modelo perfeito para você.",
    "robots": "false",
    "title": "Relógios com movimento Quartzo-digital | Kronaby"
  },
  "reloj.tiposMovimiento.03": {
    "h1": "Relógios com movimento Manual",
    "meta": "Está procurando relógios com movimento Manual? Dê uma olhada em nossa ampla variedade de relógios e escolha o modelo perfeito para você.",
    "robots": "false",
    "title": "Relógios com movimento Manual | Kronaby"
  },
  "reloj.tiposMovimiento.04": {
    "h1": "Relógios Automáticos",
    "meta": "Está à procura de um relógio automático? Na Kronaby temos uma grande seleção de relógios automáticos disponíveis. Entre e escolha o que melhor se adapta a você.",
    "title": "Relógios Automáticos | Kronaby"
  },
  "reloj.tiposMovimiento.05": {
    "h1": "Relógios Híbridos",
    "meta": "Está à procura de um relógio automático? Na Kronaby temos uma grande seleção de relógios automáticos disponíveis. Entre e escolha o que melhor se adapta a você.",
    "robots": "false",
    "title": "Relógios Híbridos | Kronaby"
  },
  "reloj.tiposMovimiento.06": {
    "h1": "Smartwatches",
    "meta": "Está à procura de um smartwatch? Na Kronaby temos uma grande seleção de relógios inteligentes disponíveis. Entre e escolha o que melhor se adapta ao seu estilo.",
    "title": "Smartwatches - Relógios Inteligentes | Kronaby"
  },
  "reloj.tiposMovimiento.07": {
    "h1": "Relógios de Quartzo",
    "meta": "Está procurando um relógio de quartzo? Na Kronaby temos uma grande seleção de relógios de quartzo disponíveis. Entre e escolha o que melhor se adapta a você.",
    "robots": "false",
    "title": "Relógios de Quartzo | Kronaby"
  }
}
