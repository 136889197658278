{
	"filtro.color.AM": "giallo",
	"filtro.color.AZ": "Blu",
	"filtro.color.AZRS": "blu / rosa",
	"filtro.color.BL": "bianchi",
	"filtro.color.BLRY": "bersagli a strisce",
	"filtro.color.CABA": "cavalli",
	"filtro.color.CHA": "PLACCATO",
	"filtro.color.CIRC": "zirconi",
	"filtro.color.DI": "Digitale",
	"filtro.color.DIAM": "Diamanti",
	"filtro.color.DO": "d'oro",
	"filtro.color.FU": "fucsie",
	"filtro.color.GATO": "gatti",
	"filtro.color.GN": "granati",
	"filtro.color.GR": "grigio",
	"filtro.color.LI": "LILLA",
	"filtro.color.MA": "marrone",
	"filtro.color.MAAG": "MARRONE / ARGENTO",
	"filtro.color.MF": "avori",
	"filtro.color.MULT": "MULTICOLORE",
	"filtro.color.NE": "Neri",
	"filtro.color.NEAM": "neri gialli",
	"filtro.color.NJ": "arance",
	"filtro.color.PL": "argenteo",
	"filtro.color.PLAZ": "BLU ARGENTO",
	"filtro.color.PLNE": "ARGENTO / NERO",
	"filtro.color.PLRS": "rose d'argento",
	"filtro.color.RO": "rosso",
	"filtro.color.RONE": "nero rosso",
	"filtro.color.RS": "Rose",
	"filtro.color.TRAN": "Trasparente",
	"filtro.color.VE": "verde",
	"filtro.color.VI": "violette",
	"filtro.estilo.CA": "Casuale",
	"filtro.estilo.CL": "classici",
	"filtro.estilo.LE": "EDIZIONE LIMITATA",
	"filtro.estilo.SP": "Gli sport",
	"filtro.funcion.ANALO": "Analogico",
	"filtro.funcion.CRONO": "cronografi",
	"filtro.funcion.DIGI": "Digitale",
	"filtro.funcion.SMART": "smartwatch",
	"filtro.marca.01": "Lotus",
	"filtro.marca.02": "Festina",
	"filtro.marca.03": "Jaguar",
	"filtro.marca.06": "Calypso",
	"filtro.marca.11": "Lotus Style",
	"filtro.marca.12": "Lotus Silver",
	"filtro.marca.16": "Kronaby",
	"filtro.marca.50": "Candino",
	"filtro.marca.TR": "Time Road",
	"filtro.tipoJoya.ANILLO": "Anelli",
	"filtro.tipoJoya.BROCHE": "spille",
	"filtro.tipoJoya.COLGANTE": "ciondoli",
	"filtro.tipoJoya.COLLAR": "collane",
	"filtro.tipoJoya.CONJUNTO": "imposta",
	"filtro.tipoJoya.GEMELOS": "Gemelli",
	"filtro.tipoJoya.PENDIENTES": "in attesa di",
	"filtro.tipoJoya.PIERCING": "Piercing",
	"filtro.tipoJoya.PULSERA": "BRACCIALI",
	"filtro.tipoJoya.TOBILLERA": "cavigliere"
}
