import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FavoriteIconComponent } from '@fgp/favorites/feature-favorite-icon/favorite-icon';
import { ProductIconsComponent } from '@fgp/product/feature-product-icons';
import { ProductItemListBaseComponent } from '@fgp/product/feature-product-item-list/base';
import { ProductSwiperComponent } from '@fgp/product/ui-product-swiper/product-swiper';
import { UtilCurrencyModule } from '@fgp/shared/pipes/util-currency';
import { UtilDiscountModule } from '@fgp/shared/pipes/util-discount';
import { UtilWebpModule } from '@fgp/shared/pipes/util-webp';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'fgp-product-item-list-kronaby',
  imports: [
    CommonModule,
    FavoriteIconComponent,
    ProductIconsComponent,
    RouterModule,
    UtilWebpModule,
    UtilCurrencyModule,
    TranslateModule,
    UtilDiscountModule,
    ProductSwiperComponent
  ],
  templateUrl: './product-item-list-kronaby.component.html',
  styleUrl: './product-item-list-kronaby.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductItemListKronabyComponent extends ProductItemListBaseComponent {}
