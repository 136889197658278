{
	"filtro.color.AM": "Amarelo",
	"filtro.color.AZ": "Azul",
	"filtro.color.AZRS": "azul / rosa",
	"filtro.color.BL": "brancos",
	"filtro.color.BLRY": "alvos listrados",
	"filtro.color.CABA": "cavalos",
	"filtro.color.CHA": "banhado",
	"filtro.color.CIRC": "zircões",
	"filtro.color.DI": "Digital",
	"filtro.color.DIAM": "Diamantes",
	"filtro.color.DO": "Dourado",
	"filtro.color.FU": "FÚCSIA",
	"filtro.color.GATO": "gatos",
	"filtro.color.GN": "granadas",
	"filtro.color.GR": "Cinzento",
	"filtro.color.LI": "lilases",
	"filtro.color.MA": "Castanho",
	"filtro.color.MAAG": "MARROM / PRATA",
	"filtro.color.MF": "marfins",
	"filtro.color.MULT": "multicolorido",
	"filtro.color.NE": "negros",
	"filtro.color.NEAM": "pretos amarelos",
	"filtro.color.NJ": "laranjas",
	"filtro.color.PL": "prateado",
	"filtro.color.PLAZ": "PRATA AZUL",
	"filtro.color.PLNE": "PRATA / PRETO",
	"filtro.color.PLRS": "rosas de prata",
	"filtro.color.RO": "Vermelho",
	"filtro.color.RONE": "Preto Vermelho",
	"filtro.color.RS": "Rosas",
	"filtro.color.TRAN": "transparente",
	"filtro.color.VE": "Verde",
	"filtro.color.VI": "violetas",
	"filtro.estilo.CA": "Casual",
	"filtro.estilo.CL": "clássicos",
	"filtro.estilo.LE": "EDIÇÃO LIMITADA",
	"filtro.estilo.SP": "esportes",
	"filtro.funcion.ANALO": "Analógico",
	"filtro.funcion.CRONO": "cronógrafos",
	"filtro.funcion.DIGI": "Digital",
	"filtro.funcion.SMART": "smartwatches",
	"filtro.marca.01": "Lotus",
	"filtro.marca.02": "Festina",
	"filtro.marca.03": "Jaguar",
	"filtro.marca.06": "Calypso",
	"filtro.marca.11": "Lotus Style",
	"filtro.marca.12": "Lotus Silver",
	"filtro.marca.16": "Kronaby",
	"filtro.marca.50": "Candino",
	"filtro.marca.TR": "Time Road",
	"filtro.tipoJoya.ANILLO": "argolas",
	"filtro.tipoJoya.BROCHE": "broches",
	"filtro.tipoJoya.COLGANTE": "pingentes",
	"filtro.tipoJoya.COLLAR": "colares",
	"filtro.tipoJoya.CONJUNTO": "Jogos",
	"filtro.tipoJoya.GEMELOS": "Gêmeos",
	"filtro.tipoJoya.PENDIENTES": "brincos",
	"filtro.tipoJoya.PIERCING": "Piercings",
	"filtro.tipoJoya.PULSERA": "PULSEIRAS",
	"filtro.tipoJoya.TOBILLERA": "tornozeleiras"
}
