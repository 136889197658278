<article class="card card-plain producto-catalogo" (mouseover)="showCarousel = true" (mouseleave)="showCarousel = false">
  <fgp-favorite-icon [product]="producto"></fgp-favorite-icon>
  @if (producto?.novedad) {
    <p class="novedad bg-gris6 text-uppercase p mx-1 paragraph-3">{{ 'detalle.producto.festina.novedad' | translate }}</p>
  }

  <a
    (click)="gtmProductClick()"
    routerLink="{{ languageUrl }}{{ producto?.productoPrincipal?.urlPathProducto }}"
    [queryParams]="
      collectionType[collectionType.private] === producto?.productoPrincipal.coleccionType
        ? {
            val1: producto?.productoPrincipal?.idColeccion
          }
        : ''
    "
    class="text-center d-block position-relative"
    [title]="producto?.productoPrincipal?.nombre"
  >
    <picture>
      <source [srcset]="producto?.productoPrincipal.urlImg | webp" type="image/webp" />
      <img
        loading="lazy"
        alt="{{ producto?.productoPrincipal?.nombre }}"
        class="img-fluid mx-auto"
        [ngClass]="{ 'w-50': !size, 'w-75': size === 75 }"
        [src]="producto?.productoPrincipal.urlImg"
        height="212"
        width="127"
      />
    </picture>
    <fgp-product-icons [product]="producto" />
    @if (producto?.productoPrincipal?.mostrarPvpAnterior) {
      <p class="descuento px-1 white-color mx-1">
        - {{ producto?.productoPrincipal?.pvp | discount: producto?.productoPrincipal?.pvpAnterior | number: '1.0-0' }}%
      </p>
    }
  </a>
  <div class="card-body py-2">
    <div class="card-description mt-2 text-center">
      <span class="paragraph-2 color-neutrals-grey-400 text-uppercase font-weight-bold">{{
        this.producto?.productoPrincipal?.coleccion | titlecase
      }}</span>
      <a
        (click)="gtmProductClick()"
        routerLink="{{ languageUrl }}{{ producto?.productoPrincipal?.urlPathProducto }}"
        [queryParams]="
          collectionType[collectionType.private] === producto?.productoPrincipal.coleccionType
            ? {
                val1: producto?.productoPrincipal?.idColeccion
              }
            : ''
        "
        [title]="producto?.productoPrincipal?.nombre"
      >
        <h3 [ngClass]="{ 'text-line-clamp': isMobileOrTablet$() }" class="mt-2 paragraph-1 color-neutrals-base-black text-capitalize">
          {{ producto?.productoPrincipal?.nombre }}
        </h3>
      </a>
    </div>
    <div class="price pt-1">
      <p
        class="pt-2 text-center paragraph-1 color-neutrals-base-black"
        [ngClass]="{ 'text-danger': producto?.productoPrincipal?.mostrarPvpAnterior }"
      >
        {{ producto?.productoPrincipal?.pvp | utilCurrency }}
        @if (producto?.productoPrincipal?.mostrarPvpAnterior) {
          <span class="p text-black strike pl-3">
            {{ producto?.productoPrincipal?.pvpAnterior | utilCurrency }}
          </span>
        }
      </p>
    </div>
  </div>
  <p class="paragraph-3 text-left font-weight-light fade cantidad-modelos text-left ml-2">
    @if (producto.productos.length > 1) {
      {{ producto.productos.length }}
      <span class="paragraph-3 font-weight-light">{{
        (producto.productos.length === 1 ? 'generic.strings.modelo' : 'generic.strings.modelos') | translate
      }}</span>
    }
  </p>
  @if (isDesktop$()) {
    <fgp-product-swiper
      [productos]="producto?.productos"
      [showCarousel]="showCarousel && producto.productos.length > 1"
      [codigo]="producto?.productoPrincipal?.codigo"
      [swiperModelConfig]="swiperModelConfig"
      (selectedProduct)="obtenerInformacionProducto($event)"
    ></fgp-product-swiper>
  }
</article>
