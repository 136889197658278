[
  {
    "title": "ventajas.deliveryTime",
    "description": "ventajas.entrega.desc",
    "icon": "icon-entrega24"
  },
  {
    "title": "ventajas.pago.titulo",
    "description": "ventajas.devolucion.desc",
    "icon": "icon-pago-seguro"
  },
  {
    "title": "ventajas.garantia.titulo",
    "description": "ventajas.garantia.desc",
    "icon": "icon-garantia-2-anios"
  }
]
