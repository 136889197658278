[
  {
    "src": "visa.png",
    "title": "Visa",
    "height": 36,
    "width": 50
  },
  {
    "src": "mastercard.png",
    "title": "Visa",
    "height": 36,
    "width": 50
  },
  {
    "src": "paypal.png",
    "title": "Paypal",
    "height": 36,
    "width": 50
  },
  {
    "src": "bizum.svg",
    "title": "Bizum",
    "height": 15,
    "width": 56,
    "country": ["ES"]
  }
]
