{
	"filtro.color.AM": "Jaune",
	"filtro.color.AZ": "BLEU",
	"filtro.color.AZRS": "bleu / rose",
	"filtro.color.BL": "Blancs",
	"filtro.color.BLRY": "cibles rayées",
	"filtro.color.CABA": "Les chevaux",
	"filtro.color.CHA": "Plaque",
	"filtro.color.CIRC": "zircons",
	"filtro.color.DI": "Numérique",
	"filtro.color.DIAM": "Diamants",
	"filtro.color.DO": "D'or",
	"filtro.color.FU": "fuchsias",
	"filtro.color.GATO": "Chats",
	"filtro.color.GN": "grenats",
	"filtro.color.GR": "Gris",
	"filtro.color.LI": "LILAS",
	"filtro.color.MA": "brunes",
	"filtro.color.MAAG": "MARRON / ARGENT",
	"filtro.color.MF": "ivoires",
	"filtro.color.MULT": "MULTICOLORE",
	"filtro.color.NE": "Noirs",
	"filtro.color.NEAM": "noirs jaunes",
	"filtro.color.NJ": "des oranges",
	"filtro.color.PL": "argenté",
	"filtro.color.PLAZ": "bleu argent",
	"filtro.color.PLNE": "ARGENT / NOIR",
	"filtro.color.PLRS": "roses d'argent",
	"filtro.color.RO": "ROUGE",
	"filtro.color.RONE": "noir rouge",
	"filtro.color.RS": "Des roses",
	"filtro.color.TRAN": "transparent",
	"filtro.color.VE": "VERT",
	"filtro.color.VI": "Violettes",
	"filtro.estilo.CA": "Décontractée",
	"filtro.estilo.CL": "classiques",
	"filtro.estilo.LE": "ÉDITION LIMITÉE",
	"filtro.estilo.SP": "Des sports",
	"filtro.funcion.ANALO": "Analogique",
	"filtro.funcion.CRONO": "chronographes",
	"filtro.funcion.DIGI": "Numérique",
	"filtro.funcion.SMART": "montres intelligentes",
	"filtro.marca.01": "Lotus",
	"filtro.marca.02": "Festina",
	"filtro.marca.03": "Jaguar",
	"filtro.marca.06": "Calypso",
	"filtro.marca.11": "Lotus Style",
	"filtro.marca.12": "Lotus Silver",
	"filtro.marca.16": "Kronaby",
	"filtro.marca.50": "Candino",
	"filtro.marca.TR": "Time Road",
	"filtro.tipoJoya.ANILLO": "Bagues",
	"filtro.tipoJoya.BROCHE": "Broches",
	"filtro.tipoJoya.COLGANTE": "Pendentifs",
	"filtro.tipoJoya.COLLAR": "colliers",
	"filtro.tipoJoya.CONJUNTO": "ensembles",
	"filtro.tipoJoya.GEMELOS": "jumeaux",
	"filtro.tipoJoya.PENDIENTES": "Boucles d’oreilles",
	"filtro.tipoJoya.PIERCING": "Piercings",
	"filtro.tipoJoya.PULSERA": "BRACELETS",
	"filtro.tipoJoya.TOBILLERA": "Chaîne de cheville",
  "filtro.tipoAccesorio.WRITING": "d'écriture"
}
