/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-var-requires */
import { CurrencyPipe, DecimalPipe, registerLocaleData } from '@angular/common';
import { HttpClient, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import localeDeDe from '@angular/common/locales/de';
import localeEnGb from '@angular/common/locales/en-GB';
import localeEsEs from '@angular/common/locales/es';
import localeFrFr from '@angular/common/locales/fr';
import localeItIt from '@angular/common/locales/it';
import { APP_ID, ErrorHandler, inject, LOCALE_ID, NgModule, provideAppInitializer } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouteReuseStrategy, RouterModule } from '@angular/router';
import { JWT_OPTIONS, JwtModule } from '@auth0/angular-jwt';
import {
  APP_CONFIG,
  FOOTER_DATA,
  ICON_COLOR,
  NAVBAR_LOGO,
  PRODUCT_ITEM_LIST_COMPONENT,
  SEO_CONFIG,
  SEO_CONVERSION,
  SEO_DATA,
  SPECIFICATION_I18,
  VENTAJAS_CONFIG
} from '@fgp/app-config';
import { BasketModule } from '@fgp/basket/data-access';
import { FeatureFlagsDataAccessModule } from '@fgp/feature-flag/data-access';
import { FooterBarComponent } from '@fgp/footer/feature-footer-bar';
import { FeatureGoToTopComponent } from '@fgp/go-to-top/feature-go-to-top';
import { iconProviderKronaby } from '@fgp/icon';
import { NavbarLocaleSelectorDataAccessModule } from '@fgp/navbar/locale-selector/data-access';
import { ProductItemListKronabyComponent } from '@fgp/product/feature-product-item-list/kronaby/product-item-list-kronaby';
import { RedirectByCountryDataAccessModule } from '@fgp/redirect-by-country/data-access';
import { ScrollModule } from '@fgp/scroll';
import { HttpInterceptorProviders } from '@fgp/shared/http-interceptors';
import { UtilCurrencyPipe } from '@fgp/shared/pipes/util-currency';
import { AdminSessionService } from '@fgp/shared/services/admin-session';
import { SharedSpinnerFeatureSpinnerModule } from '@fgp/shared/spinner/feature-spinner';
import { UserDataAccessModule } from '@fgp/user/data-access';
import { BreakpointModule } from '@fgp/utils/breakpoint';
import { devToolsModules } from '@fgp/utils/dev-tools';
import { CustomRouteReuseStrategy } from '@fgp/utils/route-reuse';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from '@sentry/angular';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { CookieService } from 'ngx-cookie-service';
import { provideNgxStripe } from 'ngx-stripe';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

registerLocaleData(localeFrFr, 'fr-FR');
registerLocaleData(localeDeDe, 'de-DE');
registerLocaleData(localeEnGb, 'en-GB');
registerLocaleData(localeEsEs, 'es-ES');
registerLocaleData(localeItIt, 'it-IT');

export function jwtOptionsFactory(sessionService: AdminSessionService) {
  return {
    tokenGetter: () => sessionService.getCurrentTokenData(),
    allowedDomains: [environment.JWTDomainPublic],
    disallowedRoutes: [environment.JWTDomainPublic.concat('/public/dmz/services/online/customer/authenticate')]
  };
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function seoProvider() {
  const seoConversionData = new Map();
  environment.idiomasAplicacion.forEach(lang => {
    const data = require(`../assets/json/seo/seo-conversion_${lang}.json`);
    seoConversionData.set(lang, data);
  });
  return seoConversionData;
}

export function seoDataProvider() {
  const seoConversionData = new Map();
  environment.idiomasAplicacion.forEach(lang => {
    const data = require(`../assets/json/seo/seo_${lang}.json`);
    seoConversionData.set(lang, data);
  });
  return seoConversionData;
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    StoreModule.forRoot({ router: routerReducer }),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot(),
    BrowserAnimationsModule,
    devToolsModules,
    RecaptchaV3Module,
    RouterModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [AdminSessionService]
      }
    }),
    RedirectByCountryDataAccessModule,
    SharedSpinnerFeatureSpinnerModule,
    AppRoutingModule,
    UserDataAccessModule,
    FooterBarComponent,
    BreakpointModule,
    BasketModule,
    NavbarLocaleSelectorDataAccessModule,
    FeatureFlagsDataAccessModule,
    FeatureGoToTopComponent,
    ScrollModule
  ],
  providers: [
    provideClientHydration(),
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    provideNgxStripe(),
    iconProviderKronaby,
    { provide: ICON_COLOR, useValue: { default: '#ab6e67', active: '#ab6e67', navbar: '#000' } },
    { provide: APP_ID, useValue: 'kronaby_com_serverApp' },
    NgbActiveModal,
    CookieService,
    HttpInterceptorProviders,
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
    {
      provide: LOCALE_ID,
      useFactory: (translate: TranslateService) => {
        // TODO investigar funcionamiento correcto con Angular Universal
        return translate.currentLang ? translate.currentLang : 'es-ES';
      },
      deps: [TranslateService]
    },
    { provide: APP_CONFIG, useValue: environment },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.reCaptcha.siteKey },
    { provide: 'googleTagManagerId', useValue: environment.google.tagManagerId },
    { provide: 'googleTagManagerAuth', useValue: environment.google.googleTagManagerAuth },
    { provide: 'googleTagManagerPreview', useValue: environment.google.googleTagManagerPreview },
    { provide: VENTAJAS_CONFIG, useValue: require('../assets/json/ventajas.json') },
    { provide: SEO_CONFIG, useValue: require('../assets/json/seo-config.json') },
    { provide: FOOTER_DATA, useValue: require('../assets/json/footer.json') },
    {
      provide: SPECIFICATION_I18,
      useValue: {
        espDe: require('../assets/json/especificaciones/especificaciones_de-DE.json'),
        espEn: require('../assets/json/especificaciones/especificaciones_en-GB.json'),
        espEs: require('../assets/json/especificaciones/especificaciones_es-ES.json'),
        espFr: require('../assets/json/especificaciones/especificaciones_fr-FR.json'),
        espIt: require('../assets/json/especificaciones/especificaciones_it-IT.json')
      }
    },
    { provide: SEO_CONVERSION, useFactory: seoProvider },
    { provide: SEO_DATA, useFactory: seoDataProvider },
    CurrencyPipe,
    DecimalPipe,
    UtilCurrencyPipe,
    { provide: PRODUCT_ITEM_LIST_COMPONENT, useValue: ProductItemListKronabyComponent },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({ showDialog: false })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    provideAppInitializer(() => {
      inject(Sentry.TraceService);
    }),
    { provide: PRODUCT_ITEM_LIST_COMPONENT, useValue: ProductItemListKronabyComponent },
    {
      provide: NAVBAR_LOGO,
      useValue: {
        logo: 'kronaby-negro.svg',
        alt: 'Logo Kronaby',
        title: 'Kronaby.com',
        width: 150,
        height: 70,
        mobileWidth: 179,
        mobileHeight: 52
      }
    }
  ]
})
export class AppModule {}
