{
	"filtro.color.AM": "Gelb",
	"filtro.color.AZ": "Blau",
	"filtro.color.AZRS": "BLAU ROSA",
	"filtro.color.BL": "Weiße",
	"filtro.color.BLRY": "gestreifte Ziele",
	"filtro.color.CABA": "Pferde",
	"filtro.color.CHA": "plattiert",
	"filtro.color.CIRC": "Zirkone",
	"filtro.color.DI": "Digital",
	"filtro.color.DIAM": "Diamanten",
	"filtro.color.DO": "GOLDEN",
	"filtro.color.FU": "Fuchsien",
	"filtro.color.GATO": "Katzen",
	"filtro.color.GN": "Granate",
	"filtro.color.GR": "GRAU",
	"filtro.color.LI": "Flieder",
	"filtro.color.MA": "Braun",
	"filtro.color.MAAG": "BRAUN / SILBER",
	"filtro.color.MF": "Elfenbein",
	"filtro.color.MULT": "Mehrfarbig",
	"filtro.color.NE": "Schwarze",
	"filtro.color.NEAM": "gelbe Schwarze",
	"filtro.color.NJ": "Orangen",
	"filtro.color.PL": "silbrig",
	"filtro.color.PLAZ": "Silber Blau",
	"filtro.color.PLNE": "SILBER SCHWARZ",
	"filtro.color.PLRS": "silberne Rosen",
	"filtro.color.RO": "rot",
	"filtro.color.RONE": "Schwarz Rot",
	"filtro.color.RS": "Rosen",
	"filtro.color.TRAN": "Transparent",
	"filtro.color.VE": "Grün",
	"filtro.color.VI": "Veilchen",
	"filtro.estilo.CA": "Beiläufig",
	"filtro.estilo.CL": "Klassiker",
	"filtro.estilo.LE": "LIMITIERTE AUFLAGE, BESCHRÄNKTE AUFLAGE",
	"filtro.estilo.SP": "Sport",
	"filtro.funcion.ANALO": "Analog",
	"filtro.funcion.CRONO": "Chronographen",
	"filtro.funcion.DIGI": "Digital",
	"filtro.funcion.SMART": "smarte Uhren",
	"filtro.marca.01": "Lotus",
	"filtro.marca.02": "Festina",
	"filtro.marca.03": "Jaguar",
	"filtro.marca.06": "Calypso",
	"filtro.marca.11": "Lotus Style",
	"filtro.marca.12": "Lotus Silver",
	"filtro.marca.16": "Kronaby",
	"filtro.marca.50": "Candino",
	"filtro.marca.TR": "Time Road",
	"filtro.tipoJoya.ANILLO": "Ringe",
	"filtro.tipoJoya.BROCHE": "Broschen",
	"filtro.tipoJoya.COLGANTE": "Anhänger",
	"filtro.tipoJoya.COLLAR": "Halsketten",
	"filtro.tipoJoya.CONJUNTO": "Sets",
	"filtro.tipoJoya.GEMELOS": "Zwillinge",
	"filtro.tipoJoya.PENDIENTES": "Ohrringe",
	"filtro.tipoJoya.PIERCING": "Piercings",
	"filtro.tipoJoya.PULSERA": "ARMBÄNDER",
	"filtro.tipoJoya.TOBILLERA": "Fußkettchen"
}
