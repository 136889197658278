{
  "acabado.AC-OR": "STEEL-GOLD",
  "acabado.B": "brightness",
  "acabado.BP01": "BP01",
  "acabado.BP01C": "COLOR CIRCLE",
  "acabado.BSL": "BSL",
  "acabado.CAUCH": "Rubber",
  "acabado.CORB": "Tie",
  "acabado.CRISA": "CHRYSALIS",
  "acabado.ESTAM": "Print",
  "acabado.F+R": "FLAT + ROUND",
  "acabado.F+S": "FLAT + STRAIGHT",
  "acabado.F+S C": "FLAT + COLOR REC",
  "acabado.F+T": "FLAT + TIE",
  "acabado.FAC06": "FAC06",
  "acabado.FR11": "FRAP 11",
  "acabado.FRA15": "FRAP15",
  "acabado.GRAB": "Recorded",
  "acabado.LAPI": "LAPIDADO",
  "acabado.LASER": "to be",
  "acabado.M": "MATE",
  "acabado.M+S": "MOUNTAIN + STRAIGHT",
  "acabado.M+S C": "MONT.RECT.COLOR",
  "acabado.M+T": "MOUNTAIN + TIE",
  "acabado.MAL": "Mesh",
  "acabado.MB": "MATTE-GLOSS",
  "acabado.MECA": "Machining",
  "acabado.MF5P": "MICROFUSION 5PC",
  "acabado.MMILA": "MILANESE MESH",
  "acabado.MOUNT": "Montana",
  "acabado.MTCA": "MATE CALADA",
  "acabado.OYS": "Oyster",
  "acabado.PIR3": "PYRAMIDAL 3M",
  "acabado.PMP": "PULI-MATE-PULI",
  "acabado.PPM": "POLISHED-MATT PANTER",
  "acabado.PPU": "POLISHED PANTER",
  "acabado.PRES": "President",
  "acabado.PULID": "polished",
  "acabado.RAS": "Scratched",
  "acabado.ROUND": "Round",
  "acabado.S7M": "ESTAM 7 3MAT",
  "acabado.S7R": "STAMP 7PCS STRIPE",
  "acabado.S73M": "ESTAMP 7-3MATE",
  "acabado.ST3": "3PCS STAMPING",
  "acabado.ST4": "STAMPING 4PCS",
  "acabado.ST5": "STAMPED 5PCS",
  "acabado.ST6": "STAMPED 6PCS",
  "acabado.ST7": "7 PIECE STAMPING",
  "acabado.TPMP": "TUBE P-M-P",
  "acabado.TUB": "tube",
  "acabado.V620": "V620",
  "acabado.Y": "POLISHED MOUNTAIN",
  "acabado.YHLO": "MOUNTAIN 1 / 2B + M",
  "accesorios.subtipo.BAG": "Handbags",
  "accesorios.subtipo.BALLPOINT_PEN": "ballpoint pen",
  "accesorios.subtipo.BELT": "Belt",
  "accesorios.subtipo.CARD_HOLDER": "card holder",
  "accesorios.subtipo.DOCUMENT_BAG": "Document bag",
  "accesorios.subtipo.DRESSING_CASE": "Dressing Case",
  "accesorios.subtipo.FOLDER": "folder",
  "accesorios.subtipo.FOUNTAIN_PEN": "fountain pen",
  "accesorios.subtipo.LAPTOP_BAG": "Laptop bag",
  "accesorios.subtipo.LEATHER_PEN_SET": "leather pen set",
  "accesorios.subtipo.OPTIC": "Optical glasses",
  "accesorios.subtipo.OPTIC_SOLAR_CLIP": "",
  "accesorios.subtipo.PEN": "pen",
  "accesorios.subtipo.REPLACEMENT": "Spare part",
  "accesorios.subtipo.REPORTER_BAG": "Shoulder bag",
  "accesorios.subtipo.ROLLERBALL_PEN": "rollerball pen",
  "accesorios.subtipo.SOLAR": "Sunglasses",
  "accesorios.subtipo.WALLET": "wallet",
  "accesorios.subtipo.WATCH_CASE": "Watch case",
  "accesorios.subtipo.WRITING_CASE": "Writing case",
  "accesorios.subtipo.WRITING_SET": "writing set",
  "accesorios.tipo.CLEANER": "cleaners",
  "accesorios.tipo.CUFFLINKS": "Cufflinks",
  "accesorios.tipo.EYEWEAR": "Eyewear",
  "accesorios.tipo.FRAGANCE": "fragrance",
  "accesorios.tipo.JEWELLERY_BOX": "Jewellers",
  "accesorios.tipo.KEYCHAIN": "key rings",
  "accesorios.tipo.LEATHER_ITEM": "leather item",
  "accesorios.tipo.SET": "sets",
  "accesorios.tipo.SPARE_PARTS": "spare parts",
  "accesorios.tipo.TOOL": "tools",
  "accesorios.tipo.WATCHBAND": "Straps",
  "accesorios.tipo.WRITING": "writing",
  "accesorios.tipo_boligrafo.BALLPOINT": "Ballpoint",
  "accesorios.tipo_boligrafo.ROLLERBALL": "Rollerball",
  "agrupacion.coleccion": "collection",
  "agrupacion.seleccion": "selection",
  "anchopuente.14": "14 mm",
  "anchopuente.15": "15 mm",
  "anchopuente.16": "16 mm",
  "anchopuente.17": "17 mm",
  "anchopuente.18": "18 mm",
  "anchopuente.21": "21 mm",
  "calendario.false": "No",
  "calendario.true": "Yes",
  "caracteristicas.alarma": "Alarm",
  "caracteristicas.balancier_visible": "Visible balance",
  "caracteristicas.complicacion_automatico": "Automatic complication",
  "caracteristicas.corona_rosca": "Rosca crown",
  "caracteristicas.dual_time": "Dual Time",
  "caracteristicas.gmt": "GMT",
  "caracteristicas.luminova": "Luminova",
  "caracteristicas.luz": "Light",
  "caracteristicas.reserva_marcha": "Power reserve",
  "caracteristicas.skeleton": "Skeleton",
  "caracteristicas.solar": "Solar",
  "caracteristicas.superluminova": "Superluminova",
  "caracteristicas.tourbillon": "Tourbillon",
  "cierre.agrupado.CAJ": "Deployment",
  "cierre.agrupado.CAP": "BUTTON BOX",
  "cierre.agrupado.CCAT": "Leverback earrings",
  "cierre.agrupado.CCJ": "DRAWER CLOSURE",
  "cierre.agrupado.CGAN": "Hook",
  "cierre.agrupado.CI": "BUTTERFLY",
  "cierre.agrupado.CIP": "Pressure",
  "cierre.agrupado.CLIP": "Clip",
  "cierre.agrupado.CM": "MAGNETIC",
  "cierre.agrupado.COMEGA": "Omega",
  "cierre.agrupado.CPC": "BOX PULS BELT",
  "cierre.agrupado.CPL": "Side",
  "cierre.agrupado.CPR": "Pressure",
  "cierre.agrupado.CSE": "Lockable closure",
  "cierre.agrupado.CSP": "SECURE CLOSURE PUSH BUTTON",
  "cierre.agrupado.HEB": "Buckle",
  "cierre.agrupado.HKSALO": "SAFE LOCK",
  "cierre.agrupado.LI": "Book pressure",
  "cierre.agrupado.MOS": "CARABINER",
  "cierre.agrupado.OCL": "HIDDEN",
  "cierre.agrupado.OCP": "HIDDEN PUSH BUTTON",
  "cierre.agrupado.OCU": "African",
  "cierre.agrupado.ROS": "Screw top",
  "cierre.agrupado.SO": "Double pusher",
  "cierre.agrupado.SOP": "BUTTON FLAP",
  "cierre.CAJ": "Deployment",
  "cierre.CAP": "BUTTON BOX",
  "cierre.CCJ": "DRAWER CLOSURE (STYLE)",
  "cierre.CI": "lobster clasp",
  "cierre.CIP": "PRESSURE CLOSURE",
  "cierre.CM": "MAGNETIC",
  "cierre.CMS": "MAGNETIC WITH INSURANCE",
  "cierre.CPC": "BELT PULS BOX",
  "cierre.CPL": "PULS LATER BOX.",
  "cierre.CPR": "Pressure",
  "cierre.CSE": "Lockable closure",
  "cierre.CSP": "SECURE CLOSURE PUSH BUTTON",
  "cierre.HEB": "Buckle",
  "cierre.HKBB": "BUTTERFLY BUCKLE",
  "cierre.HKCB": "CLIP BUCKLE",
  "cierre.HKCLB": "CLIP LOCK BUCKLE",
  "cierre.HKJEWBUK": "JEWELLERY BUCKLE",
  "cierre.HKLB": "LEATHER BUCKLE",
  "cierre.HKLOBUK": "Butterfly",
  "cierre.HKMB": "MAGNETIC BUCKLE",
  "cierre.HKSALO": "SAFE LOCK",
  "cierre.HKSCB": "SPRING C-CLIP BUCKLE",
  "cierre.HKT-BABUK": "T-BAR BUCKLE",
  "cierre.HOR": "GOLD BUCKLE",
  "cierre.LI": "Book pressure",
  "cierre.MOS": "lobster clasp",
  "cierre.NEO": "DOES NOT CARRY",
  "cierre.NOSE": "Do not know",
  "cierre.OCH": "HIDDEN CLOSURE BUCKLE",
  "cierre.OCL": "Hidden",
  "cierre.OCP": "HIDDEN PUSH BUTTON",
  "cierre.OCS": "HIDDEN FLAP",
  "cierre.OCU": "African",
  "cierre.OPC": "HIDDEN STRAP PUSH",
  "cierre.OSP": "HIDDEN FLAP PULS",
  "cierre.ROS": "THREAD CLOSURE",
  "cierre.SO": "Double pusher",
  "cierre.SOC": "FLAP STRAP",
  "cierre.SOH": "FLAP BUCKLE",
  "cierre.SOP": "BUTTON FLAP",
  "cierre.SPC": "FLAP PULS BELT",
  "cierre.undefined": "",
  "colores_base.1": "Cro",
  "colores_base.2N18": "2N18 GOLDEN",
  "colores_base.2NBN": "CENTER 2N18 BLACK BODY",
  "colores_base.2NMA": "2N18 WITH BRANDS",
  "colores_base.2NTN": "2N18 WITH BLACK FILLET",
  "colores_base.3N": "3N GOLD",
  "colores_base.3N18": "3N18",
  "colores_base.4N18": "LIGHT ROSE GOLD",
  "colores_base.5N": "5N ROSE GOLD",
  "colores_base.5N18": "PINK GOLD",
  "colores_base.026": "Ivory",
  "colores_base.032": "Dark brown",
  "colores_base.037": "HAVANA BROWN",
  "colores_base.041": "MEDIUM GOLD",
  "colores_base.061": "medium blue",
  "colores_base.065": "Light Blue",
  "colores_base.066": "Light Blue",
  "colores_base.068": "Azure",
  "colores_base.084": "Coral",
  "colores_base.100": "SHINY STEEL",
  "colores_base.128": "Nude",
  "colores_base.591": "PVD BLACK",
  "colores_base.600": "Rose Gold",
  "colores_base.840": "STEEL + GOLD",
  "colores_base.850": "STEEL + ROSE GOLD",
  "colores_base.ACCC": "STEEL ZIRCONIA",
  "colores_base.AG": "Silver",
  "colores_base.AGAB": "SILVER AMBER",
  "colores_base.AGCR": "SILVER CRYSTAL",
  "colores_base.AGLA": "LAVENDER SILVER",
  "colores_base.AGLM": "SILVER LIME",
  "colores_base.AGMC": "MARKED SILVER",
  "colores_base.AGMO": "SILVER PURPLE",
  "colores_base.AGPE": "SILVER PEARL",
  "colores_base.AGRO": "SILVER RED",
  "colores_base.AGTP": "SILVER TOPAZ",
  "colores_base.AGU": "Aquamarine",
  "colores_base.AM": "Yellow",
  "colores_base.AMA": "Amethyst",
  "colores_base.AMBA": "Amber",
  "colores_base.AMBR": "bronze yellow",
  "colores_base.AMCC": "YELLOW ZIRCONIA",
  "colores_base.AMMA": "BROWN YELLOW",
  "colores_base.AMPE": "yellow pearl",
  "colores_base.AMT": "Amethyst",
  "colores_base.AQ": "Aquamarine",
  "colores_base.AQBO": "AQUA BOHEMICA",
  "colores_base.AUR": "Dawn",
  "colores_base.AURB": "NORTHERN LIGHTS",
  "colores_base.AVAZ": "BLUE ADVENTURINE",
  "colores_base.AZ": "BLUE",
  "colores_base.AZRS": "BLUE PINK",
  "colores_base.BDIA": "BLACK DIAMOND",
  "colores_base.BE": "Beige",
  "colores_base.BICO": "BICOLOR",
  "colores_base.BL": "White",
  "colores_base.BLCO": "CORAL WHITE",
  "colores_base.BLPE": "WHITE PEARL",
  "colores_base.BLRY": "STRIPED WHITE",
  "colores_base.BR": "Bronze",
  "colores_base.BRCC": "BRONZE ZIRCONS",
  "colores_base.BRIL": "SPARKLY",
  "colores_base.BRLI": "BRONZE LILAC",
  "colores_base.BRRS": "BRONZE PINK",
  "colores_base.BU": "bordeaux",
  "colores_base.BUCR": "BURGUNDY CREAM",
  "colores_base.CA": "Camel",
  "colores_base.CABA": "horse",
  "colores_base.CAL": "Drag",
  "colores_base.CALD": "Boiler",
  "colores_base.CCPE": "ZIRCONIA PEARL",
  "colores_base.CER": "Cherry",
  "colores_base.CH": "Champagne",
  "colores_base.CHA": "PLATED",
  "colores_base.ChGR": "GRAY PLATED",
  "colores_base.CHOC": "chocolate",
  "colores_base.CIRC": "Zirconia",
  "colores_base.CITR": "Citrine",
  "colores_base.CM": "cream",
  "colores_base.CMFU": "FUCHSIA CREAM",
  "colores_base.CO": "Copper",
  "colores_base.CORA": "Coral",
  "colores_base.CQ": "Khaki",
  "colores_base.CR": "CHROME PLATED",
  "colores_base.CRDO": "GOLDEN CRYSTAL",
  "colores_base.CRIS": "Crystal",
  "colores_base.CRTR": "TRANSPARENT CRYSTAL",
  "colores_base.CURR": "CURRO",
  "colores_base.CZ": "quartz",
  "colores_base.CZAZ": "BLUE QUARTZ",
  "colores_base.CZMA": "BROWN QUARTZ",
  "colores_base.CZRS": "PINK QUARTZ",
  "colores_base.DELF": "Dolphin",
  "colores_base.DI": "Digital",
  "colores_base.DIAM": "Diamonds",
  "colores_base.DO": "Gold",
  "colores_base.DONE": "GOLDEN BLACK",
  "colores_base.ESM": "Emerald",
  "colores_base.EXPO": "EXPO",
  "colores_base.FLOR": "Flower",
  "colores_base.FLUO": "FLUORESCENT",
  "colores_base.FOCA": "Seal",
  "colores_base.FR": "Strawberry",
  "colores_base.FRAM": "raspberry",
  "colores_base.FU": "Fuchsia",
  "colores_base.FUCC": "FUCHSIA ZIRCONS",
  "colores_base.FUME": "FUME",
  "colores_base.GATO": "cat",
  "colores_base.GN": "Garnet",
  "colores_base.GR": "GRAY",
  "colores_base.GRA": "Garnet",
  "colores_base.GRAG": "Grey silver",
  "colores_base.GRPL": "silver grey",
  "colores_base.HA": "Hamilton",
  "colores_base.HAB": "Havana",
  "colores_base.HAHA": "HAMILTON HAMILTON",
  "colores_base.HAMC": "HAMILTON MARKED",
  "colores_base.HANA": "HAMILTON NACAR",
  "colores_base.HANC": "HAMILTON MOTHER OF PEARL",
  "colores_base.HEM": "HEMATITE",
  "colores_base.INDI": "INDICOLITE",
  "colores_base.JET": "Jet",
  "colores_base.KOAL": "Koala",
  "colores_base.LI": "lilac",
  "colores_base.LIOS": "DARK LILAC",
  "colores_base.LIPE": "LILAC PEARL",
  "colores_base.LUMI": "SUPERLUMINOVA",
  "colores_base.LUN": "Moon",
  "colores_base.MA": "brown",
  "colores_base.MAAG": "BROWN / SILVER",
  "colores_base.MAPE": "PEARL BROWN",
  "colores_base.MBLU": "METALLIC BLUE",
  "colores_base.MC": "Marked",
  "colores_base.ME": "Peach",
  "colores_base.MEN": "Mint",
  "colores_base.MF": "Ivory",
  "colores_base.MFNE": "IVORY-BLACK",
  "colores_base.MGRE": "METALLIC GREEN",
  "colores_base.MO": "Purple",
  "colores_base.MOBL": "PURPLE WHITE",
  "colores_base.MOS": "Mustard",
  "colores_base.MS": "Mustard",
  "colores_base.MSIL": "metallic silver",
  "colores_base.MT": "MATE",
  "colores_base.MULT": "multicolored",
  "colores_base.MULTAM": "MULTICOLOR YELLOW",
  "colores_base.MULTAZ": "MULTICOLOR BLUE",
  "colores_base.MULTRS": "MULTICOLOR PINK",
  "colores_base.NA": "NACRE",
  "colores_base.NAAZ": "NACAR BLUE",
  "colores_base.NE": "Black",
  "colores_base.NEAM": "Black yellow",
  "colores_base.NECC": "BLACK ZIRCONS",
  "colores_base.NEDO": "Black Gold",
  "colores_base.NEGD": "BLACK DIAMOND",
  "colores_base.NEO": "DOES NOT CARRY",
  "colores_base.NEPE": "BLACK PEARL",
  "colores_base.NJ": "Orange",
  "colores_base.NJNC": "ORANGE PEARL",
  "colores_base.NYB": "WHITE NYLON",
  "colores_base.NYBE": "NYLON BEIG",
  "colores_base.NYBL": "WHITE NYLON",
  "colores_base.NYC": "NYLON CREAM",
  "colores_base.NYCM": "NYLON CREAM",
  "colores_base.NYG": "GRAY NYLON",
  "colores_base.NYGR": "GRAY NYLON",
  "colores_base.NYM": "NYLON BROWN",
  "colores_base.NYMA": "NYLON BROWN",
  "colores_base.NYN": "BLACK NYLON",
  "colores_base.NYNE": "BLACK NYLON",
  "colores_base.NYO": "NYLON OCHER",
  "colores_base.NYR": "RED NYLON",
  "colores_base.OBSI": "OBSIDIAN",
  "colores_base.OC": "OCHER",
  "colores_base.OCR": "OCHER",
  "colores_base.OLIV": "OLIVINA",
  "colores_base.ONIX": "onyx",
  "colores_base.OR": "gold",
  "colores_base.ORAZ": "BLUE GOLD",
  "colores_base.PACH": "BLUE CHAMPAGNE",
  "colores_base.PAJA": "Bird",
  "colores_base.PAV": "PAVONADO",
  "colores_base.PD": "Palladium",
  "colores_base.PER": "Pearl",
  "colores_base.PERN": "BLACK PEARL",
  "colores_base.PERR": "Dog",
  "colores_base.PLAG": "SILVER SILVER",
  "colores_base.PLAM": "SILVER YELLOW",
  "colores_base.PLAZ": "BLUE SILVER",
  "colores_base.PLLI": "LILAC SILVER",
  "colores_base.PLNE": "SILVER / BLACK",
  "colores_base.PLPL": "SILVER SILVER",
  "colores_base.PLRS": "PINK SILVER",
  "colores_base.PLVE": "silver green",
  "colores_base.PV": "PAVONADO",
  "colores_base.PVCH": "BLUE CHAMPAGNE",
  "colores_base.PVD": "PVD",
  "colores_base.RH": "Rhodium",
  "colores_base.RH5N": "WHITE GOLD + 5N GOLD",
  "colores_base.RO": "RED",
  "colores_base.ROCA": "RED CALADA",
  "colores_base.ROMS": "RED MUSTARD",
  "colores_base.RONE": "Red Black",
  "colores_base.RS": "Rosé",
  "colores_base.RSCC": "PINK ZIRCONIA",
  "colores_base.RSPE": "PEARL PINK",
  "colores_base.RSRO": "PINK RED",
  "colores_base.RU": "RUTHENIUM",
  "colores_base.RUBI": "Ruby",
  "colores_base.SASA": "SALMON SALMON",
  "colores_base.ST": "SEMITRANSPARENT",
  "colores_base.TA": "BLUE TIGER",
  "colores_base.TEJA": "ROOF TILE",
  "colores_base.TIT": "TITANIUM",
  "colores_base.TOP": "TOPAZIO",
  "colores_base.TP": "Topaz",
  "colores_base.TPCL": "LIGHT TOPAZ",
  "colores_base.TR": "PINK TIGER",
  "colores_base.TRAN": "Transparent",
  "colores_base.TRCO": "TRICOLOR",
  "colores_base.TRI": "TRICOLOR",
  "colores_base.TRNE": "TRANSP BLACK",
  "colores_base.TW/W": "TOP WESSELTON TO WESSELTON",
  "colores_base.VA": "Vanilla",
  "colores_base.VE": "GREEN",
  "colores_base.VEBRI": "SHINING GREEN",
  "colores_base.VECC": "GREEN ZIRCONIA",
  "colores_base.VI": "violet",
  "colores_base.VIDI": "DIGITAL VIOLET",
  "colores_base.VIGR": "VIOLET GRAY",
  "colores_base.VIN": "Came",
  "colores_base.VIOS": "dark violet",
  "colores_base.ZAFI": "Sapphire",
  "colores_catalogo.AG": "Silver",
  "colores_catalogo.AM": "Yellow",
  "colores_catalogo.AZ": "BLUE",
  "colores_catalogo.BE": "Beige",
  "colores_catalogo.BL": "White",
  "colores_catalogo.BU": "bordeaux",
  "colores_catalogo.CH": "Champagne",
  "colores_catalogo.CM": "cream",
  "colores_catalogo.CO": "Copper",
  "colores_catalogo.DO": "Gold",
  "colores_catalogo.GR": "GRAY",
  "colores_catalogo.MA": "brown",
  "colores_catalogo.MULT": "multicolored",
  "colores_catalogo.NA": "NACRE",
  "colores_catalogo.NE": "Black",
  "colores_catalogo.NJ": "Orange",
  "colores_catalogo.PL": "Silver",
  "colores_catalogo.PLA": "Silver",
  "colores_catalogo.PLAZ": "BLUE SILVER",
  "colores_catalogo.PLRS": "PINK SILVER",
  "colores_catalogo.RO": "RED",
  "colores_catalogo.ROSE": "Rosé",
  "colores_catalogo.RS": "Rosé",
  "colores_catalogo.VE": "GREEN",
  "colores_catalogo.VI": "violet",
  "correa.agrupada.ARMYS": "Stainless Steel Bracelet",
  "correa.agrupada.CORREA": "Leather/Rubber Strap",
  "estanqueidad.1A": "1 ATM",
  "estanqueidad.3A": "3 ATM",
  "estanqueidad.5A": "5 ATM",
  "estanqueidad.10A": "10 ATM",
  "estanqueidad.20A": "20 ATM",
  "estanqueidad.?": "No tightness",
  "estanqueidad.IP68": "IP68",
  "estanqueidad.NEO": "Does not have",
  "estanqueidad.R.P.": "DUST RESISTANT",
  "estanqueidad.RP": "DUST RESISTANT",
  "estanqueidad.SHPR": "SHOWERPROOF",
  "estanqueidad.W.P.": "WATER PROFF.",
  "estanqueidad.W.R.": "Water resistant",
  "estanqueidad.WR": "Water resistant",
  "estilos.CA": "Casual",
  "estilos.CH": "Chic",
  "estilos.CL": "Classic",
  "estilos.EL": "elegance",
  "estilos.LE": "LIMITED EDITION",
  "estilos.SP": "Sports",
  "estilos.VI": "Vintage",
  "formaCaja.C": "Square",
  "formaCaja.CR": "Cross",
  "formaCaja.DI": "Digital",
  "formaCaja.IR": "IRREGULAR",
  "formaCaja.O": "Oval",
  "formaCaja.RC": "Rectangular",
  "formaCaja.RD": "Round",
  "formaCaja.TO": "Barrel",
  "formaCaja.TV": "Television",
  "funcion.agrupada.101": "Digital Functions",
  "funcion.agrupada.102": "Multifunction",
  "funcion.agrupada.103": "HOURS AND MINUTES",
  "funcion.agrupada.104": "Chronograph",
  "funcion.agrupada.105": "Smart Functions",
  "gamas.?": "",
  "gamas.agrupada.BE": "Baby",
  "gamas.agrupada.C": "man",
  "gamas.agrupada.IN": "Kids",
  "gamas.agrupada.NA": "Girl",
  "gamas.agrupada.NI": "Boy",
  "gamas.agrupada.S": "woman",
  "gamas.agrupada.UN": "Unisex",
  "gamas.AN": "Rings",
  "gamas.BE": "Baby",
  "gamas.C": "man",
  "gamas.CD": "JUNIOR",
  "gamas.CO": "pendant",
  "gamas.IN": "JUNIOR",
  "gamas.NA": "JUNIOR",
  "gamas.NI": "JUNIOR",
  "gamas.PE": "woman",
  "gamas.PU": "BRACELETS",
  "joyas.tipo.agrupada.ANILLO": "Ring",
  "joyas.tipo.agrupada.BROCHE": "Brooch",
  "joyas.tipo.agrupada.CIERRE": "Fasteners",
  "joyas.tipo.agrupada.COLGANTE": "pendant",
  "joyas.tipo.agrupada.COLLAR": "necklace",
  "joyas.tipo.agrupada.CONJUNTO": "Set",
  "joyas.tipo.agrupada.GEMELOS": "Twins",
  "joyas.tipo.agrupada.PENDIENTE_CADENA": "CHAIN EARRING",
  "joyas.tipo.agrupada.PENDIENTE_TREPADOR": "CLIMBING EARRING",
  "joyas.tipo.agrupada.PENDIENTES": "SLOPES",
  "joyas.tipo.agrupada.PIERCING": "Piercing",
  "joyas.tipo.agrupada.PULSERA": "bracelet",
  "joyas.tipo.agrupada.TOBILLERA": "anklet",
  "joyas.tipo.ANILLO": "Ring",
  "joyas.tipo.BROCHE": "Brooch",
  "joyas.tipo.COLGANTE": "pendant",
  "joyas.tipo.COLLAR": "necklace",
  "joyas.tipo.CONJUNTO": "Set",
  "joyas.tipo.GEMELOS": "Twins",
  "joyas.tipo.PENDIENTE_CADENA": "CHAIN EARRING",
  "joyas.tipo.PENDIENTE_TREPADOR": "CLIMBING EARRING",
  "joyas.tipo.PENDIENTES": "SLOPES",
  "joyas.tipo.PIERCING": "Piercing",
  "joyas.tipo.PULSERA": "bracelet",
  "joyas.tipo.sub.1-1": "LISA",
  "joyas.tipo.sub.1-2": "WITH STONES",
  "joyas.tipo.sub.1-3": "WITH DIAMONDS",
  "joyas.tipo.sub.1-4": "LISA ALLIANCE",
  "joyas.tipo.sub.1-5": "LAPIDADA ALLIANCE",
  "joyas.tipo.sub.1-6": "ALLIANCE WITH STONE",
  "joyas.tipo.sub.1-7": "Seal",
  "joyas.tipo.sub.1-8": "ENAMEL PRINT",
  "joyas.tipo.sub.1-9": "DIAMOND ALLIANCE",
  "joyas.tipo.sub.1-10": "Print",
  "joyas.tipo.sub.1-11": "ENAMEL",
  "joyas.tipo.sub.1-12": "Draft",
  "joyas.tipo.sub.1-13": "PIDRA CRISTAL",
  "joyas.tipo.sub.1-14": "SWAROVSKI",
  "joyas.tipo.sub.2-1": "Smooth",
  "joyas.tipo.sub.2-2": "WITH STONES",
  "joyas.tipo.sub.2-3": "SCAPULAR",
  "joyas.tipo.sub.2-4": "SMOOTH CROSS",
  "joyas.tipo.sub.2-5": "VIRGIN GIRL",
  "joyas.tipo.sub.2-6": "PARCHMENT / PLATE",
  "joyas.tipo.sub.2-7": "Little angels",
  "joyas.tipo.sub.2-8": "CROSS WITH STONES",
  "joyas.tipo.sub.2-9": "CROSS WITH CHRIST",
  "joyas.tipo.sub.2-10": "FETISH",
  "joyas.tipo.sub.2-11": "CROSS WITH DIAMONDS",
  "joyas.tipo.sub.2-12": "WITH DIAMONDS",
  "joyas.tipo.sub.2-13": "FETISH WITH STONES",
  "joyas.tipo.sub.2-14": "Elastic",
  "joyas.tipo.sub.2-15": "Virgin",
  "joyas.tipo.sub.2-16": "CHRIST",
  "joyas.tipo.sub.2-17": "CRUZ CARAVACA",
  "joyas.tipo.sub.2-18": "ENAMEL",
  "joyas.tipo.sub.2-19": "COMMUNION",
  "joyas.tipo.sub.2-20": "Draft",
  "joyas.tipo.sub.2-21": "CRUZ CALADA",
  "joyas.tipo.sub.3-1": "CHATON",
  "joyas.tipo.sub.3-2": "Zirconia",
  "joyas.tipo.sub.3-3": "COLOR STONE",
  "joyas.tipo.sub.3-4": "ENAMEL",
  "joyas.tipo.sub.3-5": "ELECTROFORMING",
  "joyas.tipo.sub.3-6": "PEARLS",
  "joyas.tipo.sub.3-7": "YOU AND I",
  "joyas.tipo.sub.3-8": "Ring",
  "joyas.tipo.sub.3-9": "Ball",
  "joyas.tipo.sub.3-10": "Various",
  "joyas.tipo.sub.3-11": "Ceramics",
  "joyas.tipo.sub.3-12": "LONG WITH STONES",
  "joyas.tipo.sub.3-13": "Ring",
  "joyas.tipo.sub.3-14": "ENAMEL",
  "joyas.tipo.sub.3-15": "WITH DIAMONDS",
  "joyas.tipo.sub.3-16": "Elastic",
  "joyas.tipo.sub.3-17": "STONE HOOP",
  "joyas.tipo.sub.3-18": "Print",
  "joyas.tipo.sub.3-19": "Draft",
  "joyas.tipo.sub.4-1": "LISA",
  "joyas.tipo.sub.4-2": "ENGRAVED",
  "joyas.tipo.sub.4-3": "curly",
  "joyas.tipo.sub.4-4": "CURLY WITH STONES",
  "joyas.tipo.sub.4-5": "SMOOTH WITH STONES",
  "joyas.tipo.sub.4-6": "SMOOTH WITH DIAMONDS",
  "joyas.tipo.sub.4-7": "ENAMEL",
  "joyas.tipo.sub.4-8": "WITH SKIN",
  "joyas.tipo.sub.5-1": "1st POSITION WITH PLATE",
  "joyas.tipo.sub.5-2": "Fine",
  "joyas.tipo.sub.5-3": "Gross",
  "joyas.tipo.sub.5-4": "THICK SOLID WITH SHEET",
  "joyas.tipo.sub.5-5": "THICK SOLID",
  "joyas.tipo.sub.5-6": "THICK HOLLOW WITH BEADS",
  "joyas.tipo.sub.5-7": "THICK HOLLOW",
  "joyas.tipo.sub.5-8": "THICK WITH STONES",
  "joyas.tipo.sub.5-9": "RIVIERE",
  "joyas.tipo.sub.5-10": "THICK HOLLOW WITH SHEET",
  "joyas.tipo.sub.5-11": "FINE HOLLOW WITH PLATE",
  "joyas.tipo.sub.5-12": "WITH STONE",
  "joyas.tipo.sub.5-13": "Balls",
  "joyas.tipo.sub.5-14": "BALL WITH STONE",
  "joyas.tipo.sub.5-15": "1st POSITION",
  "joyas.tipo.sub.5-16": "FINE HOLLOW",
  "joyas.tipo.sub.5-17": "FINE SOLID WITH SHEET",
  "joyas.tipo.sub.5-18": "Elastic",
  "joyas.tipo.sub.5-19": "WITH BEADS",
  "joyas.tipo.sub.5-20": "LEATHER STRAP",
  "joyas.tipo.sub.5-21": "WITH DIAMONDS",
  "joyas.tipo.sub.5-22": "DIAMOND LEATHER STRAP",
  "joyas.tipo.sub.5-23": "WITH PEARLS",
  "joyas.tipo.sub.5-24": "Rubber strap",
  "joyas.tipo.sub.6-1": "FINE HOLLOW",
  "joyas.tipo.sub.6-2": "THICK HOLLOW",
  "joyas.tipo.sub.6-3": "FINE SOLID",
  "joyas.tipo.sub.6-4": "THICK SOLID",
  "joyas.tipo.sub.6-5": "RUBBER WITH CLOSURE",
  "joyas.tipo.sub.6-6": "LEATHER WITH SILVER",
  "joyas.tipo.sub.6-7": "LEATHER WITH GOLD CLOSURE",
  "joyas.tipo.sub.6-8": "HOLLOW LAPIDADO",
  "joyas.tipo.sub.6-9": "NYLON CLOSURE PLAT",
  "joyas.tipo.sub.7-1": "HOLLOW LAPIDADO",
  "joyas.tipo.sub.7-2": "FINE WITH STONE",
  "joyas.tipo.sub.7-3": "FINA WITH PENDANT",
  "joyas.tipo.sub.7-4": "Rings",
  "joyas.tipo.sub.7-5": "FINE STONE PENDANT",
  "joyas.tipo.sub.7-6": "THICK WITH PENDANT",
  "joyas.tipo.sub.7-7": "Fine",
  "joyas.tipo.sub.7-8": "Balls",
  "joyas.tipo.sub.7-9": "FINA WITH ENAMEL PENDANT",
  "joyas.tipo.sub.7-10": "THICK WITH STONES",
  "joyas.tipo.sub.7-11": "HOLLOW THICK",
  "joyas.tipo.sub.7-12": "FINE HOLLOW",
  "joyas.tipo.sub.7-13": "RUBBER WITH PENDANT",
  "joyas.tipo.sub.7-14": "FINA DIAMOND PENDANT",
  "joyas.tipo.sub.7-15": "Elastic",
  "joyas.tipo.sub.7-16": "THICK WITH DIAMONDS",
  "joyas.tipo.sub.7-17": "LEATHER CORD",
  "joyas.tipo.sub.7-18": "CORD WITH PENDANT",
  "joyas.tipo.sub.7-19": "WITH BEADS",
  "joyas.tipo.sub.8-1": "1st POSITION",
  "joyas.tipo.sub.8-2": "Tie",
  "joyas.tipo.sub.9-1": "FINE SMOOTH",
  "joyas.tipo.sub.9-2": "THICK SMOOTH",
  "joyas.tipo.sub.9-3": "FINE WITH STONES",
  "joyas.tipo.sub.9-4": "THICK WITH STONES",
  "joyas.tipo.sub.9-5": "FINE WITH DIAMONDS",
  "joyas.tipo.sub.9-6": "THICK WITH DIAMONDS",
  "joyas.tipo.sub.9-7": "Balls",
  "joyas.tipo.sub.9-8": "RIVIERE",
  "joyas.tipo.sub.10-1": "Smooth",
  "joyas.tipo.sub.10-2": "WITH STONES",
  "joyas.tipo.sub.10-3": "PEARLS",
  "joyas.tipo.sub.11-1": "Smooth",
  "joyas.tipo.sub.11-2": "WITH STONES",
  "joyas.tipo.sub.12-1": "WITH DIAMONDS",
  "joyas.tipo.sub.12-2": "Smooth",
  "joyas.tipo.sub.13-1": "Smooth",
  "joyas.tipo.sub.13-2": "WITH STONES",
  "joyas.tipo.sub.13-3": "WITH DIAMONDS",
  "joyas.tipo.sub.14-1": "Pressure",
  "joyas.tipo.sub.14-2": "Thread",
  "joyas.tipo.sub.15-1": "WITH BEADS",
  "joyas.tipo.sub.17-1": "BOLTS PAIR",
  "joyas.tipo.sub.25-1": "EASY CLICK",
  "joyas.tipo.sub.99": "Undefined",
  "joyas.tipo.sub.900-1": "9K YELLOW",
  "joyas.tipo.sub.900-2": "9K WHITE",
  "joyas.tipo.sub.900-3": "9K BICOLOR",
  "joyas.tipo.sub.undefined": "",
  "joyas.tipo.TOBILLERA": "anklet",
  "kilataje.ORO9K": "9K GOLD",
  "kilataje.ORO18K": "18K GOLD",
  "longpatillas.140": "140 mm",
  "longpatillas.142": "142 mm",
  "longpatillas.145": "145 mm",
  "material.9K": "375ml 9K Gold",
  "material.18K": "18K GOLD",
  "material.24K": "FINE GOLD",
  "material.?": "?",
  "material.AC": "Steel",
  "material.AC-EB": "STEEL-EBANE",
  "material.ACAC": "STEEL AND STEEL",
  "material.ACBI": "STEEL-BICOLOR",
  "material.ACBR": "BRIGHT STEEL",
  "material.ACCA": "STEEL AND RUBBER",
  "material.ACCC": "STEEL ZIRCONIA",
  "material.ACCE": "CERAMIC STEEL",
  "material.ACCH": "PLATED STEEL",
  "material.ACDI": "STEEL DIAMONDS",
  "material.ACE": "316l stainless steel",
  "material.ACERO": "Stainless Steel",
  "material.ACETA": "Acetat",
  "material.ACFC": "CARBON FIBER",
  "material.ACHB": "BICOLOUR PLATED STEEL",
  "material.ACHO": "GOLD PLATED STEEL",
  "material.ACMA": "STEEL AND WOOD",
  "material.ACOR": "STEEL AND GOLD",
  "material.ACPI": "STEEL AND LEATHER",
  "material.ACPL": "STEEL AND PLASTIC",
  "material.ACTI": "STEEL TITANIUM",
  "material.AGCI": "SILVER-ZIRCONIA",
  "material.AGES": "ENAMELED SILVER",
  "material.AGG": "Silver",
  "material.AGPE": "SILVER AND PEARLS",
  "material.agrupado.ACERO": "Steel",
  "material.agrupado.ACETA": "ACETATE",
  "material.agrupado.CAUCHO": "Rubber",
  "material.agrupado.CERAMICA": "Ceramics",
  "material.agrupado.CUERO": "leather",
  "material.agrupado.GOMA": "Rubber",
  "material.agrupado.METAL": "METAL",
  "material.agrupado.ORO": "gold",
  "material.agrupado.ORO9K": "GOLD9K",
  "material.agrupado.ORO18K": "GOLD18K",
  "material.agrupado.PLATA": "Silver",
  "material.agrupado.TITANIO": "TITANIUM",
  "material.AGS": "SWAROVSKI SILVER",
  "material.ALG": "Cotton",
  "material.ALUM": "Aluminum",
  "material.ALZ": "Alloy",
  "material.ANTE": "Before",
  "material.BELC": "BELCRO",
  "material.BICO": "BICOLOR",
  "material.BRO": "Bronze",
  "material.C18K": "RUBBER + GOLD CLOSURE",
  "material.CAAC": "RUBBER STEEL",
  "material.CAU": "Rubber",
  "material.CAUCHO": "Rubber",
  "material.CEBR": "CERAMIC-BRILLIANT",
  "material.CER": "Ceramics",
  "material.CERAMICA": "Ceramics",
  "material.CHA": "Mixed",
  "material.CHAP": "PLATED",
  "material.CHBI": "BICOLOUR PLATED",
  "material.CHOR": "GOLD PLATED",
  "material.COCO": "CROCODILE SKIN",
  "material.COCT": "COCO CUT",
  "material.CRBI": "BICOLOR CHROME",
  "material.CRMO": "Chrome",
  "material.CRO": "CHROME PLATED",
  "material.CU": "Rope",
  "material.CUERO": "leather",
  "material.FIB": "Fiber",
  "material.GO": "Rubber",
  "material.GOCA": "RUBBER RUBBER",
  "material.GOM": "Rubber",
  "material.HNAU": "NAUTICAL WIRE",
  "material.LACH": "PLATED BRASS",
  "material.LACR": "CHROME BRASS",
  "material.LACT": "CUT LIZARD",
  "material.LAG": "LIZARD",
  "material.LAT": "Brass",
  "material.LCHB": "BICOLOUR PLATED BRASS",
  "material.LIQ": "Liquid",
  "material.METAL": "METAL",
  "material.MIL": "MINERAL MAGNIFIER",
  "material.MIN": "MINERAL",
  "material.MINP": "FLAT MINERAL",
  "material.MINT": "TINTED MINERAL",
  "material.NEO": "NEOPRENE",
  "material.NOB": "NOBUCK",
  "material.NY": "Nylon",
  "material.NYBE": "NYLON BELCRO",
  "material.NYCA": "NYLON RUBBER",
  "material.NYCT": "NYLON CUT",
  "material.NYL": "Nylon",
  "material.NYPC": "NYLON-LEATHER UPPER",
  "material.NYPI": "NYLON BASE LEATHER",
  "material.O18": "18K GOLD",
  "material.OASP": "18K GOLD WITHOUT PRICE",
  "material.OB5N": "WHITE GOLD + 5N GOLD",
  "material.OBSP": "WHITE GOLD WITHOUT PRICE",
  "material.OR18": "GOLD 18 KTES.",
  "material.OR24": "GOLD 24 KTES.",
  "material.ORO": "Gold",
  "material.OROB": "18k White gold",
  "material.P+O": "LEATHER + GOLD CLOSURE",
  "material.P-CC": "LEATHER C / CUT (7.5X4.2)",
  "material.PABA": "PAVE BALL",
  "material.PALD": "Palladium",
  "material.PAST": "Pasta",
  "material.PC": "PLASTIC + GLASS FIBER",
  "material.PEL": "PLEX MAGNIFIER",
  "material.PI": "Skin",
  "material.PI-C": "LEATHER C / CUT (7.5X3.8)",
  "material.PICA": "RUBBER LEATHER",
  "material.PICH": "PATENT LEATHER",
  "material.PICT": "LEATHER CUT",
  "material.PIEL": "leather",
  "material.PIEL-PU": "LEATHER-POLYURETHANE",
  "material.PIGR": "ENGRAVED LEATHER",
  "material.PILI": "SMOOTH SKIN",
  "material.PINA": "NAYLON LEATHER",
  "material.PINY": "NYLON LEATHER",
  "material.PIPL": "PLASTIFIED SKIN",
  "material.PIRA": "STRIPED LEATHER",
  "material.PISI": "SYNTHETIC SKIN",
  "material.PISIN": "SYNTHETIC SKIN",
  "material.PL": "PLEX",
  "material.PLA": "Plastic",
  "material.PLAA": "PLASTIC RING",
  "material.PLAL": "PLASTIC-MAGNIFIER",
  "material.PLAS": "Plastic",
  "material.PLAT": "Silver 925 ml",
  "material.PLATA": "Silver",
  "material.PLEX": "PLASTIC PLEX",
  "material.PLNY": "PLASTIC NYLON",
  "material.PLPI": "LEATHER BASE PLASTIC",
  "material.PLPL": "PLPL",
  "material.PLXL": "PLAST + MAGNIFIER",
  "material.POLICARBONATO": "POLYCARBONATE",
  "material.PU": "Polyurethane",
  "material.PUPI": "LEATHER BASE PURPURINE",
  "material.PVC": "PVC",
  "material.RAYA": "STRIPED LEATHER",
  "material.RH": "Rhodium",
  "material.RU": "RUTHENIUM",
  "material.SERP": "Snake",
  "material.SPBP": "SYNTHETIC LEATHER BASE",
  "material.STBP": "SYNTHETIC LEATHER BASE",
  "material.SWA": "SWAROVSKI",
  "material.TELA": "Cloth",
  "material.TEPI": "LEATHER BASE FABRIC",
  "material.TIBI": "BICOLOUR TITANIUM",
  "material.TIBU": "SHARK SKIN",
  "material.TIT": "TITANIUM",
  "material.TITANIO": "Titanium",
  "material.TORT": "TURTLE SKIN",
  "material.TUNG": "TUNGSTEN",
  "material.undefined": "",
  "material.Z+L": "SAPPHIRE + MAGNIFIER",
  "material.Z-G": "ZAF + MAGNIFIER + ENGRAVING",
  "material.ZAF": "Sapphire",
  "material.ZAFP": "PAINTED SAPPHIRE",
  "movimiento.funcion.1": "SIMPLE (CAL)",
  "movimiento.funcion.4": "CHRONO (NO CAL)",
  "movimiento.funcion.12": "RESERVE-START",
  "movimiento.funcion.101": "Digital",
  "movimiento.funcion.102": "Multifunction",
  "movimiento.funcion.103": "QUARTZ",
  "movimiento.funcion.104": "Chronograph",
  "movimiento.funcion.105": "Digital",
  "movimiento.funcion.106": "Digital",
  "movimiento.funcion.107": "ANALOG ALARM",
  "movimiento.funcion.108": "Multifunction",
  "movimiento.funcion.109": "DUAL-TIME",
  "movimiento.funcion.110": "Automatics",
  "movimiento.funcion.111": "Tourbillon",
  "movimiento.funcion.112": "GMT",
  "movimiento.funcion.113": "RESERVE-START",
  "movimiento.funcion.agrupada.ANALO": "Analogical",
  "movimiento.funcion.agrupada.AUTO": "Automatics",
  "movimiento.funcion.agrupada.CRONO": "Chronograph",
  "movimiento.funcion.agrupada.DIGI": "Digital",
  "movimiento.funcion.agrupada.HYBRID": "Hybrids",
  "movimiento.funcion.agrupada.MULTI": "Multifunction",
  "movimiento.funcion.agrupada.SMART": "Smartwatch",
  "movimiento.funcion.ANA-AL": "ANALOG ALARM",
  "movimiento.funcion.ANA-DI": "DIGITAL ANALOG",
  "movimiento.funcion.ANA-DIGI": "DIGITAL ANALOG",
  "movimiento.funcion.AUT-AL": "AUTOMATIC-ALARM",
  "movimiento.funcion.AUTO": "Automatics",
  "movimiento.funcion.CRO-SUI": "SWISS CHRONOGRAPH",
  "movimiento.funcion.CRO.SOL": "SOLAR CHRONO",
  "movimiento.funcion.CRONO": "Chronograph",
  "movimiento.funcion.CRONO-AL": "CHRONO-ALARM",
  "movimiento.funcion.DI-MULTI": "DIGITAL MULTIFUCION",
  "movimiento.funcion.DIGI": "Digital",
  "movimiento.funcion.DUAL-TIM": "DUAL-TIME",
  "movimiento.funcion.HYBRID": "Hybrids",
  "movimiento.funcion.MULTI": "Multifunction",
  "movimiento.funcion.QUTZ-ANA": "QUARTZ / ANALOG",
  "movimiento.funcion.SI": "Simple",
  "movimiento.funcion.SIM": "Simple",
  "movimiento.funcion.SIM-SUI": "SIMPLE SWISS",
  "movimiento.funcion.SMART": "SMART WATCH",
  "movimiento.tipo.01": "Digital",
  "movimiento.tipo.1": "SIMPLE (CAL)",
  "movimiento.tipo.02": "DIGITAL ANALOG",
  "movimiento.tipo.03": "QUARTZ / ANALOG",
  "movimiento.tipo.04": "Automatics",
  "movimiento.tipo.05": "HIBRID",
  "movimiento.tipo.06": "SMART WATCH",
  "movimiento.tipo.07": "Solar",
  "movimiento.tipo.7": "MULTIF (CAL)",
  "movimiento.tipo.12": "RESERVE-START",
  "movimiento.tipo.ANA-AL": "ANALOG ALARM",
  "movimiento.tipo.ANA-DI": "DIGITAL ANALOG",
  "movimiento.tipo.ANA-DIGI": "DIGITAL ANALOG",
  "movimiento.tipo.AUT-AL": "AUTOMATIC-ALARM",
  "movimiento.tipo.AUTO": "Automatics",
  "movimiento.tipo.AUTO-CRO": "AUTOMATIC CHRONOGRAPH",
  "movimiento.tipo.AUTOM": "Automatics",
  "movimiento.tipo.C": "Rope",
  "movimiento.tipo.CRO-SUI": "SWISS CHRONOGRAPH",
  "movimiento.tipo.CRONO": "Chronograph",
  "movimiento.tipo.CUER-MEC": "ROPE / MECHANICAL",
  "movimiento.tipo.CUER.": "Rope",
  "movimiento.tipo.D": "Digital",
  "movimiento.tipo.DI": "Digital",
  "movimiento.tipo.DIGI": "Digital",
  "movimiento.tipo.DUAL-TIM": "DUAL-TIME",
  "movimiento.tipo.F": "solar",
  "movimiento.tipo.HIBRID": "HIBRID",
  "movimiento.tipo.ME.CRO.": "MECA.CRONO",
  "movimiento.tipo.ME.SIM.": "SIMPLE MECA",
  "movimiento.tipo.MULTI": "Multifunction",
  "movimiento.tipo.MULTI.CR": "MULTI.CHRONO",
  "movimiento.tipo.PROF.": "DEPTH GAUGE",
  "movimiento.tipo.QUTZ-ANA": "QUARTZ / ANALOG",
  "movimiento.tipo.SIM": "Simple",
  "movimiento.tipo.SIM-SUI": "SIMPLE SWISS",
  "movimiento.tipo.SMART": "SMART WATCH",
  "movimiento.tipo.TOURBILLON": "TOURBILLON",
  "numeraciones.1A+AP": "1AR.AP + AR.PI",
  "numeraciones.1A+CC": "1AR.AP + CRIRC",
  "numeraciones.1A+IA": "1AR.AP + IN.AP",
  "numeraciones.1A+IP": "1AR.AP + IN.PI",
  "numeraciones.1A.AP": "1AR.AP",
  "numeraciones.1A.PI": "1AR.PI",
  "numeraciones.1AA": "1AR.AP",
  "numeraciones.1AP+I": "1AR.PI + IN.AP",
  "numeraciones.1AP+P": "1AR.PI + IN.PI",
  "numeraciones.1BR+I": "1BRILL + IN.AP",
  "numeraciones.1CC": "1CIRC",
  "numeraciones.1CC+I": "1CIRCO + IN.AP",
  "numeraciones.1CC+IA": "1CIRC + IN.AP",
  "numeraciones.1CIR+I": "1CIRCO + IN.AP.",
  "numeraciones.1D+IN": "1DIAM + IND APLI",
  "numeraciones.1I.AP": "1IN.AP",
  "numeraciones.1IA": "1IN.AP",
  "numeraciones.1IP": "1IN.PI",
  "numeraciones.1R+IA": "1RO.AP + IN.AP",
  "numeraciones.1R+IP": "1RO.AP + IN.PI",
  "numeraciones.1R+RP": "1RO.AP + RO.PI",
  "numeraciones.1R.AP": "1RO.AP",
  "numeraciones.1R.P": "1RO.PI",
  "numeraciones.1R.PI": "1RO.PI",
  "numeraciones.1RA": "1RO.AP.",
  "numeraciones.1RA+P": "1R.APL + RO.PIN.",
  "numeraciones.1RP+I": "1RO.PI + IN.AP",
  "numeraciones.1RP+P": "1RO.PI + IN.PI",
  "numeraciones.2A+AP": "2AR.AP + AR.PI",
  "numeraciones.2A+CC": "2AR.AP + CIRC",
  "numeraciones.2A+IA": "2AR.AP + IN.AP",
  "numeraciones.2A+IP": "2AR.AP + IN.PI",
  "numeraciones.2A.AP": "2AR.AP",
  "numeraciones.2A.PI": "2AR.PI",
  "numeraciones.2AA+2RA": "2AR.AP + 2RO.AP",
  "numeraciones.2AP+2RP": "2AR.PI + 2RO.PI",
  "numeraciones.2AP+ARP": "2AR.PI + 2RO.PI",
  "numeraciones.2AP+I": "2AR.PI + IN.AP",
  "numeraciones.2AP+P": "2AR.PI + IN.PI",
  "numeraciones.2CC": "2CIRC.",
  "numeraciones.2CC+I": "2CIRC + IN.AP",
  "numeraciones.2CC+IA": "2CIRC + IN.AP",
  "numeraciones.2I+RP": "2IN.AP + 10RO.PI",
  "numeraciones.2I.AP": "2IN.AP",
  "numeraciones.2IA": "2IN.AP",
  "numeraciones.2IA+P": "2IN.AP + IN.PI.",
  "numeraciones.2IP": "2IN.PI",
  "numeraciones.2R+CC": "2RO.AP + CIRC",
  "numeraciones.2R+IA": "2RO.AP + IN.AP",
  "numeraciones.2R+IP": "2RO.AP + IN.PI",
  "numeraciones.2R+RP": "2RO.AP + RO.PI",
  "numeraciones.2R.AP": "2RO.AP",
  "numeraciones.2R.P": "2RO.PI.",
  "numeraciones.2R.PI": "2RO.PI",
  "numeraciones.2RA": "2ROM.APL.",
  "numeraciones.2RP+C": "2RO.PI + CIRC",
  "numeraciones.2RP+I": "2RO.PI + IN.AP",
  "numeraciones.2RP+P": "2RO.PI + IN.PI",
  "numeraciones.3A+AP": "3AR.AP + AR.PI",
  "numeraciones.3A+IA": "3AR.AP + IN.AP",
  "numeraciones.3A+IP": "3AR.AP + IN.PI",
  "numeraciones.3A.AP": "3AR.AP",
  "numeraciones.3A.PI": "3AR.PI",
  "numeraciones.3AA+P": "3ARA.A + 8ARA.PIN.",
  "numeraciones.3AP+I": "3AR.PI + IN.AP.",
  "numeraciones.3AP+P": "3AR.PI + IN.PI",
  "numeraciones.3CC": "3CIRC.",
  "numeraciones.3CC+IA": "3CIRC + IN.AP",
  "numeraciones.3I+RP": "3IN.AP + RO.PI",
  "numeraciones.3I.AP": "3IN.AP",
  "numeraciones.3IA": "3IN.AP",
  "numeraciones.3IP": "3IN.PI",
  "numeraciones.3R+CC": "3ROM + ZIRCONS",
  "numeraciones.3R+IA": "3RO.AP + IN.AP",
  "numeraciones.3R+IP": "3RO.AP + IN.PI",
  "numeraciones.3R+IR": "3/4 ROMANS + IND.APL",
  "numeraciones.3R+RP": "3RO.AP + RO.PI",
  "numeraciones.3R.AP": "3RO.AP",
  "numeraciones.3R.P": "3RO.PI.",
  "numeraciones.3R.PI": "3RO.PI",
  "numeraciones.3RA+CC": "3RA.AP + CC",
  "numeraciones.3RA+P": "3RO.AP + 8RO.PI.",
  "numeraciones.3RE+E": "3ROM.EST + IND.ESTAMP.",
  "numeraciones.3RP+I": "3RO.PI + IN.AP",
  "numeraciones.3RP+P": "3RO.PI + IN.PI",
  "numeraciones.4A+4A": "4AR.PI + 4A.PI",
  "numeraciones.4A+4AP": "4AR.AP + 4AR.P",
  "numeraciones.4A+4R": "4AR + IA / 4R + IA.",
  "numeraciones.4A+AP": "4AR.AP + AR.PI",
  "numeraciones.4A+CC": "4ARA.AP + CIRC",
  "numeraciones.4A+IA": "4AR.AP + IN.AP",
  "numeraciones.4A+IP": "4AR.AP + IN.PI",
  "numeraciones.4A+P": "4ARP + IND.PIN.",
  "numeraciones.4A.AP": "4AR.AP",
  "numeraciones.4A.PI": "4AR.PI",
  "numeraciones.4AA+6": "4AR.A + 6AR.P",
  "numeraciones.4AA+P": "4ARA.A + 8ARA.PIN.",
  "numeraciones.4AP+I": "4AR.PI + IN.AP",
  "numeraciones.4AP+P": "4AR.PI + IN.PI",
  "numeraciones.4AR.P": "4ARA.PINT + IND.PIN.",
  "numeraciones.4CC": "4CIRC.",
  "numeraciones.4DIA": "4 DIAMONDS",
  "numeraciones.4I+CC": "4IN.AP + CIRC",
  "numeraciones.4IA": "4IN.AP",
  "numeraciones.4IA+P": "4IN.AP + IN.PI",
  "numeraciones.4IN.A": "4 APPLIED INDICES",
  "numeraciones.4INDS": "4 INDICES",
  "numeraciones.4IP": "4IN.PI",
  "numeraciones.4R+CC": "4 ROM. + CIRC.",
  "numeraciones.4R+DI": "/4ROM.APLIC.+8DIAM",
  "numeraciones.4R+IA": "4RO.AP + IN.AP",
  "numeraciones.4R+IP": "4RO.AP + IN.PI",
  "numeraciones.4R+RP": "4RO.AP + RO.PI",
  "numeraciones.4R.AP": "4RO.AP",
  "numeraciones.4R.P": "4RO.PI.",
  "numeraciones.4R.PI": "4RO.PI",
  "numeraciones.4RA+I": "4 / ROMAN.APL. + IND.APL",
  "numeraciones.4RA+P": "4RO.AP + 8RO.PI",
  "numeraciones.4RE+E": "4RO.EST + IN.EST.",
  "numeraciones.4ROM": "4 ROMANS",
  "numeraciones.4RP+I": "4RO.PI + IN.AP",
  "numeraciones.4RP+P": "4RO.PI + IN.PI",
  "numeraciones.5A+5CC": "5AR.AP + 5CIRC",
  "numeraciones.5A+AP": "5AR.AP + AR.PI",
  "numeraciones.5A+IA": "5AR.AP + IN.AP",
  "numeraciones.5A+IP": "5AR.AP + IN.PI",
  "numeraciones.5A.AP": "5AR.AP",
  "numeraciones.5A.PI": "5AR.PI",
  "numeraciones.5AP+I": "5AR.PI + IN.AP",
  "numeraciones.5AP+P": "5AR.PI + IN.PI",
  "numeraciones.5CC": "5CIRC.",
  "numeraciones.5D+IA": "5 DIAM + IND. APLI",
  "numeraciones.5DIAM": "5 DIAMONDS",
  "numeraciones.5IA": "5IN.AP",
  "numeraciones.5IP": "5IN.PI",
  "numeraciones.5R+IA": "5RO.AP + IN.AP",
  "numeraciones.5R+IP": "5RO.AP + IN.PI",
  "numeraciones.5R+RP": "5RO.AP + RO.PI",
  "numeraciones.5R.AP": "5RO.AP",
  "numeraciones.5R.PI": "5RO.PI",
  "numeraciones.5RP+I": "5RO.PI + IN.AP",
  "numeraciones.5RP+P": "5RO.PI + IN.PI",
  "numeraciones.6A+AP": "6AR.AP + AR.PI",
  "numeraciones.6A+CC": "6AR.AP + CIRC.",
  "numeraciones.6A+IA": "6AR.AP + IN.AP",
  "numeraciones.6A+IP": "6AR.AP + IN.PI",
  "numeraciones.6A.AP": "6AR.AP",
  "numeraciones.6A.PI": "6AR.PI",
  "numeraciones.6AP+I": "6AR.PI + IN.AP",
  "numeraciones.6AP+P": "6AR.PI + IN.PI.",
  "numeraciones.6CC.": "6CIRC.",
  "numeraciones.6IA": "6IN.AP",
  "numeraciones.6IP": "6IN.PI",
  "numeraciones.6R+IA": "6RO.AP + IN.AP",
  "numeraciones.6R+IP": "6RO.AP + IN.PI",
  "numeraciones.6R+RP": "6RO.AP + RO.PI",
  "numeraciones.6R.AP": "6RO.AP",
  "numeraciones.6R.P": "6RO.PIN.",
  "numeraciones.6RP+I": "6RO.PI + IN.AP",
  "numeraciones.6RP+P": "6RO.PI + IN.PI",
  "numeraciones.7A+IA": "7AR.AP + IN.AP",
  "numeraciones.7AR.P": "7AR.P",
  "numeraciones.7DIA": "7 DIAMONDS",
  "numeraciones.8A+IA": "8AR.AP + IN.AP",
  "numeraciones.8AP": "8ARA.PIN.",
  "numeraciones.8AP+P": "8ARA.PIN + IND.PIN.",
  "numeraciones.8R+IA": "8RO.AP + IN.AP",
  "numeraciones.8RP+P": "8RO.PI + IN.PI.",
  "numeraciones.9ARAB": "9 ARABS",
  "numeraciones.10ARA": "10 ARABES",
  "numeraciones.A+R.P": "ARA + ROM.PIN.",
  "numeraciones.AA+AP": "ARA PINT + ARA APLIC",
  "numeraciones.AP+AA": "ARA PINT + ARA APLIC",
  "numeraciones.AP+IA": "ARA.PIN + IND.A",
  "numeraciones.AP+IP": "AR.PI + IN.PI",
  "numeraciones.AR+DI": "RA. + 10 DIAMONDS",
  "numeraciones.AR.AP": "APPLIED ARABS",
  "numeraciones.AR.PI": "PAINTED ARABS",
  "numeraciones.AR4/4": "ARABES 4/4 + INDICES",
  "numeraciones.ARA.A": "APPLIED ARABS",
  "numeraciones.ARA.E": "PRINTED ARABES",
  "numeraciones.ARA.P": "PAINTED ARABS",
  "numeraciones.ARAB": "ARABES",
  "numeraciones.ARABE": "ARABES 12/12",
  "numeraciones.BAND": "Flags",
  "numeraciones.BOLAS": "2N18 BALLS",
  "numeraciones.CIRC": "Zirconia",
  "numeraciones.CR": "SWAROVSKI CRYSTAL",
  "numeraciones.DIAM": "12 DIAMONDS",
  "numeraciones.DIAMG": "BIG DIAMONDS",
  "numeraciones.DIAN": "DIGITAL ANALOG",
  "numeraciones.DIAR": "DIGITAL ARABES",
  "numeraciones.DIG": "Digital",
  "numeraciones.DIIN": "DIGITAL INDICES",
  "numeraciones.FANT": "Fantasy",
  "numeraciones.FL": "Flowers",
  "numeraciones.IN+CC": "IND + ZIRCONS",
  "numeraciones.IN.AP": "APPLIED INDICES",
  "numeraciones.IN.PI": "PAINTED INDEXES",
  "numeraciones.INAR": "12INDIC + 12ARAB",
  "numeraciones.IND.A": "APPLIED INDICES",
  "numeraciones.IND.E": "IND.EST.",
  "numeraciones.IND.P": "PAINTED INDUSTRIES",
  "numeraciones.INDIC": "Indices",
  "numeraciones.INFAN": "FANTASY INDICES",
  "numeraciones.INPIN": "IND.PIN.",
  "numeraciones.INRO": "12 INDICES + 12 ROMAN",
  "numeraciones.IP+AP": "12IN.PI + 12AR.PI",
  "numeraciones.NEO": "DOES NOT CARRY",
  "numeraciones.RA+RP": "4ROM APLI + 4ROM PIN",
  "numeraciones.RO+IN": "ROMANS 12 + 10 INDEX",
  "numeraciones.RO.AP": "APPLIED ROMANS",
  "numeraciones.RO.PI": "PAINTED ROMANS",
  "numeraciones.RO2/2": "ROMANS 2/2 + INDEX",
  "numeraciones.RO4/4": "ROMANS 4/4 + INDICES",
  "numeraciones.ROES": "ROM.STAMPINGS",
  "numeraciones.ROM": "ROMANS",
  "numeraciones.ROM.A": "ROMANS APPLIQUES",
  "numeraciones.ROM.E": "ROMANS STAMPED",
  "numeraciones.ROM.P": "PAINTED ROMANS",
  "numeraciones.ROMAN": "12/12 ROMANS",
  "numeraciones.RP+IA": "12ROM.PIN + 12IND.A.",
  "numeraciones.RP+IP": "12RO.PI + 12IN.PI",
  "origen.1": "Switzerland",
  "origen.2": "Japan",
  "piedras.tipo.agrupada.ABA": "BEADS DIFFERENT FORMS",
  "piedras.tipo.agrupada.ACBA": "STEEL BALL",
  "piedras.tipo.agrupada.AGA": "AGATE",
  "piedras.tipo.agrupada.AGBA": "SILVER BALL",
  "piedras.tipo.agrupada.AMA": "Amethyst",
  "piedras.tipo.agrupada.AMAN": "AMANZONITE",
  "piedras.tipo.agrupada.ARCI": "SILVER RING WITH ZIRCONS",
  "piedras.tipo.agrupada.ARGO": "Ring",
  "piedras.tipo.agrupada.AVEN": "ADVENTURINE",
  "piedras.tipo.agrupada.BAEN": "BAR SET ZIRCONIA",
  "piedras.tipo.agrupada.BAPA": "PAVE BAR",
  "piedras.tipo.agrupada.BGSP": "BAGUES WITHOUT PRICE",
  "piedras.tipo.agrupada.BOL": "SILVER BALL FACETED",
  "piedras.tipo.agrupada.BRI": "SPARKLY",
  "piedras.tipo.agrupada.BS": "BS",
  "piedras.tipo.agrupada.BUTT": "CULTIVATED PEARL BUTTON",
  "piedras.tipo.agrupada.CALC": "CHALCEDONY",
  "piedras.tipo.agrupada.CER": "Ceramics",
  "piedras.tipo.agrupada.CIR": "YELLOW ZIRCONIA (CZ-YE1)",
  "piedras.tipo.agrupada.CIRC": "SET ZIRCONIA",
  "piedras.tipo.agrupada.COR": "CORNELIAN",
  "piedras.tipo.agrupada.CRBA": "Crystal Ball",
  "piedras.tipo.agrupada.CRI": "Crystal",
  "piedras.tipo.agrupada.CRPA": "CRUZ PAVE",
  "piedras.tipo.agrupada.CUAR": "quartz",
  "piedras.tipo.agrupada.DIAM": "Diamond",
  "piedras.tipo.agrupada.DISP": "0",
  "piedras.tipo.agrupada.ESMA": "ENAMEL",
  "piedras.tipo.agrupada.ESP": "Spinel",
  "piedras.tipo.agrupada.FERI": "Holiday",
  "piedras.tipo.agrupada.G030": "FO1030",
  "piedras.tipo.agrupada.G031": "FO1031",
  "piedras.tipo.agrupada.G034": "BRILLIANT",
  "piedras.tipo.agrupada.G036": "FO1036",
  "piedras.tipo.agrupada.G050": "FO1050",
  "piedras.tipo.agrupada.GI10": "STONE 0.90",
  "piedras.tipo.agrupada.GI11": "38640",
  "piedras.tipo.agrupada.GI12": "MOD FO282",
  "piedras.tipo.agrupada.GI13": "SHOCKWAVE CRIMPED BEZEL",
  "piedras.tipo.agrupada.GI14": "STONES 1,6DIAM",
  "piedras.tipo.agrupada.GI15": "STONE 0.80",
  "piedras.tipo.agrupada.GIL": "DIAM ENGAS MIGUEL GIL 0.015K",
  "piedras.tipo.agrupada.GIL1": "STONES 1.20 + 1.50",
  "piedras.tipo.agrupada.GIL2": "FO436",
  "piedras.tipo.agrupada.GIL3": "DIAMOND 1.10",
  "piedras.tipo.agrupada.GIL4": "STONE MODEL LO314",
  "piedras.tipo.agrupada.GIL5": "STONES MODEL FO323",
  "piedras.tipo.agrupada.GIL6": "STONES OF 1.20",
  "piedras.tipo.agrupada.GIL7": "STONES 1.40",
  "piedras.tipo.agrupada.GIL8": "STONES 1.30",
  "piedras.tipo.agrupada.GIL9": "STONE 1.00",
  "piedras.tipo.agrupada.GLAM": "GLAM",
  "piedras.tipo.agrupada.HEMA": "HEMATITE",
  "piedras.tipo.agrupada.JADE": "Jade",
  "piedras.tipo.agrupada.LABR": "LABRADORITE",
  "piedras.tipo.agrupada.LAPI": "LAPISLAZULI",
  "piedras.tipo.agrupada.LAVA": "LAVA BALL",
  "piedras.tipo.agrupada.MP": "MOTHER PEARL",
  "piedras.tipo.agrupada.NAC": "NACRE",
  "piedras.tipo.agrupada.OJGA": "CAT EYE",
  "piedras.tipo.agrupada.ONI": "onyx",
  "piedras.tipo.agrupada.OPPE": "PERUVIAN OPAL",
  "piedras.tipo.agrupada.OTIG": "TIGER'S EYE (GARNET)",
  "piedras.tipo.agrupada.OTUR": "TURKISH EYE",
  "piedras.tipo.agrupada.PABA": "PAVE BALL",
  "piedras.tipo.agrupada.PEBA": "CULTIVATED BAROQUE PEARL",
  "piedras.tipo.agrupada.PECU": "CULTURED PEARL",
  "piedras.tipo.agrupada.PERL": "NATURAL PEARL",
  "piedras.tipo.agrupada.PESY": "SYNTHETIC PEARL",
  "piedras.tipo.agrupada.PILU": "MOON STONE",
  "piedras.tipo.agrupada.QURS": "PINK QUARTZ",
  "piedras.tipo.agrupada.RES": "RESIN BALL",
  "piedras.tipo.agrupada.RESI": "Resin",
  "piedras.tipo.agrupada.RORU": "RHODIUM RUTENIUM",
  "piedras.tipo.agrupada.RUB": "Ruby",
  "piedras.tipo.agrupada.SWA": "SWAROVSKI",
  "piedras.tipo.agrupada.SZA1": "STONE 1.50",
  "piedras.tipo.agrupada.SZA2": "STONE 1.20",
  "piedras.tipo.agrupada.TUCI": "ZIRCONIA TUBE",
  "piedras.tipo.agrupada.TUR": "turquoise",
  "producto.coleccion": "Collection",
  "producto.tipo.accesorio": "accessory",
  "producto.tipo.joya": "Jewel",
  "producto.tipo.reloj": "watch",
  "tipo.movimiento.agrupado.01": "Digital",
  "tipo.movimiento.agrupado.02": "DIGITAL QUARTZ",
  "tipo.movimiento.agrupado.03": "Manual",
  "tipo.movimiento.agrupado.04": "Automatics",
  "tipo.movimiento.agrupado.05": "hybrid",
  "tipo.movimiento.agrupado.06": "SMARTWATCH",
  "tipo.movimiento.agrupado.07": "QUARTZ",
  "tipolente.POLARIZED": "Polarised"
}
