{
  "accesorio": {
    "h1": "Accessori",
    "meta": "Trova il regalo perfetto per ogni occasione o regalati e sfoggia i migliori accessori. Visitate subito il nostro catalogo. {{numPagina}}",
    "title": "Accessoires | Kronaby"
  },
  "accesorio.default": {
    "h1": "Accessori {{tipoAccesorio}} {{subtipoAccesorio}} {{tipoBoligrafo}}",
    "meta": "Trova il regalo perfetto per ogni occasione o regalati e sfoggia i migliori accessori. Visitate subito il nostro catalogo. {{numPagina}}",
    "robots": "false",
    "title": "Accessori {{tipoAccesorio}} {{subtipoAccesorio}} {{tipoBoligrafo}} | Kronaby"
  },
  "accesorio.LEATHER_ITEM.CARD_HOLDER": {
    "h1": "Portacarte in pelle",
    "meta": "Il modo migliore per tenere le tue carte organizzate è con i nostri portacarte in pelle fatti del materiale più resistente ed elegante.",
    "title": "Portacarte in pelle : Kronaby"
  },
  "accesorio.LEATHER_ITEM.WALLET": {
    "h1": "Portafogli in pelle",
    "meta": "I nostri portafogli in pelle sono perfetti per i regali. Realizzati in materiale resistente e disponibili in una varietà di colori, sono ideali da portare ovunque tu vada.",
    "title": "Portafogli in pelle : Kronaby"
  },
  "accesorio.subtipoAccesorio.default": {
    "h1": "{{subtipoAccesorio}}",
    "meta": "Scopri la nostra selezione di {{subtipoAccesorio}} nel nostro negozio online ufficiale.",
    "title": "{{subtipoAccesorio}} | Kronaby"
  },
  "accesorio.subtipoAccesorio.SOLAR": {
    "h1": "Occhiali da sole",
    "meta": "Scopri la nostra selezione di occhiali da sole nel nostro negozio online ufficiale.",
    "title": "Occhiali da sole | Kronaby"
  },
  "accesorio.tipoAccesorio.default": {
    "h1": "Accessori {{tipoAccesorio}}",
    "meta": "Dalla nostra selezione di accessori Kronaby, trovate il regalo ideale per le occasioni speciali o per regalarvi.",
    "title": "Accessori {{tipoAccesorio}} | Kronaby"
  },
  "accesorio.tipoAccesorio.KEYCHAIN": {
    "h1": "PORTACHIAVI",
    "meta": "I portachiavi Kronaby sono un accessorio indispensabile per completare il tuo stile. Sono eleganti e moderni e danno un tocco distintivo a chi li indossa.",
    "title": "Portachiavi : Kronaby"
  },
  "accesorio.WRITING.BALLPOINT_PEN": {
    "h1": "Penne a sfera",
    "meta": "Le nostre penne a sfera sono diventate le penne più facili e comode per scrivere. Hanno anche un design sottile ed esclusivo.",
    "title": "Penne a sfera : Kronaby"
  },
  "accesorio.WRITING.FOUNTAIN_PEN": {
    "h1": "Penne stilografiche",
    "meta": "Dai un tocco personale alla tua calligrafia con le nostre penne stilografiche. Nel nostro catalogo potete trovarli in diversi colori e disegni.",
    "title": "Penne stilografiche Kronaby"
  },
  "accesorio.WRITING.REPLACEMENT": {
    "h1": "Ricariche per penne a sfera",
    "meta": "Nel nostro negozio online puoi anche comprare i refill delle penne, e comprarli in confezioni da due per averli sempre a portata di mano.",
    "title": "Ricariche per penne a sfera | Kronaby"
  },
  "accesorio.WRITING.ROLLERBALL_PEN": {
    "h1": "Penne a sfera",
    "meta": "Scopri le nostre penne roller, penne di lusso dal design elegante e discreto, perfette come regalo per i momenti speciali.",
    "title": "Penne roller : Kronaby"
  },
  "reloj": {
    "h1": "Orologi",
    "meta": "Cerchi un orologio unico ed elegante? Scopri i nostri orologi delle migliori marche e trova il modello perfetto per te. {{numPagina}}",
    "title": "Orologi {{numPagina}} | Kronaby"
  },
  "reloj.C": {
    "h1": "Orologi da uomo",
    "meta": "I nostri orologi da uomo sono perfetti per ogni occasione. Dal design unico, sono ideali per completare uno stile elegante e unico. {{numPagina}}",
    "title": "Orologi da uomo {{numPagina}} | Kronaby"
  },
  "reloj.colorEsfera.default": {
    "h1": "Orologi con quadranti {{ colorEsfera }}",
    "meta": "Siete alla ricerca di un orologio unico ed elegante? Scoprite i nostri orologi con quadranti {{ colorEsfera }} delle migliori marche e trovate il modello perfetto per voi.",
    "title": "Orologi con quadranti {{ colorEsfera }} | Kronaby"
  },
  "reloj.default": {
    "h1": "Orologi {{marca}} {{funcion}} {{estilos}} {{colorCorrea}} {{colorEsfera}} {{materialCaja}} {{materialCorrea}}",
    "meta": "Cerchi un orologio unico ed elegante? Scopri i nostri orologi delle migliori marche e trova il modello perfetto per te. {{numPagina}}",
    "robots": "false",
    "title": "Orologi {{gama}} {{marca}} {{funcion}} {{estilos}} {{colorCorrea}} {{colorEsfera}} {{materialCaja}} {{materialCorrea}} {{numPagina}} | Kronaby"
  },
  "reloj.funcion.default": {
    "h1": "Orologi {{funcion}}",
    "meta": "Cerchi un orologio {{funcion}}? Trova i migliori modelli delle migliori marche e scegli l'orologio perfetto per te. Visita subito il nostro catalogo {{numPagina}}",
    "title": "Orologi {{funcion}} {{numPagina}} | Kronaby"
  },
  "reloj.gama.colorCorrea.default": {
    "h1": "Orologi {{colorCorrea}} per {{gama}}",
    "meta": "Abbiamo una vasta selezione di colori e materiali in modo che tu possa scegliere gli orologi {{colorCorrea}} per {{gama}} che meglio si adattano al tuo stile. {{numPagina}}",
    "title": "Orologi {{colorCorrea}} per {{gama}} {{numPagina}} | Kronaby"
  },
  "reloj.gama.colorEsfera.default": {
    "h1": "Orologi con sfera {{colorEsfera}} per {{gama}}",
    "meta": "Distinto, unico e sofisticato. Questi sono i nostri Orologi con quadrante {{colorEsfera}} per {{gama}} che aggiungeranno un tocco di eleganza al tuo polso.",
    "title": "Orologi con sfera {{colorEsfera}} per {{gama}} {{numPagina}} | Kronaby"
  },
  "reloj.gama.funcion.default": {
    "h1": "Orologi {{funcion}} per {{gama}}",
    "meta": "Trova i più sorprendenti e sofisticati orologi {{funcion}} per {{gama}} di Kronaby sul mercato e indossali per ogni occasione. {{numPagina}}",
    "title": "Orologi {{funcion}} per {{gama}} {{numPagina}} | Kronaby"
  },
  "reloj.gama.materialCaja.default": {
    "h1": "{{gama}} orologi con cassa {{materialCaja}}",
    "meta": "Gli orologi con cassa da {{materialCaja}} a {{gama}} si distinguono per il loro design accurato. Entrate nel nostro negozio per scegliere quello più adatto a voi.",
    "title": "{{gama}} orologi con cassa {{materialCaja}} {{numPagina}} | Kronaby"
  },
  "reloj.gama.materialCorrea.default": {
    "h1": "Orologi da {{materialCorrea}} a {{gama}}",
    "meta": "Gli orologi Kronaby da {{materialCorrea}} a {{gama}} diventano la scelta perfetta per portare al polso un orologio esclusivo e pratico.",
    "title": "Orologi da {{materialCorrea}} a {{gama}} | Kronaby"
  },
  "reloj.gama.tipoCierre.default": {
    "h1": "Orologi {{tipoCierre}} per {{gama}}",
    "meta": "Sul nostro sito web potrai vedere l'ampia selezione di Orologi {{tipoCierre}} per {{gama}} che offriamo. Abbiamo pezzi esclusivi solo per te.",
    "title": "Orologi {{tipoCierre}} per {{gama}} | Kronaby"
  },
  "reloj.gama.tipoCorrea.ARMYS": {
    "h1": "Orologi {{tipoCorrea}} per {{gama}}",
    "meta": "Sapevi che da Kronaby offriamo orologi {{tipoCorrea}} per {{gama}} per tutti i tipi di occasioni? Trova quello che meglio si adatta alla tua personalità!",
    "title": "Orologi {{tipoCorrea}} per {{gama}} | Kronaby"
  },
  "reloj.gama.tipoCorrea.CORREA": {
    "h1": "Orologi {{tipoCorrea}} per {{gama}}",
    "meta": "Sapevi che da Kronaby offriamo orologi {{tipoCorrea}} per {{gama}} per tutti i tipi di occasioni? Trova quello che meglio si adatta alla tua personalità!",
    "title": "Orologi {{tipoCorrea}} per {{gama}} | Kronaby"
  },
  "reloj.gama.tipoEstanqueidad.default": {
    "h1": "Orologi da {{tipoEstanqueidad}} a {{gama}}",
    "meta": "Consulta il nostro ampio catalogo di orologi da {{tipoEstanqueidad}} a {{gama}} realizzati con i migliori materiali e tecniche del mercato.",
    "title": "Orologi da {{tipoEstanqueidad}} a {{gama}} | Kronaby"
  },
  "reloj.gama.tiposMovimiento.01": {
    "h1": "Orologi digitali per {{gama}}",
    "meta": "Stai cercando un orologio digitale per {{gama}}? Da Kronaby abbiamo una vasta selezione di orologi disponibili. Entra e scegli quello che meglio si adatta a te.",
    "title": "Orologi digitali per {{gama}} | Kronaby"
  },
  "reloj.gama.tiposMovimiento.02": {
    "h1": "Orologi per {{gama}} con movimento Quarzo Digitale",
    "meta": "Stai cercando Orologi da {{gama}} con movimento Quarzo Digitale? Dai un'occhiata alla nostra vasta gamma di orologi per {{gama}} e scegli il modello perfetto per te.",
    "robots": "false",
    "title": "Orologi per {{gama}} con movimento Quarzo Digitale | Kronaby"
  },
  "reloj.gama.tiposMovimiento.04": {
    "h1": "Orologi automatici per {{gama}}",
    "meta": "Stai cercando un orologio automatico per {{gama}}? Da Kronaby abbiamo una vasta selezione di orologi disponibili. Entra e scegli quello che meglio si adatta a te.",
    "title": "Orologi automatici per {{gama}} | Kronaby"
  },
  "reloj.gama.tiposMovimiento.05": {
    "h1": "Orologi ibridi per{{gama}}",
    "meta": "Stai cercando un orologio ibrido per {{gama}}? Da Kronaby abbiamo una vasta selezione di orologi disponibili. Entra e scegli quello che meglio si adatta a te.",
    "title": "Orologi ibridi per{{gama}} | Kronaby"
  },
  "reloj.gama.tiposMovimiento.06": {
    "h1": "Smartwatches per {{gama}}",
    "meta": "Stai cercando uno smartwatch per {{gama}}? Da Kronaby abbiamo una vasta selezione di orologi intelligenti disponibili. Entra e scegli quello che meglio si adatta a te.",
    "title": "Smartwatches per {{gama}} - Orologi intelligenti | Kronaby"
  },
  "reloj.gama.tiposMovimiento.07": {
    "h1": "Orologi al quarzo per {{gama}}",
    "meta": "Stai cercando un orologio al quarzo per {{gama}}? Da Kronaby abbiamo una vasta selezione di orologi disponibili. Entra e scegli quello che meglio si adatta a te.",
    "title": "Orologi al quarzo per {{gama}} | Kronaby"
  },
  "reloj.IN": {
    "h1": "Orologi per bambini",
    "meta": "Stai cercando i migliori orologi per bambini? Nel nostro negozio online troverete orologi divertenti e variegati per i membri più giovani della famiglia. {{numPagina}}",
    "title": "Orologi per bambini {{numPagina}} | Kronaby"
  },
  "reloj.IN.funcion": {
    "h1": "Orologi {{funcion}} per bambini",
    "meta": "Vuoi un {{funcion}} orologio per un bambino? Trova una varietà di orologi per bambini {{funcion}} delle migliori marche. Visitate il nostro catalogo! {{numPagina}}",
    "title": "Orologi {{funcion}} per bambini {{numPagina}} | Kronaby"
  },
  "reloj.materialCaja.default": {
    "h1": "Orologi con custodia {{materialCaja}}",
    "meta": "Ti piacciono gli {{materialCaja}}orologi? Scopri il nostro ampio catalogo e trova l'orologio che fa brillare il tuo polso. {{numPagina}}",
    "title": "Orologi con custodia {{materialCaja}} {{numPagina}} | Kronaby"
  },
  "reloj.materialCorrea.default": {
    "h1": "Orologi {{materialCorrea}}",
    "meta": "Ti piacciono gli orologi con cinturino {{materialCorrea}}? Scopri il nostro ampio catalogo e trova l'orologio che fa brillare il tuo polso. {{numPagina}}",
    "title": "Orologi {{materialCorrea}} {{numPagina}} | Kronaby"
  },
  "reloj.S": {
    "h1": "Orologi da donna",
    "meta": "Nel nostro catalogo di orologi da donna, trova quello che meglio si adatta al tuo stile e alla tua personalità e indossalo comodamente nella tua vita quotidiana. {{numPagina}}",
    "title": "Orologi da donna {{numPagina}} | Kronaby"
  },
  "reloj.tipoCierre.default": {
    "h1": "Orologi {{tipoCierre}}",
    "meta": "Cerchi un orologio {{tipoCierre}}? Trova i migliori modelli delle migliori marche e scegli l'orologio perfetto per te. Visita subito il nostro catalogo {{numPagina}}",
    "title": "Orologi {{tipoCierre}} {{numPagina}} | Kronaby"
  },
  "reloj.tipoCorrea.default": {
    "h1": "Orologi {{tipoCorrea}}",
    "meta": "Cerchi un orologio {{tipoCorrea}}? Trova i migliori modelli delle migliori marche e scegli l'orologio perfetto per te. Visita subito il nostro catalogo {{numPagina}}",
    "title": "Orologi {{tipoCorrea}} {{numPagina}} | Kronaby"
  },
  "reloj.tiposMovimiento.01": {
    "h1": "Orologi digitali",
    "meta": "Stai cercando un orologio digitale? In Kronaby abbiamo una vasta selezione di orologi digitali disponibili. Entra e scegli quello che meglio si adatta a te.",
    "robots": "false",
    "title": "Orologi digitali | Kronaby"
  },
  "reloj.tiposMovimiento.02": {
    "h1": "Orologi con movimento Quarzo Digitale",
    "meta": "Stai cercando orologi con movimento Quarzo Digitale? Dai un'occhiata alla nostra vasta gamma di orologi per {Genero} e scegli il modello perfetto per te.",
    "robots": "false",
    "title": "Orologi con movimento Quarzo Digitale | Kronaby"
  },
  "reloj.tiposMovimiento.03": {
    "h1": "Orologi con movimento Manuale",
    "meta": "Stai cercando orologi con movimento Manuale? Dai un'occhiata alla nostra vasta gamma di orologi per Kronaby e scegli il modello perfetto per te.",
    "robots": "false",
    "title": "Orologi con movimento Manuale | Kronaby"
  },
  "reloj.tiposMovimiento.04": {
    "h1": "Orologi automatici",
    "meta": "Stai cercando un orologio automatico? In Kronaby abbiamo una vasta selezione di orologi automatici disponibili. Entra e scegli quello che meglio si adatta a te.",
    "title": "Orologi automatici | Kronaby"
  },
  "reloj.tiposMovimiento.05": {
    "h1": "Orologi ibridi",
    "meta": "Stai cercando un orologio automatico? In Kronaby abbiamo una vasta selezione di orologi automatici disponibili. Entra e scegli quello che meglio si adatta a te.",
    "robots": "false",
    "title": "Orologi ibridi | Kronaby"
  },
  "reloj.tiposMovimiento.06": {
    "h1": "Smartwatches",
    "meta": "Stai cercando uno smartwatch? Da Kronaby abbiamo una vasta selezione di orologi intelligenti disponibili. Entra e scegli quello che si adatta meglio al tuo stile.",
    "title": "Smartwatches - Orologi intelligenti | Kronaby"
  },
  "reloj.tiposMovimiento.07": {
    "h1": "Orologi al quarzo",
    "meta": "Stai cercando un orologio al quarzo? In Kronaby abbiamo una vasta selezione di orologi al quarzo disponibili. Entra e scegli quello che meglio si adatta a te.",
    "robots": "false",
    "title": "Orologi al quarzo | Kronaby"
  }
}
