{
  "accesorio": {
    "h1": "Accessories",
    "meta": "Find the perfect gift for any occasion or treat yourself and show off the best accessories. Visit our catalog now. {{numPagina}}",
    "title": "Accessories | Kronaby"
  },
  "accesorio.default": {
    "h1": "Accessories {{tipoAccesorio}} {{subtipoAccesorio}} {{tipoBoligrafo}}",
    "meta": "Find the perfect gift for any occasion or treat yourself and show off the best accessories. Visit our catalog now. {{numPagina}}",
    "robots": "false",
    "title": "Accessories {{tipoAccesorio}} {{subtipoAccesorio}} {{tipoBoligrafo}} | Kronaby"
  },
  "accesorio.LEATHER_ITEM.CARD_HOLDER": {
    "h1": "Leather Card Holders",
    "meta": "The best option to carry your organized cards is to have our Leather Card Holders made of the most resistant and elegant material.",
    "title": "Leather Card Holders | Kronaby"
  },
  "accesorio.LEATHER_ITEM.WALLET": {
    "h1": "Leather Wallets",
    "meta": "Our leather wallets are perfect for gifts. Made with a resistant material and in various colors, they are ideal to take wherever you want.",
    "title": "Leather Wallets | Kronaby"
  },
  "accesorio.subtipoAccesorio.default": {
    "h1": "{{subtipoAccesorio}}",
    "meta": "Discover our selection of {{subtipoAccesorio}} in our official online shop.",
    "title": "{{subtipoAccesorio}} | Kronaby"
  },
  "accesorio.subtipoAccesorio.SOLAR": {
    "h1": "Sunglasses",
    "meta": "Discover our selection of Sunglasses in our official online shop.",
    "title": "Sunglasses | Kronaby"
  },
  "accesorio.tipoAccesorio.default": {
    "h1": "Accessories {{tipoAccesorio}} ",
    "meta": "From our selection of Kronaby Accessories, find the ideal gift for special occasions or to treat yourself.",
    "title": "Accessories {{tipoAccesorio}} | Kronaby"
  },
  "accesorio.tipoAccesorio.KEYCHAIN": {
    "h1": "Keychains",
    "meta": "Kronaby keychains are an essential accessory to complement your style. They are elegant and modern and give a distinctive touch to whoever wears them.",
    "title": "Keychains | Kronaby"
  },
  "accesorio.WRITING.BALLPOINT_PEN": {
    "h1": "Ballpoint Pens",
    "meta": "Our ballpoint pens have become the most comfortable and easy pens to write on. In addition, they present a subtle and exclusive design.",
    "title": "Ballpoint Pens | Kronaby"
  },
  "accesorio.WRITING.FOUNTAIN_PEN": {
    "h1": "Fountain Pens",
    "meta": "Give your calligraphy a personal touch with our Fountain Pens. In our catalog you can find them in different colors and designs.",
    "title": "Fountain Pens | Kronaby"
  },
  "accesorio.WRITING.REPLACEMENT": {
    "h1": "Pen Refills",
    "meta": "In our online store you can also buy pen refills and other spare parts, and buy them in packs of two units to always have them at hand.",
    "title": "Pen Refills | Kronaby"
  },
  "accesorio.WRITING.ROLLERBALL_PEN": {
    "h1": "Rollerball Pens",
    "meta": "Meet our rollerball pens, luxury pens with an elegant and discreet design, perfect for giving gifts in special moments.",
    "title": "Rollerball Pens | Kronaby"
  },
  "reloj": {
    "h1": "Kronaby Watches",
    "meta": "Discover all Kronaby watches at our official website. Kronaby brand is making watches for men and women since 1902 in more than 90 countries.",
    "title": "Kronaby | Watches for Men and Women | Official Online Store"
  },
  "reloj.C": {
    "h1": "Watches for Men",
    "meta": "Check our catalog and discover all our Kronaby men watches. Get Watches for men that best suit your style . {{numPagina}}",
    "title": "Watches for Men {{numPagina}} | Kronaby"
  },
  "reloj.colorEsfera.default": {
    "h1": "{{ colorEsfera }} Dial Watches",
    "meta": "Looking for a unique and elegant watch? Discover our {{ colorEsfera }} dial watches from the best brands and find the perfect model for you.",
    "title": "{{ colorEsfera }} Dial Watches | Kronaby"
  },
  "reloj.default": {
    "h1": "Watches {{marca}} {{funcion}} {{estilos}} {{colorCorrea}} {{colorEsfera}} {{materialCaja}} {{materialCorrea}}",
    "meta": "Looking for a unique and elegant watch? Discover our watches from the best brands and find the perfect model for you. {{numPagina}}",
    "robots": "false",
    "title": "Watches {{gama}} {{marca}} {{funcion}} {{estilos}} {{colorCorrea}} {{colorEsfera}} {{materialCaja}} {{materialCorrea}} {{numPagina}} | Kronaby"
  },
  "reloj.funcion.default": {
    "h1": "Watches {{funcion}}",
    "meta": "Looking for a Watche {{funcion}}? Find the best models of the best brands and choose the perfect watch for you. Visit our catalog now {{numPagina}}",
    "title": "Watches {{funcion}} {{numPagina}} | Kronaby"
  },
  "reloj.gama.colorCorrea.default": {
    "h1": "{{colorCorrea}} Watches for {{gama}}",
    "meta": "We have an extensive selection of colors and materials for you to choose the {{colorCorrea}} Watches for {{gama}} that best suit your style. {{numPagina}}",
    "title": "{{colorCorrea}} Watches for {{gama}} {{numPagina}} | Kronaby"
  },
  "reloj.gama.colorEsfera.default": {
    "h1": "{{colorEsfera}} dial Watches for {{gama}}",
    "meta": "Distinguished, unique and sophisticated. This is how our {{colorEsfera}} Watches for {{gama}} are like, which will add a touch of elegance to you.",
    "title": "{{colorEsfera}} dial Watches for {{gama}} {{numPagina}} | Kronaby"
  },
  "reloj.gama.funcion.default": {
    "h1": "Watches for {{gama}} {{funcion}}",
    "meta": "Find the most incredible and sophisticated {{funcion}} Watches for {{gama}} on the market and wear them on any occasion. {{numPagina}}",
    "title": "Watches for {{gama}} {{funcion}} {{numPagina}} | Kronaby"
  },
  "reloj.gama.materialCaja.default": {
    "h1": "{{gama}} watches with {{materialCaja}}casing",
    "meta": "The watches with {{materialCaja}} to {{gama}} cases stand out for their careful design. Enter our shop to choose the one that best suits you.",
    "title": "{{gama}} watches with {{materialCaja}} {{numPagina}} casing | Kronaby"
  },
  "reloj.gama.materialCorrea.default": {
    "h1": "{{materialCorrea}} Watches for {{gama}}",
    "meta": "The Kronaby {{materialCorrea}} Watches for {{gama}} become the perfect option to wear an exclusive and manageable watch on your wrist.",
    "title": "Watches from {{materialCorrea}} to {{gama}} | Kronaby"
  },
  "reloj.gama.tipoCierre.default": {
    "h1": "Watches with {{tipoCierre}} for {{gama}}",
    "meta": "On our website you will see the wide selection of watches with {{tipoCierre}} for {{gama}} that we offer. We have exclusive pieces just for you.",
    "title": "Watches with {{tipoCierre}} for {{gama}} | Kronaby"
  },
  "reloj.gama.tipoCorrea.ARMYS": {
    "h1": "{{tipoCorrea}} Watches for {{gama}}",
    "meta": "Did you know that at Kronaby we offer {{tipoCorrea}} Watches for {{gama}} for all kinds of occasions? Find the one that best suits your personality!",
    "title": "{{tipoCorrea}} Watches for {{gama}} | Kronaby"
  },
  "reloj.gama.tipoCorrea.CORREA": {
    "h1": "{{tipoCorrea}} Watches for {{gama}}",
    "meta": "Did you know that at Kronaby we offer {{tipoCorrea}} Watches for {{gama}} for all kinds of occasions? Find the one that best suits your personality!",
    "title": "{{tipoCorrea}} Watches for {{gama}} | Kronaby"
  },
  "reloj.gama.tipoEstanqueidad.default": {
    "h1": "{{tipoEstanqueidad}} Watches for {{gama}}",
    "meta": "Consult our wide catalog of {{tipoEstanqueidad}} Watches for {{gama}} made with the best materials and techniques on the market.",
    "title": "{{tipoEstanqueidad}} Watches for {{gama}} | Kronaby"
  },
  "reloj.gama.tiposMovimiento.01": {
    "h1": "Digital Watches for {{gama}}",
    "meta": "Are you looking for a digital watch for {{gama}}? At Kronaby we have a great selection of watches available. Come in and choose the one that best suits you.",
    "title": "Digital Watches for {{gama}} | Kronaby"
  },
  "reloj.gama.tiposMovimiento.02": {
    "h1": "Watches for {{gama}} with Digital Quartz movement.",
    "meta": "Are you looking for {{gama}} watches with Digital Quartz movement? Take a look at our wide range of watches for {{gama}} and choose the perfect model for you.",
    "robots": "false",
    "title": "Watches for {{gama}} with Digital Quartz movement | Kronaby"
  },
  "reloj.gama.tiposMovimiento.04": {
    "h1": "Automatic Watches for {{gama}}",
    "meta": "Are you looking for an automatic watch for {{gama}}? At Kronaby, we have a great selection of watches available. Come in and choose the one that best suits you.",
    "title": "Automatic Watches for {{gama}} | Kronaby"
  },
  "reloj.gama.tiposMovimiento.05": {
    "h1": "Hybrid Watches for {{gama}}",
    "meta": "Are you looking for a hybrid watch for {{gama}}? At Kronaby, we have a great selection of watches available. Come in and choose the one that best suits you.",
    "title": "Hybrid Watches for {{gama}} | Kronaby"
  },
  "reloj.gama.tiposMovimiento.06": {
    "h1": "Smartwatches for {{gama}}",
    "meta": "Are you looking for a smartwatch for {{gama}}? At Kronaby we have a great selection of smartwatches available. Come in and choose the one that best suits you.",
    "title": "Smartwatches for {{gama}} - Smartwatches | Kronaby"
  },
  "reloj.gama.tiposMovimiento.07": {
    "h1": "Quartz Watches for {{gama}}",
    "meta": "Are you looking for a quartz watch for {{gama}}? At Kronaby, we have a great selection of watches available. Come in and choose the one that best suits you.",
    "title": "Quartz Watches for {{gama}} | Kronaby"
  },
  "reloj.IN": {
    "h1": "Kids Watches",
    "meta": "Find the best junior watches in our catalog. Help them learn the time with the best models of the best brands. {{numPagina}}",
    "title": "Children's Watches {{numPagina}} | Kronaby"
  },
  "reloj.IN.funcion": {
    "h1": "Watches {{funcion}} for Children",
    "meta": "Do you want a {{funcion}} watch for a child? Find a variety of children's watches {{funcion}} from the best brands. Visit our catalog! {{numPagina}}",
    "title": "Watches {{funcion}} for Children {{numPagina}} | Kronaby"
  },
  "reloj.materialCaja.default": {
    "h1": "Watches with {{materialCaja}}case",
    "meta": "Do you like {{materialCaja}}watches? Discover our extensive catalog and find the watch that makes your wrist shine. {{numPagina}}",
    "title": "Watches with {{materialCaja}} {{numPagina}} case | Kronaby"
  },
  "reloj.materialCorrea.default": {
    "h1": "Watches {{materialCorrea}}",
    "meta": "Do you like watches with a {{materialCorrea}}strap? Discover our extensive catalog and find the watch that makes your wrist shine. {{numPagina}}",
    "title": "Watches {{materialCorrea}} {{numPagina}} | Kronaby"
  },
  "reloj.S": {
    "h1": "Ladies Watches",
    "meta": "In our Women's Watches catalogue, find the one that best suits your style and personality and wear it comfortably in your everyday life. {{numPagina}}",
    "title": "Ladies Watches {{numPagina}} | Kronaby"
  },
  "reloj.tipoCierre.default": {
    "h1": "Watches {{tipoCierre}}",
    "meta": "Looking for a Watch {{tipoCierre}}? Find the best models of the best brands and choose the perfect watch for you. Visit our catalog now {{numPagina}}",
    "title": "Watches {{tipoCierre}} {{numPagina}} | Kronaby"
  },
  "reloj.tipoCorrea.default": {
    "h1": "Watches {{tipoCorrea}}",
    "meta": "Looking for a Watch {{tipoCorrea}}? Find the best models of the best brands and choose the perfect watch for you. Visit our catalog now {{numPagina}}",
    "title": "Watches {{tipoCorrea}} {{numPagina}} | Kronaby"
  },
  "reloj.tiposMovimiento.01": {
    "h1": "Digital Watches",
    "meta": "Are you looking for a digital watch? At Kronaby, we have a great selection of digital watches available. Come in and choose the one that best suits you.",
    "robots": "false",
    "title": "Digital Watches | Kronaby"
  },
  "reloj.tiposMovimiento.02": {
    "h1": "Watches with movement Digital Quartz",
    "meta": "Are you looking for watches with Digital Quartz movement? Take a look at our wide range of watches and choose the perfect model for you.",
    "robots": "false",
    "title": "Watches with movement Digital Quartz | Kronaby"
  },
  "reloj.tiposMovimiento.03": {
    "h1": "Watches with movement Manual",
    "meta": "Are you looking for watches with Manual movement? Take a look at our wide range of watches and choose the perfect model for you.",
    "robots": "false",
    "title": "Watches with movement Manual | Kronaby"
  },
  "reloj.tiposMovimiento.04": {
    "h1": "Automatic Watches",
    "meta": "Are you looking for an automatic watch? At Kronaby, we have a great selection of automatic watches available. Come in and choose the one that best suits you.",
    "title": "Automatic Watches | Kronaby"
  },
  "reloj.tiposMovimiento.05": {
    "h1": "Hybrid Watches",
    "meta": "Are you looking for an automatic watch? At Kronaby, we have a great selection of automatic watches available. Come in and choose the one that best suits you.",
    "robots": "false",
    "title": "Hybrid Watches | Kronaby"
  },
  "reloj.tiposMovimiento.06": {
    "h1": "Smartwatches",
    "meta": "Are you looking for a smartwatch? At Kronaby, we have a great selection of smartwatches available. Come in and choose the one that best suits your style.",
    "title": "Smartwatches - Smartwatches | Kronaby"
  },
  "reloj.tiposMovimiento.07": {
    "h1": "Quartz Watches",
    "meta": "Are you looking for a quartz watch? At Kronaby, we have a great selection of quartz watches available. Come in and choose the one that best suits you.",
    "robots": "false",
    "title": "Quartz Watches | Kronaby"
  }
}
