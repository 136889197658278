{
  "title": "Kronaby.com",
  "description": "Ecommerce watches of FESTINA LOTUS GROUP S.A.",
  "keywords": "Kronabt, correa cuero, correa acero, automatico, acero, Swiss made",
  "image": "",
  "url": "",
  "robots": true,
  "canonical": true,
  "listaUrlPaths": "",
  "site": "@kronaby",
  "siteName": "kronaby.com"
}
