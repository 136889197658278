{
  "accesorio": {
    "h1": "Accessoires",
    "meta": "Trouvez le cadeau parfait pour toute occasion ou faites-vous plaisir et montrez les meilleurs accessoires. Visitez notre catalogue maintenant. {{numPagina}}",
    "title": "Accessoires | Kronaby"
  },
  "accesorio.default": {
    "h1": "Accessoires {{tipoAccesorio}} {{subtipoAccesorio}} {{tipoBoligrafo}}",
    "meta": "Trouvez le cadeau parfait pour toute occasion ou faites-vous plaisir et montrez les meilleurs accessoires. Visitez notre catalogue maintenant. {{numPagina}}",
    "robots": "false",
    "title": "Accessoires {{tipoAccesorio}} {{subtipoAccesorio}} {{tipoBoligrafo}} | Kronaby"
  },
  "accesorio.LEATHER_ITEM.CARD_HOLDER": {
    "h1": "Porte-cartes cuir",
    "meta": "La meilleure option pour transporter vos cartes est d'avoir nos Porte-cartes cuir fabriqués dans le matériau le plus résistant et le plus élégant.",
    "title": "Porte-cartes cuir | Kronaby"
  },
  "accesorio.LEATHER_ITEM.WALLET": {
    "h1": "Portefeuille cuir",
    "meta": "Nos portefeuilles en cuir sont parfaits. Fabriqués avec un matériau résistant et de différentes couleurs, ils sont idéaux à emporter où vous voulez.",
    "title": "Portefeuille cuir | Kronaby"
  },
  "accesorio.subtipoAccesorio.default": {
    "h1": "{{subtipoAccesorio}}",
    "meta": "Découvrez notre sélection de {{subtipoAccesorio}} dans notre boutique en ligne officielle.",
    "title": "{{subtipoAccesorio}} | Kronaby"
  },
  "accesorio.subtipoAccesorio.SOLAR": {
    "h1": "lunettes de soleil",
    "meta": "Découvrez notre sélection de lunettes de soleil dans notre boutique en ligne officielle.",
    "title": "Lunettes de soleil | Kronaby"
  },
  "accesorio.tipoAccesorio.default": {
    "h1": "Accessoires {{tipoAccesorio}}",
    "meta": "Parmi notre sélection d'accessoires Kronaby, trouvez le cadeau idéal pour les occasions spéciales ou pour vous faire plaisir.",
    "title": "Accessoires {{tipoAccesorio}} | Kronaby"
  },
  "accesorio.tipoAccesorio.KEYCHAIN": {
    "h1": "Porte-clés",
    "meta": "Les porte-clés sont un accessoire indispensable pour compléter votre style. Ils sont élégants et donnent une touche distinctive à celui qui les porte.",
    "title": "Porte-clés | Kronaby"
  },
  "accesorio.WRITING.BALLPOINT_PEN": {
    "h1": "Stylo à Bille ",
    "meta": "Nos Stylos designe paintball sont devenus les plus confortables et les plus faciles à écrire. De plus, ils présentent un design subtil et exclusif.",
    "title": "Stylo à Bille | Kronaby"
  },
  "accesorio.WRITING.FOUNTAIN_PEN": {
    "h1": "Stylo à Plume",
    "meta": "Donnez à votre calligraphie une touche personnelle avec nos Stylos à Plume. Vous pouvez les trouver dans différentes couleurs et designs.",
    "title": "Stylo à Plume | Kronaby"
  },
  "accesorio.WRITING.REPLACEMENT": {
    "h1": "Pièce de Rechange pour stylos ",
    "meta": "Dans notre boutique en ligne, vous pouvez également acheter des pièces de rechange pour stylos, et les acheter en packs de deux unités.",
    "title": "Pièce de Rechange pour stylos | Kronaby"
  },
  "accesorio.WRITING.ROLLERBALL_PEN": {
    "h1": "Stylo à Bille Roller ",
    "meta": "Découvrez nos Stylos à Bille Roller, des stylos de luxe au design élégant et discret, parfaits pour offrir des cadeaux lors de moments spéciaux.",
    "title": "Stylo à Bille Roller | Kronaby"
  },
  "reloj": {
    "h1": "Montres Kronaby",
    "meta": "Découvrez notre sélection de montres Kronaby uniques et d'accessoires d'écriture originaux dans notre boutique en ligne officielle.",
    "title": "Kronaby | Montres pour hommes et femmes | Site Officiel"
  },
  "reloj.C": {
    "h1": "Montres pour hommes",
    "meta": "Nos Montres pour hommes sont parfaites pour toutes les occasions. De conception unique, elles sont idéales pour compléter un style élégant et unique. {{numPagina}}",
    "title": "Montres pour Hommes {{numPagina}} | Kronaby"
  },
  "reloj.colorEsfera.default": {
    "h1": "Montres avec Cadran {{ colorEsfera }}",
    "meta": "A la recherche d'une montre unique et élégante ? Découvrez nos montres avec cadran {{ colorEsfera }} des meilleures marques et trouvez le modèle parfait pour vous.",
    "title": "Montres avec Cadran {{ colorEsfera }} | Kronaby"
  },
  "reloj.default": {
    "h1": "Montres {{marca}} {{funcion}} {{estilos}} {{colorCorrea}} {{colorEsfera}} {{materialCaja}} {{materialCorrea}}",
    "meta": "A la recherche d'une montre unique et élégante? Découvrez nos montres des meilleures marques et trouvez le modèle parfait pour vous. {{numPagina}}",
    "robots": "false",
    "title": "Montres {{gama}} {{marca}} {{funcion}} {{estilos}} {{colorCorrea}} {{colorEsfera}} {{materialCaja}} {{materialCorrea}} {{numPagina}} | Kronaby"
  },
  "reloj.funcion.default": {
    "h1": "Montres {{funcion}}",
    "meta": "Vous recherchez une horloge {{funcion}}? Trouvez les meilleurs modèles des meilleures marques et choisissez la montre parfaite pour vous. Consultez notre catalogue maintenant {{numPagina}}",
    "title": "Montres {{funcion}} {{numPagina}} | Kronaby"
  },
  "reloj.gama.colorCorrea.default": {
    "h1": "Montres {{colorCorrea}} pour {{gama}}",
    "meta": "Nous avons une sélection de couleurs pour vous permettre de choisir les montres {{gama}} {{colorCorrea}} qui conviennent le mieux à votre style. {{numPagina}}",
    "title": "Montres {{colorCorrea}} pour {{gama}} {{numPagina}} | Kronaby"
  },
  "reloj.gama.colorEsfera.default": {
    "h1": "Montres avec cadran {{colorEsfera}} pour {{gama}}",
    "meta": "Distingué et sophistiqué. Voici à quoi ressemblent nos Montres avec cadran {{colorEsfera}} pour {{gama}}, qui ajouteront une touche d'élégance à votre poignet.",
    "title": "Montres avec cadran {{colorEsfera}} pour {{gama}} {{numPagina}} | Kronaby"
  },
  "reloj.gama.funcion.default": {
    "h1": "Montres {{funcion}} pour {{gama}}",
    "meta": "Trouvez les Montres Kronaby les plus incroyables et sophistiquées pour {{gama}} {{funcion}} sur le marché et portez-les en toute occasion. {{numPagina}}",
    "title": "Montres {{funcion}} pour {{gama}} {{numPagina}} | Kronaby"
  },
  "reloj.gama.materialCaja.default": {
    "h1": "montres avec boîtier de {{materialCaja}} pour {{gama}}",
    "meta": "Les montres aux boîtiers {{materialCaja}} à {{gama}} se distinguent par leur design soigné. Entrez dans notre boutique pour choisir celui qui vous convient le mieux.",
    "title": "montres avec boîtier de {{materialCaja}} pour {{gama}} {{numPagina}} boîtier | Kronaby"
  },
  "reloj.gama.materialCorrea.default": {
    "h1": "Montres pour {{materialCorrea}} {{gama}}",
    "meta": "Les Montres Kronaby pour {{gama}} {{materialCorrea}} deviennent l'option parfaite pour porter une montre exclusive et maniable à votre poignet.",
    "title": "Montres pour {{gama}} {{materialCorrea}} | Kronaby"
  },
  "reloj.gama.tipoCierre.default": {
    "h1": "Montres pour {{gama}} à {{tipoCierre}}",
    "meta": "Sur notre web, vous verrez la sélection de montres pour {{gama}} à {{tipoCierre}} que nous avons. Nous avons des pièces uniques rien que pour vous.",
    "title": "Montres pour {{gama}} à {{tipoCierre}} | Kronaby"
  },
  "reloj.gama.tipoCorrea.ARMYS": {
    "h1": "Montres pour {{gama}} {{tipoCorrea}}",
    "meta": "Chez Kronaby, nous proposons des montres pour {{gama}} {{tipoCorrea}} pour toutes les occasions. Trouvez celui qui correspond à votre style!",
    "title": "Montres pour {{gama}} {{tipoCorrea}} | Kronaby"
  },
  "reloj.gama.tipoCorrea.CORREA": {
    "h1": "Montres pour {{gama}} {{tipoCorrea}}",
    "meta": "Chez Kronaby, nous proposons des montres pour {{gama}} {{tipoCorrea}} pour toutes les occasions. Trouvez celui qui correspond à votre style!",
    "title": "Montres pour {{gama}} {{tipoCorrea}} | Kronaby"
  },
  "reloj.gama.tipoEstanqueidad.default": {
    "h1": "Montres pour {{gama}} {{tipoEstanqueidad}}",
    "meta": "Consultez notre large catalogue de montres pour {{gama}} {{tipoEstanqueidad}} fabriquées avec les meilleurs matériaux et techniques du marché.",
    "title": "Montres pour {{gama}} {{tipoEstanqueidad}} | Kronaby"
  },
  "reloj.gama.tiposMovimiento.01": {
    "h1": "Montres numériques pour {{gama}}",
    "meta": "Cherchez-vous une montre numérique pour {{gama}}? ChezKronaby, nous avons une grande sélection de montres disponibles. Entrez et choisissez celle qui vous convient le mieux.",
    "title": "Montres numériques pour {{gama}} | Kronaby"
  },
  "reloj.gama.tiposMovimiento.02": {
    "h1": "Montres pour {{gama}} avec mouvement Digital Quartz",
    "meta": "Vous recherchez des montres pour {{gama}} avec un mouvement Digital Quartz? Jetez un œil à notre large gamme de montres pour {{gama}} et choisissez le modèle parfait pour vous.",
    "robots": "false",
    "title": "Montres pour {{gama}} avec mouvement Digital Quartz | Kronaby"
  },
  "reloj.gama.tiposMovimiento.04": {
    "h1": "Montres automatiques pour {{gama}}",
    "meta": "Cherchez-vous une montre automatique pour {{gama}}? ChezKronaby, nous avons une grande sélection de montres disponibles. Entrez et choisissez celle qui vous convient le mieux.",
    "title": "Montres automatiques pour {{gama}} | Kronaby"
  },
  "reloj.gama.tiposMovimiento.05": {
    "h1": "Montres hybrides pour{{gama}}",
    "meta": "Vous recherchez une montre hybride pour {{gama}}? ChezKronaby, nous avons une grande sélection de montres disponibles. Venez et choisissez celle qui vous convient le mieux.",
    "title": "Montres hybrides pour{{gama}} | Kronaby"
  },
  "reloj.gama.tiposMovimiento.06": {
    "h1": "Montres intelligentes pour {{gama}}",
    "meta": "Vous recherchez une montre connectée pour {{gama}}? ChezKronaby, nous avons une grande sélection de montres intelligentes disponibles. Venez et choisissez celle qui vous convient le mieux.",
    "title": "Montres connectées pour {{gama}} - Montres intelligentes | Kronaby"
  },
  "reloj.gama.tiposMovimiento.07": {
    "h1": "Montres à quartz pour {{gama}}",
    "meta": "Vous recherchez une montre à quartz pour {{gama}}? ChezKronaby, nous avons une grande sélection de montres disponibles. Venez et choisissez celle qui vous convient le mieux.",
    "title": "Montres à quartz pour {{gama}} | Kronaby"
  },
  "reloj.IN": {
    "h1": "Montres enfants",
    "meta": "Vous cherchez les meilleures Montres Enfants? Dans notre boutique en ligne, vous trouverez des montres amusantes pour les plus petits de la maison. {{numPagina}}",
    "title": "Montres pour enfants {{numPagina}} | Kronaby"
  },
  "reloj.IN.funcion": {
    "h1": "Montres {{funcion}} pour enfants",
    "meta": "Voulez-vous une montre {{funcion}} pour un enfant? Trouvez une variété de montres pour enfants {{funcion}} des meilleures marques. Visitez notre catalogue! {{numPagina}}",
    "title": "Montres {{funcion}} pour enfants {{numPagina}} | Kronaby"
  },
  "reloj.materialCaja.default": {
    "h1": "Montres avec étui {{materialCaja}}",
    "meta": "Aimez-vous {{materialCaja}}montres? Découvrez notre vaste catalogue et trouvez la montre qui fait briller votre poignet. {{numPagina}}",
    "title": "Montres avec étui {{materialCaja}} {{numPagina}} | Kronaby"
  },
  "reloj.materialCorrea.default": {
    "h1": "Montres {{materialCorrea}}",
    "meta": "Aimez-vous les montres avec un bracelet {{materialCorrea}}? Découvrez notre vaste catalogue et trouvez la montre qui fait briller votre poignet. {{numPagina}}",
    "title": "Montres {{materialCorrea}} {{numPagina}} | Kronaby"
  },
  "reloj.S": {
    "h1": "Montres pour femmes",
    "meta": "Dans notre catalogue Montres Femme, trouvez celle qui correspond le mieux à votre style et portez-la confortablement au quotidien. {{numPagina}}",
    "title": "Montres pour Femmes {{numPagina}} | Kronaby"
  },
  "reloj.tipoCierre.default": {
    "h1": "Montres {{tipoCierre}}",
    "meta": "Vous recherchez une horloge {{tipoCierre}}? Trouvez les meilleurs modèles des meilleures marques et choisissez la montre parfaite pour vous. Consultez notre catalogue maintenant {{numPagina}}",
    "title": "Montres {{tipoCierre}} {{numPagina}} | Kronaby"
  },
  "reloj.tipoCorrea.default": {
    "h1": "Montres {{tipoCorrea}}",
    "meta": "Vous recherchez une horloge {{tipoCorrea}}? Trouvez les meilleurs modèles des meilleures marques et choisissez la montre parfaite pour vous. Consultez notre catalogue maintenant {{numPagina}}",
    "title": "Montres {{tipoCorrea}} {{numPagina}} | Kronaby"
  },
  "reloj.tiposMovimiento.01": {
    "h1": "Montres numériques",
    "meta": "Cherchez-vous une montre numérique ? Chez Kronaby, nous avons une grande sélection de montres numériques disponibles. Entrez et choisissez celle qui vous convient le mieux.",
    "robots": "false",
    "title": "Montres numériques | Kronaby"
  },
  "reloj.tiposMovimiento.02": {
    "h1": "Montres avec mouvement Digital Quartz",
    "meta": "Vous recherchez des montres avec un mouvement Digital Quartz ? Jetez un œil à notre large gamme de montres et choisissez le modèle parfait pour vous.",
    "robots": "false",
    "title": "Montres avec mouvement Digital Quartz | Kronaby"
  },
  "reloj.tiposMovimiento.03": {
    "h1": "Montres avec mouvement Manuel ",
    "meta": "Vous recherchez des montres avec un mouvement Manuel? Jetez un œil à notre large gamme de montres et choisissez le modèle parfait pour vous.",
    "robots": "false",
    "title": "Montres avec mouvement Manuel | Kronaby"
  },
  "reloj.tiposMovimiento.04": {
    "h1": "Montres automatiques",
    "meta": "Cherchez-vous une montre automatique? Chez Kronaby, nous avons une grande sélection de montres automatiques disponibles. Entrez et choisissez celle qui vous convient le mieux.",
    "title": "Montres automatiques | Kronaby"
  },
  "reloj.tiposMovimiento.05": {
    "h1": "Montres hybrides",
    "meta": "Cherchez-vous une montre automatique ? Chez Kronaby, nous avons une grande sélection de montres automatiques disponibles. Entrez et choisissez celle qui vous convient le mieux.",
    "robots": "false",
    "title": "Montres hybrides | Kronaby"
  },
  "reloj.tiposMovimiento.06": {
    "h1": "Montres intelligentes",
    "meta": "Vous recherchez une montre connectée ? Chez Kronaby, nous avons une grande sélection de montres intelligentes disponibles. Venez et choisissez celle qui correspond le mieux à votre style.",
    "title": "Montres intelligentes - Montres intelligentes | Kronaby"
  },
  "reloj.tiposMovimiento.07": {
    "h1": "Montres à quartz",
    "meta": "Cherchez-vous une montre à quartz ? ChezKronaby, nous avons une grande sélection de montres à quartz disponibles. Venez et choisissez celle qui vous convient le mieux.",
    "robots": "false",
    "title": "Montres à quartz | Kronaby"
  }
}
