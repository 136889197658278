{
  "acabado.AC-OR": "ACCIAIO-ORO",
  "acabado.B": "luminosità",
  "acabado.BP01": "BP01",
  "acabado.BP01C": "CERCHIO DI COLORE",
  "acabado.BSL": "BSL",
  "acabado.CAUCH": "Gomma",
  "acabado.CORB": "Cravatta",
  "acabado.CRISA": "CRISALIDE",
  "acabado.ESTAM": "stampa",
  "acabado.F+R": "PIATTO + TONDO",
  "acabado.F+S": "PIATTO + DRITTO",
  "acabado.F+S C": "FLAT + COLORE REC",
  "acabado.F+T": "PIATTO + CRAVATTA",
  "acabado.FAC06": "FAC06",
  "acabado.FR11": "FRAP 11",
  "acabado.FRA15": "FRAP15",
  "acabado.GRAB": "Registrato",
  "acabado.LAPI": "LAPIDADO",
  "acabado.LASER": "ESSERE",
  "acabado.M": "Compagno",
  "acabado.M+S": "MONTAGNA + DRITTO",
  "acabado.M+S C": "MONT.RECT.COLOR",
  "acabado.M+T": "MONTAGNA + CRAVATTA",
  "acabado.MAL": "maglia",
  "acabado.MB": "OPACO-LUCIDO",
  "acabado.MECA": "Lavorazioni",
  "acabado.MF5P": "MICROFUSIONE 5PZ",
  "acabado.MMILA": "MAGLIA MILANESE",
  "acabado.MOUNT": "MONTANA",
  "acabado.MTCA": "MATE CALADA",
  "acabado.OYS": "OSTRICA",
  "acabado.PIR3": "PYRAMIDAL 3M",
  "acabado.PMP": "PULI-MATE-PULI",
  "acabado.PPM": "PANTERA LUCIDO OPACO",
  "acabado.PPU": "PANTERA LUCIDO",
  "acabado.PRES": "Presidente",
  "acabado.PULID": "lucidato",
  "acabado.RAS": "graffiato",
  "acabado.ROUND": "Rotonda",
  "acabado.S7M": "ESTAM 7 3MAT",
  "acabado.S7R": "TIMBRO 7PZ STRIPE",
  "acabado.S73M": "ESTAMP 7-3MATE",
  "acabado.ST3": "TIMBRATURA 3 PZ",
  "acabado.ST4": "TIMBRATURA 4PZ",
  "acabado.ST5": "TIMBRATO 5PZ",
  "acabado.ST6": "STAMPATO 6PZ",
  "acabado.ST7": "STAMPAGGIO 7 PEZZI",
  "acabado.TPMP": "TUBO P-M-P",
  "acabado.TUB": "Tubo",
  "acabado.V620": "V620",
  "acabado.Y": "MONTAGNA LUCIDATA",
  "acabado.YHLO": "MONTAGNA 1 / 2B + M",
  "accesorios.subtipo.BAG": "Borse",
  "accesorios.subtipo.BALLPOINT_PEN": "Penna a sfera Ballpoint",
  "accesorios.subtipo.BELT": "Cintura",
  "accesorios.subtipo.CARD_HOLDER": "Portacarte",
  "accesorios.subtipo.DOCUMENT_BAG": "Borsa per documenti",
  "accesorios.subtipo.DRESSING_CASE": "Borsa dei trucchi",
  "accesorios.subtipo.FOLDER": "raccoglitore",
  "accesorios.subtipo.FOUNTAIN_PEN": "penna stilografica",
  "accesorios.subtipo.LAPTOP_BAG": "Borsa per computer portatile",
  "accesorios.subtipo.LEATHER_PEN_SET": "set penne pelle",
  "accesorios.subtipo.OPTIC": "Occhiali ottici",
  "accesorios.subtipo.OPTIC_SOLAR_CLIP": "",
  "accesorios.subtipo.PEN": "penna",
  "accesorios.subtipo.REPLACEMENT": "Pezzo di ricambio",
  "accesorios.subtipo.REPORTER_BAG": "Borsa a tracolla",
  "accesorios.subtipo.ROLLERBALL_PEN": "Penna a sfera Rollerball",
  "accesorios.subtipo.SOLAR": "Occhiali da sole",
  "accesorios.subtipo.WALLET": "portafoglio",
  "accesorios.subtipo.WATCH_CASE": "Cassa dell'orologio",
  "accesorios.subtipo.WRITING_CASE": "Caso di scrittura",
  "accesorios.subtipo.WRITING_SET": "set scrittura",
  "accesorios.tipo.CLEANER": "addetti alle pulizie",
  "accesorios.tipo.CUFFLINKS": "Gemelli",
  "accesorios.tipo.EYEWEAR": "Occhiali",
  "accesorios.tipo.FRAGANCE": "profumi",
  "accesorios.tipo.JEWELLERY_BOX": "Gioiellieri",
  "accesorios.tipo.KEYCHAIN": "PORTACHIAVI",
  "accesorios.tipo.LEATHER_ITEM": "articolo in pelle",
  "accesorios.tipo.SET": "imposta",
  "accesorios.tipo.SPARE_PARTS": "ricambi",
  "accesorios.tipo.TOOL": "utensili",
  "accesorios.tipo.WATCHBAND": "Braccialetti",
  "accesorios.tipo.WRITING": "scrittura",
  "accesorios.tipo_boligrafo.BALLPOINT": "Ballpoint",
  "accesorios.tipo_boligrafo.ROLLERBALL": "Rollerball",
  "agrupacion.coleccion": "collezione",
  "agrupacion.seleccion": "selezione",
  "anchopuente.14": "14 mm",
  "anchopuente.15": "15 mm",
  "anchopuente.16": "16 mm",
  "anchopuente.17": "17 mm",
  "anchopuente.18": "18 mm",
  "anchopuente.21": "21 mm",
  "calendario.false": "No",
  "calendario.true": "sì",
  "caracteristicas.alarma": "Allarme",
  "caracteristicas.balancier_visible": "Bilanciere visibile",
  "caracteristicas.complicacion_automatico": "Complicazione automatica",
  "caracteristicas.corona_rosca": "Corona a vite",
  "caracteristicas.dual_time": "Tempo doppio",
  "caracteristicas.gmt": "GMT",
  "caracteristicas.luminova": "Luminova",
  "caracteristicas.luz": "Luce",
  "caracteristicas.reserva_marcha": "Riserva di carica",
  "caracteristicas.skeleton": "Skeleton",
  "caracteristicas.solar": "Solare",
  "caracteristicas.superluminova": "Superluminova",
  "caracteristicas.tourbillon": "Tourbillon",
  "cierre.agrupado.CAJ": "Apribile",
  "cierre.agrupado.CAP": "PULSANTIERA",
  "cierre.agrupado.CCAT": "Perno",
  "cierre.agrupado.CCJ": "CHIUSURA CASSETTO",
  "cierre.agrupado.CGAN": "Monachella",
  "cierre.agrupado.CI": "Farfalla",
  "cierre.agrupado.CIP": "A pressione",
  "cierre.agrupado.CLIP": "Clip",
  "cierre.agrupado.CM": "MAGNETICO",
  "cierre.agrupado.COMEGA": "Cerchio",
  "cierre.agrupado.CPC": "SCATOLA PULS CINTURA",
  "cierre.agrupado.CPL": "Lato",
  "cierre.agrupado.CPR": "Pressione",
  "cierre.agrupado.CSE": "Chiusura a chiave",
  "cierre.agrupado.CSP": "PULSANTE DI CHIUSURA SICURA",
  "cierre.agrupado.HEB": "Fibbia",
  "cierre.agrupado.HKSALO": "LUCCHETTO DI SICUREZZA",
  "cierre.agrupado.LI": "Pressione del libro",
  "cierre.agrupado.MOS": "MOSCHETTONE",
  "cierre.agrupado.OCL": "Nascosto",
  "cierre.agrupado.OCP": "PULSANTE NASCOSTO",
  "cierre.agrupado.OCU": "Africano",
  "cierre.agrupado.ROS": "A Vite",
  "cierre.agrupado.SO": "Doppio pulsante",
  "cierre.agrupado.SOP": "BOTTONE PATTA",
  "cierre.CAJ": "Apribile",
  "cierre.CAP": "PULSANTIERA",
  "cierre.CCJ": "CHIUSURA CASSETTO (STILE)",
  "cierre.CI": "Moschettone",
  "cierre.CIP": "A Pressione",
  "cierre.CM": "MAGNETICO",
  "cierre.CMS": "MAGNETICO CON ASSICURAZIONE",
  "cierre.CPC": "CINTURA PULS BOX",
  "cierre.CPL": "PULS DOPO SCATOLA.",
  "cierre.CPR": "Pressione",
  "cierre.CSE": "Chiusura a chiave",
  "cierre.CSP": "PULSANTE DI CHIUSURA SICURA",
  "cierre.HEB": "Fibbia",
  "cierre.HKBB": "FIBBIA A FARFALLA",
  "cierre.HKCB": "FIBBIA A CLIP",
  "cierre.HKCLB": "FIBBIA A CLIP LOCK",
  "cierre.HKJEWBUK": "FIBBIA DI GIOIELLI",
  "cierre.HKLB": "FIBBIA IN PELLE",
  "cierre.HKLOBUK": "Chiusura a farfalla ",
  "cierre.HKMB": "FIBBIA MAGNETICA",
  "cierre.HKSALO": "LUCCHETTO DI SICUREZZA",
  "cierre.HKSCB": "FIBBIA A CLIP A MOLLA",
  "cierre.HKT-BABUK": "FIBBIA T-BAR",
  "cierre.HOR": "FIBBIA IN ORO",
  "cierre.LI": "Pressione del libro",
  "cierre.MOS": "MOSCHETTONE",
  "cierre.NEO": "NON TRASPORTA",
  "cierre.NOSE": "Non lo so",
  "cierre.OCH": "FIBBIA DI CHIUSURA NASCOSTA",
  "cierre.OCL": "Chiusura nascosta a libro",
  "cierre.OCP": "PULSANTE NASCOSTO",
  "cierre.OCS": "PATTA NASCOSTA",
  "cierre.OCU": "Africano",
  "cierre.OPC": "CINTURINO NASCOSTO PUSH",
  "cierre.OSP": "PULSANTI NASCOSTI DELLA PATTINA",
  "cierre.ROS": "CHIUSURA A FILETTO",
  "cierre.SO": "Doppio pulsante",
  "cierre.SOC": "CINTURINO CON PATTA",
  "cierre.SOH": "FIBBIA CON PATTA",
  "cierre.SOP": "BOTTONE PATTA",
  "cierre.SPC": "CINTURA PULSANTE FLAP",
  "cierre.undefined": "",
  "colores_base.1": "CRO",
  "colores_base.2N18": "2N18 GOLDEN",
  "colores_base.2NBN": "CENTRO 2N18 CORPO NERO",
  "colores_base.2NMA": "2N18 CON MARCHI",
  "colores_base.2NTN": "2N18 CON FILETTO NERO",
  "colores_base.3N": "3N ORO",
  "colores_base.3N18": "3N18",
  "colores_base.4N18": "ORO ROSA CHIARO",
  "colores_base.5N": "5N ORO ROSA",
  "colores_base.5N18": "Oro rosa",
  "colores_base.026": "AVORIO",
  "colores_base.032": "Marrone scuro",
  "colores_base.037": "MARRONE AVANA",
  "colores_base.041": "ORO MEDIO",
  "colores_base.061": "BLU MEDIO",
  "colores_base.065": "azzurro",
  "colores_base.066": "azzurro",
  "colores_base.068": "azzurro",
  "colores_base.084": "CORALLO",
  "colores_base.100": "ACCIAIO LUCIDO",
  "colores_base.128": "Nudo",
  "colores_base.591": "PVD NERO",
  "colores_base.600": "Oro rosa",
  "colores_base.840": "ACCIAIO + ORO",
  "colores_base.850": "ACCIAIO + ORO ROSA",
  "colores_base.ACCC": "ZIRCONIA IN ACCIAIO",
  "colores_base.AG": "Argento",
  "colores_base.AGAB": "AMBRA ARGENTO",
  "colores_base.AGCR": "CRISTALLO ARGENTO",
  "colores_base.AGLA": "ARGENTO LAVANDA",
  "colores_base.AGLM": "CALCE ARGENTO",
  "colores_base.AGMC": "MARCATO ARGENTO",
  "colores_base.AGMO": "VIOLA ARGENTO",
  "colores_base.AGPE": "PERLA D'ARGENTO",
  "colores_base.AGRO": "ROSSO ARGENTO",
  "colores_base.AGTP": "TOPAZIO ARGENTO",
  "colores_base.AGU": "Acquamarina",
  "colores_base.AM": "giallo",
  "colores_base.AMA": "Ametista",
  "colores_base.AMBA": "ambra",
  "colores_base.AMBR": "GIALLO BRONZO",
  "colores_base.AMCC": "ZIRCONIA GIALLO",
  "colores_base.AMMA": "GIALLO MARRONE",
  "colores_base.AMPE": "PERLA GIALLA",
  "colores_base.AMT": "Ametista",
  "colores_base.AQ": "Acquamarina",
  "colores_base.AQBO": "AQUA BOHEMICA",
  "colores_base.AUR": "DAWN",
  "colores_base.AURB": "AURORA BOREALE",
  "colores_base.AVAZ": "AVVENTURINA BLU",
  "colores_base.AZ": "Blu",
  "colores_base.AZRS": "ROSA BLU",
  "colores_base.BDIA": "DIAMANTE NERO",
  "colores_base.BE": "BEIGE",
  "colores_base.BICO": "BICOLORE",
  "colores_base.BL": "bianco",
  "colores_base.BLCO": "BIANCO CORALLO",
  "colores_base.BLPE": "PERLA BIANCA",
  "colores_base.BLRY": "BIANCO A RIGHE",
  "colores_base.BR": "Bronzo",
  "colores_base.BRCC": "ZIRCONI IN BRONZO",
  "colores_base.BRIL": "SCINTILLANTE",
  "colores_base.BRLI": "LILLA BRONZO",
  "colores_base.BRRS": "ROSA BRONZO",
  "colores_base.BU": "BORDEAUX",
  "colores_base.BUCR": "CREMA BORDEAUX",
  "colores_base.CA": "CAMMELLO",
  "colores_base.CABA": "Cavallo",
  "colores_base.CAL": "trascinare",
  "colores_base.CALD": "CALDAIA",
  "colores_base.CCPE": "ZIRCONIA PEARL",
  "colores_base.CER": "CILIEGIA",
  "colores_base.CH": "CHAMPAGNE",
  "colores_base.CHA": "PLACCATO",
  "colores_base.ChGR": "PLACCATO GRIGIO",
  "colores_base.CHOC": "CIOCCOLATO",
  "colores_base.CIRC": "Zirconia",
  "colores_base.CITR": "citrino",
  "colores_base.CM": "crema",
  "colores_base.CMFU": "CREMA FUCSIA",
  "colores_base.CO": "rame",
  "colores_base.CORA": "CORALLO",
  "colores_base.CQ": "CACHI",
  "colores_base.CR": "CROMATO",
  "colores_base.CRDO": "CRISTALLO DORATO",
  "colores_base.CRIS": "Cristallo",
  "colores_base.CRTR": "CRISTALLO TRASPARENTE",
  "colores_base.CURR": "CURRO",
  "colores_base.CZ": "QUARZO",
  "colores_base.CZAZ": "QUARZO BLU",
  "colores_base.CZMA": "QUARZO MARRONE",
  "colores_base.CZRS": "QUARZO ROSA",
  "colores_base.DELF": "delfino",
  "colores_base.DI": "Digitale",
  "colores_base.DIAM": "Diamanti",
  "colores_base.DO": "GOLDEN",
  "colores_base.DONE": "NERO DORATO",
  "colores_base.ESM": "Smeraldo",
  "colores_base.EXPO": "EXPO",
  "colores_base.FLOR": "Fiore",
  "colores_base.FLUO": "FLUORESCENTE",
  "colores_base.FOCA": "Foca",
  "colores_base.FR": "fragola",
  "colores_base.FRAM": "LAMPONE",
  "colores_base.FU": "Fucsia",
  "colores_base.FUCC": "ZIRCONI FUCSIA",
  "colores_base.FUME": "FUMI",
  "colores_base.GATO": "Gatto",
  "colores_base.GN": "Granato",
  "colores_base.GR": "grigio",
  "colores_base.GRA": "Granato",
  "colores_base.GRAG": "GRIGIO ARGENTO",
  "colores_base.GRPL": "GRIGIO ARGENTO",
  "colores_base.HA": "HAMILTON",
  "colores_base.HAB": "L'AVANA",
  "colores_base.HAHA": "HAMILTON HAMILTON",
  "colores_base.HAMC": "MARCHIO HAMILTON",
  "colores_base.HANA": "HAMILTON NACAR",
  "colores_base.HANC": "HAMILTON MADREPERLA",
  "colores_base.HEM": "EMATITE",
  "colores_base.INDI": "INDICOLITE",
  "colores_base.JET": "JET",
  "colores_base.KOAL": "KOALA",
  "colores_base.LI": "LILLA",
  "colores_base.LIOS": "LILLA SCURO",
  "colores_base.LIPE": "PERLA LILLA",
  "colores_base.LUMI": "SUPERLUMINOVA",
  "colores_base.LUN": "Luna",
  "colores_base.MA": "marrone",
  "colores_base.MAAG": "MARRONE / ARGENTO",
  "colores_base.MAPE": "MARRONE PERLA",
  "colores_base.MBLU": "BLU METALLIZZATO",
  "colores_base.MC": "segnato",
  "colores_base.ME": "pesca",
  "colores_base.MEN": "MENTA",
  "colores_base.MF": "AVORIO",
  "colores_base.MFNE": "AVORIO-NERO",
  "colores_base.MGRE": "VERDE METALLIZZATO",
  "colores_base.MO": "viola",
  "colores_base.MOBL": "BIANCO VIOLA",
  "colores_base.MOS": "MOSTARDA",
  "colores_base.MS": "MOSTARDA",
  "colores_base.MSIL": "ARGENTO METALLIZZATO",
  "colores_base.MT": "Compagno",
  "colores_base.MULT": "MULTICOLORE",
  "colores_base.MULTAM": "GIALLO MULTICOLORE",
  "colores_base.MULTAZ": "BLU MULTICOLORE",
  "colores_base.MULTRS": "ROSA MULTICOLORE",
  "colores_base.NA": "MADREPERLA",
  "colores_base.NAAZ": "BLU NACAR",
  "colores_base.NE": "nero",
  "colores_base.NEAM": "NERO GIALLO",
  "colores_base.NECC": "ZIRCONI NERI",
  "colores_base.NEDO": "ORO NERO",
  "colores_base.NEGD": "DIAMANTE NERO",
  "colores_base.NEO": "NON TRASPORTA",
  "colores_base.NEPE": "PERLA NERA",
  "colores_base.NJ": "Arancione",
  "colores_base.NJNC": "PERLA ARANCIONE",
  "colores_base.NYB": "NYLON BIANCO",
  "colores_base.NYBE": "NYLON BEIG",
  "colores_base.NYBL": "NYLON BIANCO",
  "colores_base.NYC": "CREMA DI NYLON",
  "colores_base.NYCM": "CREMA DI NYLON",
  "colores_base.NYG": "NYLON GRIGIO",
  "colores_base.NYGR": "NYLON GRIGIO",
  "colores_base.NYM": "MARRONE NYLON",
  "colores_base.NYMA": "MARRONE NYLON",
  "colores_base.NYN": "NYLON NERO",
  "colores_base.NYNE": "NYLON NERO",
  "colores_base.NYO": "NYLON OCRA",
  "colores_base.NYR": "NYLON ROSSO",
  "colores_base.OBSI": "OSSIDIANA",
  "colores_base.OC": "OCRA",
  "colores_base.OCR": "OCRA",
  "colores_base.OLIV": "OLIVINA",
  "colores_base.ONIX": "ONICE",
  "colores_base.OR": "oro",
  "colores_base.ORAZ": "ORO BLU",
  "colores_base.PACH": "CHAMPAGNE BLU",
  "colores_base.PAJA": "uccello",
  "colores_base.PAV": "PAVONADO",
  "colores_base.PD": "Palladio",
  "colores_base.PER": "Perla",
  "colores_base.PERN": "PERLA NERA",
  "colores_base.PERR": "cane",
  "colores_base.PLAG": "ARGENTO ARGENTO",
  "colores_base.PLAM": "GIALLO ARGENTO",
  "colores_base.PLAZ": "BLU ARGENTO",
  "colores_base.PLLI": "ARGENTO LILLA",
  "colores_base.PLNE": "ARGENTO / NERO",
  "colores_base.PLPL": "ARGENTO ARGENTO",
  "colores_base.PLRS": "ARGENTO ROSA",
  "colores_base.PLVE": "VERDE ARGENTO",
  "colores_base.PV": "PAVONADO",
  "colores_base.PVCH": "CHAMPAGNE BLU",
  "colores_base.PVD": "PVD",
  "colores_base.RH": "rodio",
  "colores_base.RH5N": "ORO BIANCO + ORO 5N",
  "colores_base.RO": "rosso",
  "colores_base.ROCA": "CALADA ROSSA",
  "colores_base.ROMS": "SENAPE ROSSA",
  "colores_base.RONE": "ROSSO NERO",
  "colores_base.RS": "rosa",
  "colores_base.RSCC": "ZIRCONIA ROSA",
  "colores_base.RSPE": "ROSA PERLA",
  "colores_base.RSRO": "ROSA ROSA",
  "colores_base.RU": "RUTENIO",
  "colores_base.RUBI": "Rubino",
  "colores_base.SASA": "SALMONE SALMONE",
  "colores_base.ST": "SEMI TRASPARENTE",
  "colores_base.TA": "TIGRE BLU",
  "colores_base.TEJA": "TEGOLA",
  "colores_base.TIT": "TITANIO",
  "colores_base.TOP": "Topazio",
  "colores_base.TP": "Topazio",
  "colores_base.TPCL": "TOPAZIO CHIARO",
  "colores_base.TR": "TIGRE ROSA",
  "colores_base.TRAN": "Trasparente",
  "colores_base.TRCO": "TRICOLORE",
  "colores_base.TRI": "TRICOLORE",
  "colores_base.TRNE": "TRANSP NERO",
  "colores_base.TW/W": "TOP WESSELTON A WESSELTON",
  "colores_base.VA": "VANIGLIA",
  "colores_base.VE": "verde",
  "colores_base.VEBRI": "VERDE BRILLANTE",
  "colores_base.VECC": "ZIRCONIA VERDE",
  "colores_base.VI": "viola",
  "colores_base.VIDI": "VIOLA DIGITALE",
  "colores_base.VIGR": "GRIGIO VIOLA",
  "colores_base.VIN": "VINO",
  "colores_base.VIOS": "Viola scuro",
  "colores_base.ZAFI": "Zaffiro",
  "colores_catalogo.AG": "Argento",
  "colores_catalogo.AM": "giallo",
  "colores_catalogo.AZ": "Blu",
  "colores_catalogo.BE": "BEIGE",
  "colores_catalogo.BL": "bianco",
  "colores_catalogo.BU": "BORDEAUX",
  "colores_catalogo.CH": "CHAMPAGNE",
  "colores_catalogo.CM": "crema",
  "colores_catalogo.CO": "rame",
  "colores_catalogo.DO": "GOLDEN",
  "colores_catalogo.GR": "grigio",
  "colores_catalogo.MA": "marrone",
  "colores_catalogo.MULT": "MULTICOLORE",
  "colores_catalogo.NA": "MADREPERLA",
  "colores_catalogo.NE": "nero",
  "colores_catalogo.NJ": "Arancione",
  "colores_catalogo.PL": "Argento",
  "colores_catalogo.PLA": "Argento",
  "colores_catalogo.PLAZ": "BLU ARGENTO",
  "colores_catalogo.PLRS": "ARGENTO ROSA",
  "colores_catalogo.RO": "rosso",
  "colores_catalogo.ROSE": "rosa",
  "colores_catalogo.RS": "rosa",
  "colores_catalogo.VE": "verde",
  "colores_catalogo.VI": "viola",
  "correa.agrupada.ARMYS": "BRACCIALE IN ACCIAIO",
  "correa.agrupada.CORREA": "CINTURINO",
  "estanqueidad.1A": "1 ATM",
  "estanqueidad.3A": "3 ATM",
  "estanqueidad.5A": "5 ATM",
  "estanqueidad.10A": "10 ATM",
  "estanqueidad.20A": "20 ATM",
  "estanqueidad.?": "Nessuna tenuta",
  "estanqueidad.IP68": "IP68",
  "estanqueidad.NEO": "Non ha",
  "estanqueidad.R.P.": "RESISTENTE ALLA POLVERE",
  "estanqueidad.RP": "RESISTENTE ALLA POLVERE",
  "estanqueidad.SHPR": "IDROREPELLENTE",
  "estanqueidad.W.P.": "PROVA D'ACQUA.",
  "estanqueidad.W.R.": "Resistente all'acqua",
  "estanqueidad.WR": "Resistente all'acqua",
  "estilos.CA": "Casuale",
  "estilos.CH": "CHIC",
  "estilos.CL": "Classico",
  "estilos.EL": "eleganza",
  "estilos.LE": "EDIZIONE LIMITATA",
  "estilos.SP": "Gli sport",
  "estilos.VI": "Vintage ▾",
  "formaCaja.C": "Piazza",
  "formaCaja.CR": "Attraversare",
  "formaCaja.DI": "Digitale",
  "formaCaja.IR": "IRREGOLARE",
  "formaCaja.O": "Ovale",
  "formaCaja.RC": "RETTANGOLARE",
  "formaCaja.RD": "Rotonda",
  "formaCaja.TO": "BARILE",
  "formaCaja.TV": "Televisione",
  "funcion.agrupada.101": "Funzioni Digitali",
  "funcion.agrupada.102": "Multifunzione",
  "funcion.agrupada.103": "ORE E MINUTI",
  "funcion.agrupada.104": "Cronografo",
  "funcion.agrupada.105": "Funzioni Intelligenti",
  "gamas.?": "",
  "gamas.agrupada.BE": "Bambino",
  "gamas.agrupada.C": "uomo",
  "gamas.agrupada.IN": "Bambini",
  "gamas.agrupada.NA": "Ragazza",
  "gamas.agrupada.NI": "Ragazzo",
  "gamas.agrupada.S": "donna",
  "gamas.agrupada.UN": "Unisex",
  "gamas.AN": "Anelli",
  "gamas.BE": "Bambino",
  "gamas.C": "uomo",
  "gamas.CD": "JUNIOR",
  "gamas.CO": "PENDENTE",
  "gamas.IN": "JUNIOR",
  "gamas.NA": "JUNIOR",
  "gamas.NI": "JUNIOR",
  "gamas.PE": "donna",
  "gamas.PU": "BRACCIALI",
  "joyas.tipo.agrupada.ANILLO": "SQUILLARE",
  "joyas.tipo.agrupada.BROCHE": "spilla",
  "joyas.tipo.agrupada.CIERRE": "Chiusura",
  "joyas.tipo.agrupada.COLGANTE": "PENDENTE",
  "joyas.tipo.agrupada.COLLAR": "COLLANA",
  "joyas.tipo.agrupada.CONJUNTO": "Impostato",
  "joyas.tipo.agrupada.GEMELOS": "Gemelli",
  "joyas.tipo.agrupada.PENDIENTE_CADENA": "PENDENZA A CATENA",
  "joyas.tipo.agrupada.PENDIENTE_TREPADOR": "PENDIO IN SALITA",
  "joyas.tipo.agrupada.PENDIENTES": "in attesa di",
  "joyas.tipo.agrupada.PIERCING": "Piercing",
  "joyas.tipo.agrupada.PULSERA": "braccialetto",
  "joyas.tipo.agrupada.TOBILLERA": "cavigliera",
  "joyas.tipo.ANILLO": "SQUILLARE",
  "joyas.tipo.BROCHE": "spilla",
  "joyas.tipo.COLGANTE": "PENDENTE",
  "joyas.tipo.COLLAR": "COLLANA",
  "joyas.tipo.CONJUNTO": "Impostato",
  "joyas.tipo.GEMELOS": "Gemelli",
  "joyas.tipo.PENDIENTE_CADENA": "PENDENZA A CATENA",
  "joyas.tipo.PENDIENTE_TREPADOR": "PENDIO IN SALITA",
  "joyas.tipo.PENDIENTES": "in attesa di",
  "joyas.tipo.PIERCING": "Piercing",
  "joyas.tipo.PULSERA": "braccialetto",
  "joyas.tipo.sub.1-1": "LISA",
  "joyas.tipo.sub.1-2": "CON LE PIETRE",
  "joyas.tipo.sub.1-3": "CON DIAMANTI",
  "joyas.tipo.sub.1-4": "LISA ALIANCE",
  "joyas.tipo.sub.1-5": "ALLEANZA DELLA LAPIDADA",
  "joyas.tipo.sub.1-6": "ALLEANZA CON LA PIETRA",
  "joyas.tipo.sub.1-7": "Francobollo",
  "joyas.tipo.sub.1-8": "STAMPA SMALTATA",
  "joyas.tipo.sub.1-9": "DIAMOND ALLIANCE",
  "joyas.tipo.sub.1-10": "stampa",
  "joyas.tipo.sub.1-11": "smalto",
  "joyas.tipo.sub.1-12": "bozza",
  "joyas.tipo.sub.1-13": "PIDRA CRISTAL",
  "joyas.tipo.sub.1-14": "SWAROVSKI",
  "joyas.tipo.sub.2-1": "liscio",
  "joyas.tipo.sub.2-2": "CON LE PIETRE",
  "joyas.tipo.sub.2-3": "SCAPOLARE",
  "joyas.tipo.sub.2-4": "CROCE LISCIA",
  "joyas.tipo.sub.2-5": "RAGAZZA VERGINE",
  "joyas.tipo.sub.2-6": "PERGAMENA / PIATTO",
  "joyas.tipo.sub.2-7": "Piccoli angeli",
  "joyas.tipo.sub.2-8": "CROCE CON LE PIETRE",
  "joyas.tipo.sub.2-9": "CROCE CON CRISTO",
  "joyas.tipo.sub.2-10": "FETICCIO",
  "joyas.tipo.sub.2-11": "CROCE CON DIAMANTI",
  "joyas.tipo.sub.2-12": "CON DIAMANTI",
  "joyas.tipo.sub.2-13": "FETISH CON LE PIETRE",
  "joyas.tipo.sub.2-14": "ELASTICO",
  "joyas.tipo.sub.2-15": "Vergine",
  "joyas.tipo.sub.2-16": "CRISTO",
  "joyas.tipo.sub.2-17": "CRUZ CARAVACA",
  "joyas.tipo.sub.2-18": "smalto",
  "joyas.tipo.sub.2-19": "COMUNIONE",
  "joyas.tipo.sub.2-20": "bozza",
  "joyas.tipo.sub.2-21": "CRUZ CALADA",
  "joyas.tipo.sub.3-1": "CHATON",
  "joyas.tipo.sub.3-2": "Zirconia",
  "joyas.tipo.sub.3-3": "COLORE PIETRA",
  "joyas.tipo.sub.3-4": "smalto",
  "joyas.tipo.sub.3-5": "ELETTROFORMATURA",
  "joyas.tipo.sub.3-6": "PERLE",
  "joyas.tipo.sub.3-7": "IO E TE",
  "joyas.tipo.sub.3-8": "SQUILLARE",
  "joyas.tipo.sub.3-9": "Palla",
  "joyas.tipo.sub.3-10": "VARIE",
  "joyas.tipo.sub.3-11": "Ceramica",
  "joyas.tipo.sub.3-12": "LUNGO CON LE PIETRE",
  "joyas.tipo.sub.3-13": "SQUILLARE",
  "joyas.tipo.sub.3-14": "smalto",
  "joyas.tipo.sub.3-15": "CON DIAMANTI",
  "joyas.tipo.sub.3-16": "ELASTICO",
  "joyas.tipo.sub.3-17": "CERCHIO DI PIETRA",
  "joyas.tipo.sub.3-18": "stampa",
  "joyas.tipo.sub.3-19": "bozza",
  "joyas.tipo.sub.4-1": "LISA",
  "joyas.tipo.sub.4-2": "INCISO",
  "joyas.tipo.sub.4-3": "Riccio",
  "joyas.tipo.sub.4-4": "RICCI CON PIETRE",
  "joyas.tipo.sub.4-5": "LISCIO CON LE PIETRE",
  "joyas.tipo.sub.4-6": "LISCIO CON DIAMANTI",
  "joyas.tipo.sub.4-7": "smalto",
  "joyas.tipo.sub.4-8": "CON LA PELLE",
  "joyas.tipo.sub.5-1": "1 ° POSIZIONE CON PIASTRA",
  "joyas.tipo.sub.5-2": "fine",
  "joyas.tipo.sub.5-3": "schifoso",
  "joyas.tipo.sub.5-4": "MASSELLO SPESSO CON STRATO",
  "joyas.tipo.sub.5-5": "SOLIDO SPESSO",
  "joyas.tipo.sub.5-6": "CAVO SPESSO CON PERLINE",
  "joyas.tipo.sub.5-7": "SPESSORE CAVO",
  "joyas.tipo.sub.5-8": "SPESSORE CON PIETRE",
  "joyas.tipo.sub.5-9": "RIVIERE",
  "joyas.tipo.sub.5-10": "SPESSORE CAVO CON STRATO",
  "joyas.tipo.sub.5-11": "FINE CAVA CON PIASTRA",
  "joyas.tipo.sub.5-12": "CON PIETRA",
  "joyas.tipo.sub.5-13": "PALLINE",
  "joyas.tipo.sub.5-14": "SFERA CON PIETRA",
  "joyas.tipo.sub.5-15": "1 ° POSIZIONE",
  "joyas.tipo.sub.5-16": "FINE HOLLOW",
  "joyas.tipo.sub.5-17": "FINE SOLID CON LASTRA",
  "joyas.tipo.sub.5-18": "ELASTICO",
  "joyas.tipo.sub.5-19": "CON PERLINE",
  "joyas.tipo.sub.5-20": "CINTURINO IN PELLE",
  "joyas.tipo.sub.5-21": "CON DIAMANTI",
  "joyas.tipo.sub.5-22": "CINTURINO IN PELLE DIAMANTE",
  "joyas.tipo.sub.5-23": "CON PERLE",
  "joyas.tipo.sub.5-24": "CINTURINO IN CAUCCIÙ",
  "joyas.tipo.sub.6-1": "FINE HOLLOW",
  "joyas.tipo.sub.6-2": "SPESSORE CAVO",
  "joyas.tipo.sub.6-3": "FINE SOLID",
  "joyas.tipo.sub.6-4": "SOLIDO SPESSO",
  "joyas.tipo.sub.6-5": "GOMMA CON CHIUSURA",
  "joyas.tipo.sub.6-6": "PELLE CON ARGENTO",
  "joyas.tipo.sub.6-7": "PELLE CON CHIUSURA IN ORO",
  "joyas.tipo.sub.6-8": "LAPIDADO CAVO",
  "joyas.tipo.sub.6-9": "PIASTRA DI CHIUSURA IN NYLON",
  "joyas.tipo.sub.7-1": "LAPIDADO CAVO",
  "joyas.tipo.sub.7-2": "FINE CON LA PIETRA",
  "joyas.tipo.sub.7-3": "FINA CON PENDENTE",
  "joyas.tipo.sub.7-4": "Anelli",
  "joyas.tipo.sub.7-5": "PENDENTE IN PIETRA FINE",
  "joyas.tipo.sub.7-6": "SPESSORE CON PENDENTE",
  "joyas.tipo.sub.7-7": "fine",
  "joyas.tipo.sub.7-8": "PALLINE",
  "joyas.tipo.sub.7-9": "FINA CON PENDENTE SMALTATO",
  "joyas.tipo.sub.7-10": "SPESSORE CON PIETRE",
  "joyas.tipo.sub.7-11": "CAVO DI SPESSORE",
  "joyas.tipo.sub.7-12": "FINE HOLLOW",
  "joyas.tipo.sub.7-13": "GOMMA CON PENDENTE",
  "joyas.tipo.sub.7-14": "PENDENTE FINA DIAMANTI",
  "joyas.tipo.sub.7-15": "ELASTICO",
  "joyas.tipo.sub.7-16": "SPESSORE CON DIAMANTI",
  "joyas.tipo.sub.7-17": "CORDA IN PELLE",
  "joyas.tipo.sub.7-18": "CORDA CON PENDENTE",
  "joyas.tipo.sub.7-19": "CON PERLINE",
  "joyas.tipo.sub.8-1": "1 ° POSIZIONE",
  "joyas.tipo.sub.8-2": "Cravatta",
  "joyas.tipo.sub.9-1": "FINE LISCIO",
  "joyas.tipo.sub.9-2": "SPESSORE LISCIO",
  "joyas.tipo.sub.9-3": "FINE CON LE PIETRE",
  "joyas.tipo.sub.9-4": "SPESSORE CON PIETRE",
  "joyas.tipo.sub.9-5": "FINE CON DIAMANTI",
  "joyas.tipo.sub.9-6": "SPESSORE CON DIAMANTI",
  "joyas.tipo.sub.9-7": "PALLINE",
  "joyas.tipo.sub.9-8": "RIVIERE",
  "joyas.tipo.sub.10-1": "liscio",
  "joyas.tipo.sub.10-2": "CON LE PIETRE",
  "joyas.tipo.sub.10-3": "PERLE",
  "joyas.tipo.sub.11-1": "liscio",
  "joyas.tipo.sub.11-2": "CON LE PIETRE",
  "joyas.tipo.sub.12-1": "CON DIAMANTI",
  "joyas.tipo.sub.12-2": "liscio",
  "joyas.tipo.sub.13-1": "liscio",
  "joyas.tipo.sub.13-2": "CON LE PIETRE",
  "joyas.tipo.sub.13-3": "CON DIAMANTI",
  "joyas.tipo.sub.14-1": "Pressione",
  "joyas.tipo.sub.14-2": "Filo",
  "joyas.tipo.sub.15-1": "CON PERLINE",
  "joyas.tipo.sub.17-1": "COPPIA BULLONI",
  "joyas.tipo.sub.25-1": "FACILE CLIC",
  "joyas.tipo.sub.99": "non definito",
  "joyas.tipo.sub.900-1": "GIALLO 9K",
  "joyas.tipo.sub.900-2": "BIANCO 9K",
  "joyas.tipo.sub.900-3": "BICOLORE 9K",
  "joyas.tipo.sub.undefined": "",
  "joyas.tipo.TOBILLERA": "cavigliera",
  "kilataje.ORO9K": "ORO 9K",
  "kilataje.ORO18K": "ORO 18K",
  "longpatillas.140": "140 mm",
  "longpatillas.142": "142 mm",
  "longpatillas.145": "145 mm",
  "material.9K": "375 ml di oro 9K",
  "material.18K": "ORO 18K",
  "material.24K": "Oro puro",
  "material.?": "?",
  "material.AC": "Acciaio",
  "material.AC-EB": "ACCIAIO-EBANE",
  "material.ACAC": "ACCIAIO E ACCIAIO",
  "material.ACBI": "ACCIAIO BICOLORE",
  "material.ACBR": "ACCIAIO LUCIDO",
  "material.ACCA": "ACCIAIO E GOMMA",
  "material.ACCC": "ZIRCONIA IN ACCIAIO",
  "material.ACCE": "ACCIAIO CERAMICO",
  "material.ACCH": "ACCIAIO PLACCATO",
  "material.ACDI": "DIAMANTI IN ACCIAIO",
  "material.ACE": "Acciaio inossidabile 316l",
  "material.ACERO": "Acciaio inossidabile 316l",
  "material.ACETA": "Acetato",
  "material.ACFC": "FIBRA DI CARBONIO",
  "material.ACHB": "ACCIAIO BICOLORE",
  "material.ACHO": "ACCIAIO PLACCATO ORO",
  "material.ACMA": "ACCIAIO E LEGNO",
  "material.ACOR": "ACCIAIO E ORO",
  "material.ACPI": "ACCIAIO E PELLE",
  "material.ACPL": "ACCIAIO E PLASTICA",
  "material.ACTI": "ACCIAIO TITANIO",
  "material.AGCI": "ARGENTO-ZIRCONIA",
  "material.AGES": "ARGENTO SMALTATO",
  "material.AGG": "Argento",
  "material.AGPE": "ARGENTO E PERLE",
  "material.agrupado.ACERO": "Acciaio",
  "material.agrupado.ACETA": "ACETATO",
  "material.agrupado.CAUCHO": "Gomma",
  "material.agrupado.CERAMICA": "Ceramica",
  "material.agrupado.CUERO": "Pelle",
  "material.agrupado.GOMA": "Gomma",
  "material.agrupado.METAL": "METALLO",
  "material.agrupado.ORO": "oro",
  "material.agrupado.ORO9K": "GOLD9K",
  "material.agrupado.ORO18K": "GOLD18K",
  "material.agrupado.PLATA": "Argento",
  "material.agrupado.TITANIO": "TITANIO",
  "material.AGS": "SWAROVSKI ARGENTO",
  "material.ALG": "Cotone",
  "material.ALUM": "Alluminio",
  "material.ALZ": "Lega di zinco",
  "material.ANTE": "PRIMA",
  "material.BELC": "BELCRO",
  "material.BICO": "BICOLORE",
  "material.BRO": "Bronzo",
  "material.C18K": "CHIUSURA IN GOMMA + ORO",
  "material.CAAC": "ACCIAIO IN GOMMA",
  "material.CAU": "Gomma",
  "material.CAUCHO": "Gomma",
  "material.CEBR": "CERAMICA-BRILLANTE",
  "material.CER": "Ceramica",
  "material.CERAMICA": "Ceramica",
  "material.CHA": "Chapada",
  "material.CHAP": "PLACCATO",
  "material.CHBI": "PLACCATO BICOLORE",
  "material.CHOR": "PLACCATO ORO",
  "material.COCO": "PELLE DI COCCODRILLO",
  "material.COCT": "COCO CUT",
  "material.CRBI": "CROMATO BICOLORE",
  "material.CRMO": "Cromo",
  "material.CRO": "CROMATO",
  "material.CU": "corda",
  "material.CUERO": "Pelle",
  "material.FIB": "Fibra",
  "material.GO": "Gomma",
  "material.GOCA": "GOMMA GOMMA",
  "material.GOM": "Gomma",
  "material.HNAU": "FILO NAUTICO",
  "material.LACH": "OTTONE PLACCATO",
  "material.LACR": "OTTONE CROMATO",
  "material.LACT": "TAGLIARE LA LUCERTOLA",
  "material.LAG": "LUCERTOLA",
  "material.LAT": "Ottone",
  "material.LCHB": "OTTONE BICOLORE",
  "material.LIQ": "liquido",
  "material.METAL": "METALLO",
  "material.MIL": "LENTE MINERALE",
  "material.MIN": "MINERALE",
  "material.MINP": "MINERALE PIATTO",
  "material.MINT": "MINERALE COLORATO",
  "material.NEO": "NEOPRENE",
  "material.NOB": "NOBUCK",
  "material.NY": "NYLON",
  "material.NYBE": "BELCRO IN NYLON",
  "material.NYCA": "GOMMA DI NYLON",
  "material.NYCT": "TAGLIO IN NYLON",
  "material.NYL": "NYLON",
  "material.NYPC": "TOMAIA IN NYLON-PELLE",
  "material.NYPI": "BASE IN NYLON PELLE",
  "material.O18": "ORO 18K",
  "material.OASP": "ORO 18K SENZA PREZZO",
  "material.OB5N": "ORO BIANCO + ORO 5N",
  "material.OBSP": "ORO BIANCO SENZA PREZZO",
  "material.OR18": "ORO 18 KTES.",
  "material.OR24": "ORO 24 KTES.",
  "material.ORO": "Oro",
  "material.OROB": "Oro bianco 18 carati",
  "material.P+O": "CHIUSURA IN PELLE + ORO",
  "material.P-CC": "PELLE C / TAGLIO (7.5X4.2)",
  "material.PABA": "PAVE BALL",
  "material.PALD": "Palladio",
  "material.PAST": "PASTA",
  "material.PC": "PLASTICA + FIBRA DI VETRO",
  "material.PEL": "INGRANDIMENTO PLEX",
  "material.PI": "Pelle",
  "material.PI-C": "PELLE C / TAGLIO (7,5X3,8)",
  "material.PICA": "PELLE GOMMA",
  "material.PICH": "PELLE VERNICE",
  "material.PICT": "TAGLIO IN PELLE",
  "material.PIEL": "Pelle",
  "material.PIEL-PU": "PELLE-POLIURETANO",
  "material.PIGR": "PELLE INCISO",
  "material.PILI": "PELLE LISCIA",
  "material.PINA": "PELLE DI NAYLON",
  "material.PINY": "PELLE DI NYLON",
  "material.PIPL": "PELLE PLASTIFICATA",
  "material.PIRA": "PELLE A RIGHE",
  "material.PISI": "PELLE SINTETICA",
  "material.PISIN": "PELLE SINTETICA",
  "material.PL": "PLEX",
  "material.PLA": "Plastica",
  "material.PLAA": "ANELLO IN PLASTICA",
  "material.PLAL": "LENTE IN PLASTICA",
  "material.PLAS": "Plastica",
  "material.PLAT": "Argento 925 ml",
  "material.PLATA": "Argento",
  "material.PLEX": "PLASTICA PLEX",
  "material.PLNY": "NYLON PLASTICO",
  "material.PLPI": "BASE IN PELLE PLASTICA",
  "material.PLPL": "PLPL",
  "material.PLXL": "PLAST + MAGNIFIER",
  "material.POLICARBONATO": "POLICARBONATO",
  "material.PU": "POLIURETANO",
  "material.PUPI": "BASE IN PELLE PURPURINE",
  "material.PVC": "PVC",
  "material.RAYA": "PELLE A RIGHE",
  "material.RH": "rodio",
  "material.RU": "RUTENIO",
  "material.SERP": "Serpente",
  "material.SPBP": "BASE IN PELLE SINTETICA",
  "material.STBP": "BASE IN PELLE SINTETICA",
  "material.SWA": "SWAROVSKI",
  "material.TELA": "stoffa",
  "material.TEPI": "TESSUTO BASE IN PELLE",
  "material.TIBI": "TITANIO BICOLORE",
  "material.TIBU": "PELLE DI SQUALO",
  "material.TIT": "TITANIO",
  "material.TITANIO": "Titanio",
  "material.TORT": "PELLE DI TARTARUGA",
  "material.TUNG": "TUNGSTENO",
  "material.undefined": "",
  "material.Z+L": "ZAFFIRO + LENTE D'ingrandimento",
  "material.Z-G": "ZAF + MAGNIFIER + INCISIONE",
  "material.ZAF": "Zaffiro",
  "material.ZAFP": "ZAFFIRO VERNICIATO",
  "movimiento.funcion.1": "SEMPLICE (CAL)",
  "movimiento.funcion.4": "CHRONO (NO CAL)",
  "movimiento.funcion.12": "PRENOTA-PARTENZA",
  "movimiento.funcion.101": "Digitale",
  "movimiento.funcion.102": "Multifunzione",
  "movimiento.funcion.103": "QUARZO",
  "movimiento.funcion.104": "Cronografo",
  "movimiento.funcion.105": "Digitale",
  "movimiento.funcion.106": "Digitale",
  "movimiento.funcion.107": "ALLARME ANALOGICO",
  "movimiento.funcion.108": "Multifunzione",
  "movimiento.funcion.109": "DOPPIO TEMPO",
  "movimiento.funcion.110": "automatico",
  "movimiento.funcion.111": "Tourbillon",
  "movimiento.funcion.112": "GMT",
  "movimiento.funcion.113": "PRENOTA-PARTENZA",
  "movimiento.funcion.agrupada.ANALO": "Analogico",
  "movimiento.funcion.agrupada.AUTO": "Automaticos",
  "movimiento.funcion.agrupada.CRONO": "Cronografo",
  "movimiento.funcion.agrupada.DIGI": "Digitale",
  "movimiento.funcion.agrupada.HYBRID": "ibridi",
  "movimiento.funcion.agrupada.MULTI": "Multifunzione",
  "movimiento.funcion.agrupada.SMART": "smartwatch",
  "movimiento.funcion.ANA-AL": "ALLARME ANALOGICO",
  "movimiento.funcion.ANA-DI": "ANALOGICO DIGITALE",
  "movimiento.funcion.ANA-DIGI": "ANALOGICO DIGITALE",
  "movimiento.funcion.AUT-AL": "ALLARME AUTOMATICO",
  "movimiento.funcion.AUTO": "automaticos",
  "movimiento.funcion.CRO-SUI": "CRONOGRAFO SVIZZERO",
  "movimiento.funcion.CRO.SOL": "CRONO SOLARE",
  "movimiento.funcion.CRONO": "Cronografo",
  "movimiento.funcion.CRONO-AL": "CRONO-SVEGLIA",
  "movimiento.funcion.DI-MULTI": "MULTIFUCIONE DIGITALE",
  "movimiento.funcion.DIGI": "Digitale",
  "movimiento.funcion.DUAL-TIM": "DOPPIO TEMPO",
  "movimiento.funcion.HYBRID": "ibridi",
  "movimiento.funcion.MULTI": "Multifunzione",
  "movimiento.funcion.QUTZ-ANA": "QUARZO / ANALOGICO",
  "movimiento.funcion.SI": "Semplice",
  "movimiento.funcion.SIM": "Semplice",
  "movimiento.funcion.SIM-SUI": "SVIZZERO SEMPLICE",
  "movimiento.funcion.SMART": "OROLOGIO INTELLIGENTE",
  "movimiento.tipo.01": "Digitale",
  "movimiento.tipo.1": "SEMPLICE (CAL)",
  "movimiento.tipo.02": "ANALOGICO DIGITALE",
  "movimiento.tipo.03": "QUARZO / ANALOGICO",
  "movimiento.tipo.04": "automatico",
  "movimiento.tipo.05": "HIBRID",
  "movimiento.tipo.06": "OROLOGIO INTELLIGENTE",
  "movimiento.tipo.07": "Solare",
  "movimiento.tipo.7": "MULTIF (CAL)",
  "movimiento.tipo.12": "PRENOTA-PARTENZA",
  "movimiento.tipo.ANA-AL": "ALLARME ANALOGICO",
  "movimiento.tipo.ANA-DI": "ANALOGICO DIGITALE",
  "movimiento.tipo.ANA-DIGI": "ANALOGICO DIGITALE",
  "movimiento.tipo.AUT-AL": "ALLARME AUTOMATICO",
  "movimiento.tipo.AUTO": "automatico",
  "movimiento.tipo.AUTO-CRO": "CRONOGRAFO AUTOMATICO",
  "movimiento.tipo.AUTOM": "automatico",
  "movimiento.tipo.C": "corda",
  "movimiento.tipo.CRO-SUI": "CRONOGRAFO SVIZZERO",
  "movimiento.tipo.CRONO": "Cronografo",
  "movimiento.tipo.CUER-MEC": "CORDA / MECCANICA",
  "movimiento.tipo.CUER.": "corda",
  "movimiento.tipo.D": "Digitale",
  "movimiento.tipo.DI": "Digitale",
  "movimiento.tipo.DIGI": "Digitale",
  "movimiento.tipo.DUAL-TIM": "DOPPIO TEMPO",
  "movimiento.tipo.F": "Solare",
  "movimiento.tipo.HIBRID": "HIBRID",
  "movimiento.tipo.ME.CRO.": "MECA.CRONO",
  "movimiento.tipo.ME.SIM.": "SEMPLICE MECA",
  "movimiento.tipo.MULTI": "Multifunzione",
  "movimiento.tipo.MULTI.CR": "MULTI.CHRONO",
  "movimiento.tipo.PROF.": "PROFONDIMETRO",
  "movimiento.tipo.QUTZ-ANA": "QUARZO / ANALOGICO",
  "movimiento.tipo.SIM": "Semplice",
  "movimiento.tipo.SIM-SUI": "SVIZZERO SEMPLICE",
  "movimiento.tipo.SMART": "OROLOGIO INTELLIGENTE",
  "movimiento.tipo.TOURBILLON": "TOURBILLON",
  "numeraciones.1A+AP": "1AR.AP + AR.PI",
  "numeraciones.1A+CC": "1AR.AP + CRIRC",
  "numeraciones.1A+IA": "1AR.AP + IN.AP",
  "numeraciones.1A+IP": "1AR.AP + IN.PI",
  "numeraciones.1A.AP": "1AR.AP",
  "numeraciones.1A.PI": "1AR.PI",
  "numeraciones.1AA": "1AR.AP",
  "numeraciones.1AP+I": "1AR.PI + IN.AP",
  "numeraciones.1AP+P": "1AR.PI + IN.PI",
  "numeraciones.1BR+I": "1BRILL + IN.AP",
  "numeraciones.1CC": "1CIRC",
  "numeraciones.1CC+I": "1CIRCO + IN.AP",
  "numeraciones.1CC+IA": "1CIRC + IN.AP",
  "numeraciones.1CIR+I": "1CIRCO + IN.AP.",
  "numeraciones.1D+IN": "1DIAM + IND APLI",
  "numeraciones.1I.AP": "1IN.AP",
  "numeraciones.1IA": "1IN.AP",
  "numeraciones.1IP": "1IN.PI",
  "numeraciones.1R+IA": "1RO.AP + IN.AP",
  "numeraciones.1R+IP": "1RO.AP + IN.PI",
  "numeraciones.1R+RP": "1RO.AP + RO.PI",
  "numeraciones.1R.AP": "1RO.AP",
  "numeraciones.1R.P": "1RO.PI",
  "numeraciones.1R.PI": "1RO.PI",
  "numeraciones.1RA": "1RO.AP.",
  "numeraciones.1RA+P": "1R.APL + RO.PIN.",
  "numeraciones.1RP+I": "1RO.PI + IN.AP",
  "numeraciones.1RP+P": "1RO.PI + IN.PI",
  "numeraciones.2A+AP": "2AR.AP + AR.PI",
  "numeraciones.2A+CC": "2AR.AP + CIRC",
  "numeraciones.2A+IA": "2AR.AP + IN.AP",
  "numeraciones.2A+IP": "2AR.AP + IN.PI",
  "numeraciones.2A.AP": "2AR.AP",
  "numeraciones.2A.PI": "2AR.PI",
  "numeraciones.2AA+2RA": "2AR.AP + 2RO.AP",
  "numeraciones.2AP+2RP": "2AR.PI + 2RO.PI",
  "numeraciones.2AP+ARP": "2AR.PI + 2RO.PI",
  "numeraciones.2AP+I": "2AR.PI + IN.AP",
  "numeraciones.2AP+P": "2AR.PI + IN.PI",
  "numeraciones.2CC": "2CIRC.",
  "numeraciones.2CC+I": "2CIRC + IN.AP",
  "numeraciones.2CC+IA": "2CIRC + IN.AP",
  "numeraciones.2I+RP": "2IN.AP + 10RO.PI",
  "numeraciones.2I.AP": "2IN.AP",
  "numeraciones.2IA": "2IN.AP",
  "numeraciones.2IA+P": "2IN.AP + IN.PI.",
  "numeraciones.2IP": "2IN.PI",
  "numeraciones.2R+CC": "2RO.AP + CIRC",
  "numeraciones.2R+IA": "2RO.AP + IN.AP",
  "numeraciones.2R+IP": "2RO.AP + IN.PI",
  "numeraciones.2R+RP": "2RO.AP + RO.PI",
  "numeraciones.2R.AP": "2RO.AP",
  "numeraciones.2R.P": "2RO.PI.",
  "numeraciones.2R.PI": "2RO.PI",
  "numeraciones.2RA": "2ROM.APL.",
  "numeraciones.2RP+C": "2RO.PI + CIRC",
  "numeraciones.2RP+I": "2RO.PI + IN.AP",
  "numeraciones.2RP+P": "2RO.PI + IN.PI",
  "numeraciones.3A+AP": "3AR.AP + AR.PI",
  "numeraciones.3A+IA": "3AR.AP + IN.AP",
  "numeraciones.3A+IP": "3AR.AP + IN.PI",
  "numeraciones.3A.AP": "3AR.AP",
  "numeraciones.3A.PI": "3AR.PI",
  "numeraciones.3AA+P": "3ARA.A + 8ARA.PIN.",
  "numeraciones.3AP+I": "3AR.PI + IN.AP.",
  "numeraciones.3AP+P": "3AR.PI + IN.PI",
  "numeraciones.3CC": "3CIRC.",
  "numeraciones.3CC+IA": "3CIRC + IN.AP",
  "numeraciones.3I+RP": "3IN.AP + RO.PI",
  "numeraciones.3I.AP": "3IN.AP",
  "numeraciones.3IA": "3IN.AP",
  "numeraciones.3IP": "3IN.PI",
  "numeraciones.3R+CC": "3ROM + ZIRCONI",
  "numeraciones.3R+IA": "3RO.AP + IN.AP",
  "numeraciones.3R+IP": "3RO.AP + IN.PI",
  "numeraciones.3R+IR": "3/4 ROMANI + IND.APL",
  "numeraciones.3R+RP": "3RO.AP + RO.PI",
  "numeraciones.3R.AP": "3RO.AP",
  "numeraciones.3R.P": "3RO.PI.",
  "numeraciones.3R.PI": "3RO.PI",
  "numeraciones.3RA+CC": "3RA.AP + CC",
  "numeraciones.3RA+P": "3RO.AP + 8RO.PI.",
  "numeraciones.3RE+E": "3ROM.EST + IND.ESTAMP.",
  "numeraciones.3RP+I": "3RO.PI + IN.AP",
  "numeraciones.3RP+P": "3RO.PI + IN.PI",
  "numeraciones.4A+4A": "4AR.PI + 4A.PI",
  "numeraciones.4A+4AP": "4AR.AP + 4AR.P",
  "numeraciones.4A+4R": "4AR + IA / 4R + IA.",
  "numeraciones.4A+AP": "4AR.AP + AR.PI",
  "numeraciones.4A+CC": "4ARA.AP + CIRC",
  "numeraciones.4A+IA": "4AR.AP + IN.AP",
  "numeraciones.4A+IP": "4AR.AP + IN.PI",
  "numeraciones.4A+P": "4ARP + PIN IND.",
  "numeraciones.4A.AP": "4AR.AP",
  "numeraciones.4A.PI": "4AR.PI",
  "numeraciones.4AA+6": "4AR.A + 6AR.P",
  "numeraciones.4AA+P": "4ARA.A + 8ARA.PIN.",
  "numeraciones.4AP+I": "4AR.PI + IN.AP",
  "numeraciones.4AP+P": "4AR.PI + IN.PI",
  "numeraciones.4AR.P": "4ARA.PINT + IND.PIN.",
  "numeraciones.4CC": "4CIRC.",
  "numeraciones.4DIA": "4 DIAMANTI",
  "numeraciones.4I+CC": "4IN.AP + CIRC",
  "numeraciones.4IA": "4IN.AP",
  "numeraciones.4IA+P": "4IN.AP + IN.PI",
  "numeraciones.4IN.A": "4 INDICI APPLICATI",
  "numeraciones.4INDS": "4 INDICI",
  "numeraciones.4IP": "4IN.PI",
  "numeraciones.4R+CC": "4 ROM. + CIRC.",
  "numeraciones.4R+DI": "/4ROM.APLIC.+8DIAM",
  "numeraciones.4R+IA": "4RO.AP + IN.AP",
  "numeraciones.4R+IP": "4RO.AP + IN.PI",
  "numeraciones.4R+RP": "4RO.AP + RO.PI",
  "numeraciones.4R.AP": "4RO.AP",
  "numeraciones.4R.P": "4RO.PI.",
  "numeraciones.4R.PI": "4RO.PI",
  "numeraciones.4RA+I": "4 / ROMAN.APL. + IND.APL",
  "numeraciones.4RA+P": "4RO.AP + 8RO.PI",
  "numeraciones.4RE+E": "4RO.EST + IN.EST.",
  "numeraciones.4ROM": "4 ROMANI",
  "numeraciones.4RP+I": "4RO.PI + IN.AP",
  "numeraciones.4RP+P": "4RO.PI + IN.PI",
  "numeraciones.5A+5CC": "5AR.AP + 5CIRC",
  "numeraciones.5A+AP": "5AR.AP + AR.PI",
  "numeraciones.5A+IA": "5AR.AP + IN.AP",
  "numeraciones.5A+IP": "5AR.AP + IN.PI",
  "numeraciones.5A.AP": "5AR.AP",
  "numeraciones.5A.PI": "5AR.PI",
  "numeraciones.5AP+I": "5AR.PI + IN.AP",
  "numeraciones.5AP+P": "5AR.PI + IN.PI",
  "numeraciones.5CC": "5CIRC.",
  "numeraciones.5D+IA": "5 DIAM + IND. APLI",
  "numeraciones.5DIAM": "5 DIAMANTI",
  "numeraciones.5IA": "5IN.AP",
  "numeraciones.5IP": "5IN.PI",
  "numeraciones.5R+IA": "5RO.AP + IN.AP",
  "numeraciones.5R+IP": "5RO.AP + IN.PI",
  "numeraciones.5R+RP": "5RO.AP + RO.PI",
  "numeraciones.5R.AP": "5RO.AP",
  "numeraciones.5R.PI": "5RO.PI",
  "numeraciones.5RP+I": "5RO.PI + IN.AP",
  "numeraciones.5RP+P": "5RO.PI + IN.PI",
  "numeraciones.6A+AP": "6AR.AP + AR.PI",
  "numeraciones.6A+CC": "6AR.AP + CIRC.",
  "numeraciones.6A+IA": "6AR.AP + IN.AP",
  "numeraciones.6A+IP": "6AR.AP + IN.PI",
  "numeraciones.6A.AP": "6AR.AP",
  "numeraciones.6A.PI": "6AR.PI",
  "numeraciones.6AP+I": "6AR.PI + IN.AP",
  "numeraciones.6AP+P": "6AR.PI + IN.PI.",
  "numeraciones.6CC.": "6CIRC.",
  "numeraciones.6IA": "6IN.AP",
  "numeraciones.6IP": "6IN.PI",
  "numeraciones.6R+IA": "6RO.AP + IN.AP",
  "numeraciones.6R+IP": "6RO.AP + IN.PI",
  "numeraciones.6R+RP": "6RO.AP + RO.PI",
  "numeraciones.6R.AP": "6RO.AP",
  "numeraciones.6R.P": "6RO.PIN.",
  "numeraciones.6RP+I": "6RO.PI + IN.AP",
  "numeraciones.6RP+P": "6RO.PI + IN.PI",
  "numeraciones.7A+IA": "7AR.AP + IN.AP",
  "numeraciones.7AR.P": "7AR.P",
  "numeraciones.7DIA": "7 DIAMANTI",
  "numeraciones.8A+IA": "8AR.AP + IN.AP",
  "numeraciones.8AP": "8ARA.PIN.",
  "numeraciones.8AP+P": "8ARA.PIN + IND.PIN.",
  "numeraciones.8R+IA": "8RO.AP + IN.AP",
  "numeraciones.8RP+P": "8RO.PI + IN.PI.",
  "numeraciones.9ARAB": "9 ARABI",
  "numeraciones.10ARA": "10 ARABI",
  "numeraciones.A+R.P": "ARA + ROM.PIN.",
  "numeraciones.AA+AP": "ARA PINT + ARA APLIC",
  "numeraciones.AP+AA": "ARA PINT + ARA APLIC",
  "numeraciones.AP+IA": "ARA.PIN + IND.A",
  "numeraciones.AP+IP": "AR.PI + IN.PI",
  "numeraciones.AR+DI": "RA. + 10 DIAMANTI",
  "numeraciones.AR.AP": "ARABI APPLICATI",
  "numeraciones.AR.PI": "ARABI DIPINTI",
  "numeraciones.AR4/4": "ARABI 4/4 + INDICI",
  "numeraciones.ARA.A": "ARABI APPLICATI",
  "numeraciones.ARA.E": "ARABI STAMPATI",
  "numeraciones.ARA.P": "ARABI DIPINTI",
  "numeraciones.ARAB": "ARABI",
  "numeraciones.ARABE": "ARABI 12/12",
  "numeraciones.BAND": "bandiere",
  "numeraciones.BOLAS": "PALLINE 2N18",
  "numeraciones.CIRC": "Zirconia",
  "numeraciones.CR": "CRISTALLO SWAROVSKI",
  "numeraciones.DIAM": "12 DIAMANTI",
  "numeraciones.DIAMG": "GRANDI DIAMANTI",
  "numeraciones.DIAN": "ANALOGICO DIGITALE",
  "numeraciones.DIAR": "ARABI DIGITALI",
  "numeraciones.DIG": "Digitale",
  "numeraciones.DIIN": "INDICI DIGITALI",
  "numeraciones.FANT": "Fantasia",
  "numeraciones.FL": "FIORI",
  "numeraciones.IN+CC": "IND + ZIRCONI",
  "numeraciones.IN.AP": "INDICI APPLICATI",
  "numeraciones.IN.PI": "INDICI DIPINTI",
  "numeraciones.INAR": "12INDIC + 12ARAB",
  "numeraciones.IND.A": "INDICI APPLICATI",
  "numeraciones.IND.E": "IND.EST.",
  "numeraciones.IND.P": "INDUSTRIE VERNICIATE",
  "numeraciones.INDIC": "Indici",
  "numeraciones.INFAN": "INDICI DI FANTASIA",
  "numeraciones.INPIN": "IND.PIN.",
  "numeraciones.INRO": "12 INDICI + 12 ROMANI",
  "numeraciones.IP+AP": "12IN.PI + 12AR.PI",
  "numeraciones.NEO": "NON TRASPORTA",
  "numeraciones.RA+RP": "4ROM APLI + 4ROM PIN",
  "numeraciones.RO+IN": "ROMANI 12 + 10 INDICE",
  "numeraciones.RO.AP": "ROMANI APPLICATI",
  "numeraciones.RO.PI": "ROMANI DIPINTI",
  "numeraciones.RO2/2": "ROMANI 2/2 + INDICE",
  "numeraciones.RO4/4": "ROMANI 4/4 + INDICI",
  "numeraciones.ROES": "ROM.STAMPINGS",
  "numeraciones.ROM": "ROMANI",
  "numeraciones.ROM.A": "APPLICAZIONI ROMANE",
  "numeraciones.ROM.E": "I ROMANI TIMBRATI",
  "numeraciones.ROM.P": "ROMANI DIPINTI",
  "numeraciones.ROMAN": "12/12 ROMANI",
  "numeraciones.RP+IA": "12ROM.PIN + 12IND.A.",
  "numeraciones.RP+IP": "12RO.PI + 12IN.PI",
  "origen.1": "Svizzera",
  "origen.2": "Giappone",
  "piedras.tipo.agrupada.ABA": "PERLINE DIVERSE FORME",
  "piedras.tipo.agrupada.ACBA": "PALLA D'ACCIAIO",
  "piedras.tipo.agrupada.AGA": "AGATA",
  "piedras.tipo.agrupada.AGBA": "SFERA D'ARGENTO",
  "piedras.tipo.agrupada.AMA": "Ametista",
  "piedras.tipo.agrupada.AMAN": "AMANZONITE",
  "piedras.tipo.agrupada.ARCI": "ANELLO IN ARGENTO CON ZIRCONI",
  "piedras.tipo.agrupada.ARGO": "SQUILLARE",
  "piedras.tipo.agrupada.AVEN": "AVVENTURINA",
  "piedras.tipo.agrupada.BAEN": "BAR SET ZIRCONIA",
  "piedras.tipo.agrupada.BAPA": "PAVE BAR",
  "piedras.tipo.agrupada.BGSP": "BORSE SENZA PREZZO",
  "piedras.tipo.agrupada.BOL": "SFERA ARGENTO SFACCETTATA",
  "piedras.tipo.agrupada.BRI": "SCINTILLANTE",
  "piedras.tipo.agrupada.BS": "BS",
  "piedras.tipo.agrupada.BUTT": "BOTTONE PERLA COLTIVATA",
  "piedras.tipo.agrupada.CALC": "CALCEDONIO",
  "piedras.tipo.agrupada.CER": "Ceramica",
  "piedras.tipo.agrupada.CIR": "ZIRCONIA GIALLA (CZ-YE1)",
  "piedras.tipo.agrupada.CIRC": "SET ZIRCONIA",
  "piedras.tipo.agrupada.COR": "CORNIOLA",
  "piedras.tipo.agrupada.CRBA": "PALLA DI CRISTALLO",
  "piedras.tipo.agrupada.CRI": "Cristallo",
  "piedras.tipo.agrupada.CRPA": "CRUZ PAVE",
  "piedras.tipo.agrupada.CUAR": "QUARZO",
  "piedras.tipo.agrupada.DIAM": "Diamante",
  "piedras.tipo.agrupada.DISP": "0",
  "piedras.tipo.agrupada.ESMA": "smalto",
  "piedras.tipo.agrupada.ESP": "spinello",
  "piedras.tipo.agrupada.FERI": "vacanza",
  "piedras.tipo.agrupada.G030": "FO1030",
  "piedras.tipo.agrupada.G031": "FO1031",
  "piedras.tipo.agrupada.G034": "BRILLANTE",
  "piedras.tipo.agrupada.G036": "FO1036",
  "piedras.tipo.agrupada.G050": "FO1050",
  "piedras.tipo.agrupada.GI10": "PIETRA 0.90",
  "piedras.tipo.agrupada.GI11": "38640",
  "piedras.tipo.agrupada.GI12": "MOD FO282",
  "piedras.tipo.agrupada.GI13": "LUNETTA CRIMPATA CON ONDA D'URTO",
  "piedras.tipo.agrupada.GI14": "PIETRE 1,6DIAM",
  "piedras.tipo.agrupada.GI15": "PIETRA 0.80",
  "piedras.tipo.agrupada.GIL": "DIAM ENGAS MIGUEL GIL 0,015K",
  "piedras.tipo.agrupada.GIL1": "PIETRE 1.20 + 1.50",
  "piedras.tipo.agrupada.GIL2": "FO436",
  "piedras.tipo.agrupada.GIL3": "DIAMANTE 1.10",
  "piedras.tipo.agrupada.GIL4": "MODELLO IN PIETRA LO314",
  "piedras.tipo.agrupada.GIL5": "PIETRE MODELLO FO323",
  "piedras.tipo.agrupada.GIL6": "PIETRE DI 1.20",
  "piedras.tipo.agrupada.GIL7": "PIETRE 1.40",
  "piedras.tipo.agrupada.GIL8": "PIETRE 1.30",
  "piedras.tipo.agrupada.GIL9": "PIETRA 1.00",
  "piedras.tipo.agrupada.GLAM": "GLAM",
  "piedras.tipo.agrupada.HEMA": "EMATITE",
  "piedras.tipo.agrupada.JADE": "GIADA",
  "piedras.tipo.agrupada.LABR": "LABRADORITE",
  "piedras.tipo.agrupada.LAPI": "LAPISLAZZULI",
  "piedras.tipo.agrupada.LAVA": "SFERA DI LAVA",
  "piedras.tipo.agrupada.MP": "MADREPERLA",
  "piedras.tipo.agrupada.NAC": "MADREPERLA",
  "piedras.tipo.agrupada.OJGA": "OCCHIO DI GATTO",
  "piedras.tipo.agrupada.ONI": "ONICE",
  "piedras.tipo.agrupada.OPPE": "OPALE PERUVIANO",
  "piedras.tipo.agrupada.OTIG": "OCCHIO DI TIGRE (GRANATO)",
  "piedras.tipo.agrupada.OTUR": "OCCHIO TURCO",
  "piedras.tipo.agrupada.PABA": "PAVE BALL",
  "piedras.tipo.agrupada.PEBA": "PERLA BAROCCA COLTIVATA",
  "piedras.tipo.agrupada.PECU": "PERLA COLTIVATA",
  "piedras.tipo.agrupada.PERL": "PERLA NATURALE",
  "piedras.tipo.agrupada.PESY": "PERLA SINTETICA",
  "piedras.tipo.agrupada.PILU": "PIETRA DELLA LUNA",
  "piedras.tipo.agrupada.QURS": "QUARZO ROSA",
  "piedras.tipo.agrupada.RES": "SFERA IN RESINA",
  "piedras.tipo.agrupada.RESI": "Resina",
  "piedras.tipo.agrupada.RORU": "RODIO RUTENIO",
  "piedras.tipo.agrupada.RUB": "Rubino",
  "piedras.tipo.agrupada.SWA": "SWAROVSKI",
  "piedras.tipo.agrupada.SZA1": "PIETRA 1.50",
  "piedras.tipo.agrupada.SZA2": "PIETRA 1.20",
  "piedras.tipo.agrupada.TUCI": "ZIRCONIA TUBE",
  "piedras.tipo.agrupada.TUR": "TURCHESE",
  "producto.coleccion": "Collezione",
  "producto.tipo.accesorio": "accessorio",
  "producto.tipo.joya": "Gioiello",
  "producto.tipo.reloj": "Orologio",
  "tipo.movimiento.agrupado.01": "Digitale",
  "tipo.movimiento.agrupado.02": "QUARZO DIGITALE",
  "tipo.movimiento.agrupado.03": "Manuale",
  "tipo.movimiento.agrupado.04": "Automatico",
  "tipo.movimiento.agrupado.05": "ibrido",
  "tipo.movimiento.agrupado.06": "SMARTWATCH",
  "tipo.movimiento.agrupado.07": "QUARZO",
  "tipolente.POLARIZED": "Polarizzato"
}
