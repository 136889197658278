import { APPEnv, CMSEnv, IAppConfig, Language, ShopCode } from '@fgp/shared/interfaces';

/* eslint-disable @typescript-eslint/no-var-requires */
export const environment: IAppConfig = {
  version: require('../../../../package.json').version,
  appVersion: require('../../../../package.json').version + '-prod',
  appEnv: APPEnv.PROD,
  production: true,
  apiPublicUrl: 'https://fcloud.festina.com/public/dmz/services/',
  JWTDomainPublic: 'fcloud.festina.com',
  tiendaCode: ShopCode.Kronaby,
  //Poner el de kronaby
  tiendaCodigoAES: '60fbc7237effe2ee6f5a53c6c9f461ee',
  tiendaNombre: 'kronaby.com',
  appName: 'kronaby',
  domain: 'https://kronaby.com',
  cms: {
    local: false,
    endpoint: 'https://cms-swiss.festinagroup.com',
    bucket: 'bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3',
    preview: false,
    env: CMSEnv.prod
  },
  imageConversor: {
    url: 'https://imageconv.festinagroup.com/convert?code=',
    basePath: 'festina-swiss-cms'
  },
  translateDomain: 'https://festina.com',
  imgServerUrl: 'https://static6.festinagroup.com/product/',
  urlServidorEstatico: 'https://media6.festinagroup.com/static/tiendas_online/',
  imgServerUrlLogoTienda: 'https://media6.festinagroup.com/static/tiendas_online/logos/',
  imgServerUrlHomeImages: 'https://media6.festinagroup.com/static/tiendas_online/kronaby/images/home',
  imgServerUrlMenuImages: 'https://media6.festinagroup.com/static/tiendas_online/kronaby/images/menu',
  imgServerUrlImages: 'https://media6.festinagroup.com/static/tiendas_online/kronaby/images',
  imgServerUrlLogoImages: 'https://media6.festinagroup.com/static/tiendas_online/kronaby/images/logos',
  imgServerUrlVideos: 'https://media6.festinagroup.com/static/tiendas_online/kronaby/videos',
  imgServerUrlSmartWatch: 'https://media6.festinagroup.com/static/tiendas_online/kronaby/smartwatch',
  apiDescargaManualesUrl: 'https://static6.festinagroup.com/product',
  imgServerUrlMapaImages: 'https://media6.festinagroup.com/static/tiendas_online/kronaby/images/mapa',
  imgServerUrlImagesPromociones: 'https://media6.festinagroup.com/static/tiendas_online/kronaby/images/promotions',
  documentsServerUrl: 'https://media6.festinagroup.com/static/tiendas_online/kronaby/documents',
  imgServerUrlSharedImages: 'https://media6.festinagroup.com/static/tiendas_online/shared/images',
  imgServerUrlSharedVideos: 'https://media6.festinagroup.com/static/tiendas_online/shared/videos',
  imgServerUrlPhotoReview: 'https://media6.festinagroup.com/static/logistica/photo-review',
  numeroProductosPorPagina: 12,
  modalSubscribe: false,
  modalSubscribeTime: 8000,
  // El primero de cada grupo de idiomas indica el que se escogerá por defecto
  idiomasAplicacion: [Language.English, Language.Spanish, Language.French, Language.Italian, Language.German],
  idiomaPorDefecto: Language.English,
  countrydefault: 'ES',
  idiomaCulturalSeparador: '-',
  reCaptcha: {
    enabled: true,
    siteKey: '6LcXKmEqAAAAAN55uy8mYcj8jAjq-JfnccFPR3YR'
  },
  payPal: {
    messages: true,
    clientId: 'AcZmpdN7_YUz8eXs6bhmloQs4TLU10KO2JBpCn7527YbXQVt0O8NQLNws_T7pCsX2wBzEk9J_jJlRzeE'
  },
  stripe: {
    key: 'pk_test_51NnNKEDSaP4VoijmPFbI2pydGogVb93wnBjxC6Ckn2hJsH9ZEPGNcO7Q2KzURk6R7qxOb1ebbunhBOzm8IvIJV7M00fHn31b75'
  },
  google: {
    tagManagerId: 'GTM-TBNJKN',
    googleTagManagerAuth: 'cI8UQ1M53gbmhF7qwozwjQ',
    googleTagManagerPreview: 'env-2'
  },
  googleMaps: {
    key: 'AIzaSyCd3Kz19yz8NGrCBMnrGwUluWkOlYJGUhQ'
  },
  facebook: {
    appId: '125579485409570'
  },
  fileUpload: {
    extensionesImagenes: ['jpg', 'png'],
    extensionesFicheros: ['pdf'],
    tamanyoMaximo: 9000000
  },
  imgDefaultLazyLoading: 'https://media1.festina.com/static/tiendas_online/logos/festina.svg',
  urlInterceptorErrorEscaped: ['sfileserver.festina.com/Manual'],
  patronTelefono: '[0-9]+',
  seo: {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'kronaby',
    logo: 'https://test-gcp.festina.com/favicon.ico',
    url: 'https://test-gcp.festina.com',
    address: 'Calle Velázquez nº 150, 3º 1ª, 28002 de Madrid (Madrid)',
    priceCurrency: 'EUR',
    email: 'atencioncliente@festina.com',
    wiki: 'https://es.wikipedia.org/wiki/Festina',
    contactPoint: {
      '@type': 'ContactPoint',
      telephone: '+34-915-40-12-91',
      contactType: 'customer service',
      areaServed: 'ES',
      email: 'info@festina.com'
    },
    sameAs: ['https://www.facebook.com/festinaes/', 'https://www.instagram.com/festina.watches/']
  },
  fiestasNacionales: ['01-01', '01-06', '05-01', '06-01', '06-24', '08-06', '08-15', '09-11', '10-12', '12-08', '12-25', '12-26'],
  limiteDiasRespuestaIncidencia: 15,
  googleMerchantId: '285696531'
};
